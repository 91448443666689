




















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AttributeButtons extends Vue {
  @Prop() disabled: boolean = false;
}
