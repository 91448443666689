



















































import { cloneDeep } from 'lodash';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';

@Component({})
export default class RemoveEmailDomainModal extends Vue {
  //   @Prop({ default: false }) notify!: boolean;
  @Prop() domain!: string;
  @Prop() domains!: string[];
  @Prop() parentsName!: any;
  @Prop() removeMembers!: boolean;
  @Prop() addFirstDomain!: boolean;

  notify: boolean = false;

  @Action('admin/exportCsvDeleteGroupMembersList')
  public exportCsvDeleteGroupMembersList!: ({
    groupId,
    parentsName,
    emailDomain,
    allDomains,
    isFirstDomain
  }: {
    groupId: number;
    parentsName: string[];
    emailDomain: string;
    allDomains: string[];
    isFirstDomain: boolean;
  }) => Promise<void>;

  get groupId() {
    return +this.$route.params.groupId;
  }

  public async handleDownloadCsv() {
    await this.exportCsvDeleteGroupMembersList({
      groupId: +this.groupId,
      parentsName: this.parentsName,
      emailDomain: this.domain,
      allDomains: this.domains,
      isFirstDomain: this.addFirstDomain
    });
  }

  public handleSubmit(notify: boolean) {
    this.$emit('submit', notify);
    this.$emit('close');
  }
}
