





































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import { Action, State } from 'vuex-class';
import { RootState } from '@/store/store';
import { clone } from '@/jbi-shared/util/group-level-attributes.util';
import {
  ApiState,
  SortOrder,
  SortEventData,
  PaginatorSpecs
} from '@/store/types/general.types';
import {
  MyjbiGroupUserAttributeSpec,
  MyjbiGroupUserAttribute
} from '@/jbi-shared/types/myjbi-group.types';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import AttributesList from './AttributesList.vue';
import { BaseUserAttributeId } from '@/store/modules/profile/profile.state';

/**
 * This component allows user to select attributes with specs.
 * - It is INDEPENDENT (can be triggered from anywhere).
 * - It accepts an array of selected specs (optional) for cross-checking.
 *
 * Available events:
 * - `@close`: closes the modal without making changes to the selection
 * - `@updateSelection`: emits the updated attributes with their respective specs
 */
@Component({
  components: {
    BaseLoading,
    BasePaginatorHoc,
    AttributesList
  }
})
export default class ExistingUserAttributesModal extends Vue {
  /**
   * Business logic:
   *
   * Base attributes (email, first and last names) should not appear
   * in the selection because the specs and labels of these attributes
   * should be FIXED.
   *
   * Making changes to these attributes has MASSIVE consequences.
   *
   * @IMPORTANT "hiding" them ≠ disabling them.
   *
   * Note:
   * This modal has multiple scenarios where base attributes have to be shown.
   *
   * In those scenarios, please, utilize the `disableRow` prop,
   * and handle the data with care.
   */
  @Prop({ default: true }) hideBaseAttributes!: boolean;

  /**
   * This optional prop provides "freedom" to the parent/consumer
   * to define the criteria for an attribute row to be disabled
   * by supplying a function to execute for each attribute spec
   * in the AttributeRow component.
   *
   * This function should take a `MyjbiGroupUserAttributeSpec` type
   * and return a boolean. (`true` disables the attribute row.)
   *
   * Disabling an attribute row will prevent:
   * - The attribute row from being selected/deselected
   * - The specs of the attribute row from being modified
   */
  @Prop(Function) disableRow!: (spec: MyjbiGroupUserAttributeSpec) => boolean;
  @Prop() selectedAttributes!: MyjbiGroupUserAttributeSpec[];

  // For permissions matrix
  @Prop() groupTypeName!: string;
  @Prop(Number) groupId!: number;

  // pagination and search variables
  perPage: number = 50;
  page: number = 1;
  sortColumn: string = 'attributeLabel';
  sortOrder: SortOrder = SortOrder.ASC;
  searchPhrase: string = '';

  /**
   * Local copies of attribute spec prop and state.
   * Initiated with empty array.
   */
  clonedSelection: MyjbiGroupUserAttributeSpec[] = [];
  allAttributes: MyjbiGroupUserAttributeSpec[] = [];

  @Action('admin/getGroupUserAttributes')
  getGroupUserAttributes!: () => void;

  @State(({ admin }: RootState) => admin.apiState.getGroupUserAttributes)
  getGroupUserAttributesApiState!: ApiState;

  @State(({ admin }: RootState) => admin.groupUserAttributes)
  userAttributesState!: MyjbiGroupUserAttribute[];

  mounted() {
    this.getGroupUserAttributes();

    if (this.selectedAttributes !== undefined) {
      this.clonedSelection = clone(this.selectedAttributes);
    }
  }

  get AttributesList() {
    return AttributesList;
  }

  get attributesPage() {
    return {
      name: 'admin-group-management',
      query: {
        tab: 'Attributes',
        attributeLabel: this.searchPhrase,
        limit: this.perPage.toString() || '50',
        page: this.page.toString() || '1',
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder || SortOrder.ASC
      }
    };
  }

  // total number of items in pagination
  get totalCount(): number {
    if (this.searchPhrase) {
      return this.allAttributes.length;
    }

    const totalItems: number = this.userAttributesState
      ? this.userAttributesState.length
      : 0;

    /**
     * Additional calculation to exclude base attributes
     * from the total count when the variable changes
     */
    if (this.hideBaseAttributes) {
      /**
       * When the values enum is accessed using ```Object.values(enum)```,
       * you get a flattened array of key value PAIRS:
       * (enum value, enum index).
       *
       * So, dividing the array by 2 gives you the NUMBER OF PAIRS.
       *
       * @example
       * ```ts
       * enum Status {
       *  'ACTIVE',
       *  'DELETE'
       * }
       * console.log(Object.values(Status)) // ['ACTIVE', 'DELETE', 0, 1]
       * ```
       */
      const baseAttributeCount = Object.values(BaseUserAttributeId).length / 2;
      return Math.max(totalItems - baseAttributeCount, 0);
    }

    return totalItems;
  }

  // returns attribute specs in current page
  get filteredUserAttributes(): MyjbiGroupUserAttributeSpec[] {
    if (this.userAttributesState === undefined) {
      return [];
    }

    const startItemIndex = this.perPage * (this.page - 1);
    const endItemIndex = startItemIndex + this.perPage;
    return this.allAttributes.slice(startItemIndex, endItemIndex);
  }

  /**
   * Conditionally returns the function to execute for each attribute spec.
   *
   * If parent/consumer supplies their own function,
   * that supplied function will be used instead of the default function.
   */
  get disableRowFunction(): (spec: MyjbiGroupUserAttributeSpec) => boolean {
    if (this.disableRow instanceof Function) {
      return this.disableRow;
    }

    return this.defaultDisabler;
  }

  /**
   * This modal disables base attributes by default.
   *
   * Note:
   * "Hiding" them ≠ disabling them.
   */
  defaultDisabler(spec: MyjbiGroupUserAttributeSpec): boolean {
    return this.isBaseAttribute(spec.groupUserAttribute);
  }

  /**
   * Data massager for the main attribute list.
   *
   * It does a few things to the data:
   * 1. Filter out base attributes from the list
   * 2. Map attribute specifications onto existing attribute data
   * 3. Filter attributes that matched the search phrase (optional)
   */
  transformStateData(): MyjbiGroupUserAttributeSpec[] {
    const transformedData = this.userAttributesState
      // removes base attributes from the main list
      .filter((attr) => {
        if (this.hideBaseAttributes) {
          return this.isBaseAttribute(attr) === false;
        }

        return true;
      })
      /**
       * `lockAttribute` and `required` are not part of the
       * attribute data structure. This mapping also tries to identify if
       * an attribute is selected, and if so, their spec values will take precedence.
       */
      .map((stateAttr) => {
        const targetAttr = this.clonedSelection.find((selectedAttr) => {
          return selectedAttr.groupUserAttribute.id === stateAttr.id;
        });

        return {
          groupUserAttribute: stateAttr,
          required: targetAttr ? targetAttr.required : false,
          lockAttribute: targetAttr ? targetAttr.lockAttribute : false
        };
      });

    // This is the "search function".
    if (this.searchPhrase) {
      return transformedData.filter((attrSpec) => {
        return attrSpec.groupUserAttribute.name
          .toLowerCase()
          .includes(this.searchPhrase.toLowerCase());
      });
    }

    return transformedData;
  }

  isBaseAttribute(
    groupUserAttribute: MyjbiGroupUserAttributeSpec['groupUserAttribute']
  ): boolean {
    return Object.values(BaseUserAttributeId).includes(groupUserAttribute.id);
  }

  /**
   * Adds all SELECTABLE items in CURRENT PAGE to selection
   * (selectable = not disabled)
   */
  selectAllInCurrentPage() {
    const selectedAttrIds = this.clonedSelection.map(
      (spec) => spec.groupUserAttribute.id
    );
    const attrsToAdd: MyjbiGroupUserAttributeSpec[] = [];

    this.filteredUserAttributes
      .filter((currPageSpec) => {
        const isSelectable = this.disableRowFunction(currPageSpec) === false;
        return isSelectable;
      })
      .forEach((spec) => {
        if (selectedAttrIds.includes(spec.groupUserAttribute.id) === false) {
          attrsToAdd.push(spec);
        }
      });

    this.clonedSelection = [...this.clonedSelection, ...attrsToAdd];
  }

  /**
   * Remove all DESELECTABLE items in CURRENT PAGE from selection
   * (deselectable = not disabled)
   *
   * (Achieved by retaining only the ones that ARE disabled.)
   */
  removeAllInCurrentPage() {
    const currPageAttrIds = this.filteredUserAttributes.map(
      (spec) => spec.groupUserAttribute.id
    );

    const attrsToRetain = this.clonedSelection.filter((spec) => {
      /**
       * There are a few scenarios when the selected row
       * is NOT visible in the current page.
       *
       * 1. When displaying search results
       * 2. When viewing other pages
       *
       * This function only targets items in current page.
       */
      const attrInOtherPage =
        currPageAttrIds.includes(spec.groupUserAttribute.id) === false;
      const shouldRetain = this.disableRowFunction(spec) === true;

      if (attrInOtherPage || shouldRetain) {
        return true;
      }
    });

    this.clonedSelection = clone(attrsToRetain);
  }

  addAttribute(item: MyjbiGroupUserAttributeSpec) {
    this.clonedSelection.push(item);
  }

  removeAttribute(item: MyjbiGroupUserAttributeSpec) {
    this.clonedSelection.forEach((spec, index, arr) => {
      if (item.groupUserAttribute.id === spec.groupUserAttribute.id) {
        arr.splice(index, 1);
      }
    });
  }

  /**
   * User can toggle the "locked" and "required" spec freely without
   * the attribute being selected.
   *
   * So this function only updates the attribute spec visually.
   * It also has to update 2 copies of the attributes.
   */
  updateRowSpecVisual(updatedSpec: MyjbiGroupUserAttributeSpec) {
    // Update 1: in main array
    const copyOfAllAttributes = clone(this.allAttributes);
    const index = copyOfAllAttributes.findIndex(
      (spec) => spec.groupUserAttribute.id === updatedSpec.groupUserAttribute.id
    );

    if (index > -1) {
      copyOfAllAttributes[index] = updatedSpec;
      this.allAttributes = copyOfAllAttributes;
    }

    // Update 2: in selection array
    const copyOfSelection = clone(this.clonedSelection);
    const indexInSelection = copyOfSelection.findIndex(
      (spec) => spec.groupUserAttribute.id === updatedSpec.groupUserAttribute.id
    );

    if (indexInSelection > -1) {
      copyOfSelection[indexInSelection] = updatedSpec;
      this.clonedSelection = copyOfSelection;
    }
  }

  // emits updated selection on CTA click
  updateSelection() {
    this.$emit('updateSelection', this.clonedSelection);
    this.$emit('close');
  }

  discardChangesAndClose() {
    this.$emit('close');
  }

  @Watch('getGroupUserAttributesApiState', { deep: true })
  fetchAllUserAttributesApiStateCallback(state: ApiState) {
    if (state.success) {
      this.allAttributes = this.transformStateData();
    }

    if (state.error) {
      Toast.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: 'Error getting existing attributes. Please try again.'
      });
    }
  }

  /* Paginator staples */
  paginateItems({ perPage, page }: PaginatorSpecs) {
    this.perPage = perPage;
    this.page = page;
  }

  sortItems(sortEvent: SortEventData) {
    this.sortColumn = sortEvent.sortColumn;
    this.sortOrder = sortEvent.sortOrder;

    this.filteredUserAttributes.sort((a, b) => {
      const columnA = a.groupUserAttribute.name.toLowerCase();
      const columnB = b.groupUserAttribute.name.toLowerCase();

      if (this.sortOrder === SortOrder.ASC) {
        return columnA > columnB ? 1 : columnB > columnA ? -1 : 0;
      } else {
        return columnB > columnA ? 1 : columnA > columnB ? -1 : 0;
      }
    });
  }

  // Page has to be set to 1 to ensure search function works properly
  resetPage() {
    this.page = 1;
    this.allAttributes = this.transformStateData();
  }
}
