var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-card has-modal-small"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title",class:_vm.$style.titleHeader},[_vm._v(" "+_vm._s(_vm.template.name)+" ")]),_c('button',{staticClass:"close-icon",attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_c('i',{staticClass:"mdi mdi-close"})])]),_c('section',{staticClass:"modal-card-body"},[_c('p',{class:_vm.$style.attributeCount},[_vm._v(" "+_vm._s(_vm.template.attributes.length)+" attribute(s) ")]),_c('BaseTable',{attrs:{"isBorderless":true},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',[_c('th',[_vm._v("Label")]),_c('th',[_vm._v("Content Type")])])]},proxy:true},{key:"body",fn:function(){return _vm._l((_vm.template.attributes),function(attribute){return _c('tr',{key:attribute.id},[_c('td',[_c('span',[_c('i',{staticClass:"mdi mdi-asterisk",class:[
                  _vm.$style.asterisk,
                  attribute.required ? _vm.$style.isRed : ''
                ]}),_vm._v(" "+_vm._s(attribute.name)+" "),_c('i',{staticClass:"mdi mdi-lock",class:attribute.lockAttribute ? _vm.$style.locked : _vm.$style.unlocked,attrs:{"title":attribute.lockAttribute
                    ? 'Lock Attribute'
                    : 'Unlock Attribute'}}),(attribute.groupUserAttributeType.type === 'list')?_c('ul',{class:_vm.$style.listSelections},_vm._l((attribute.option.value),function(listItem,index){return _c('li',{key:index,class:attribute.option.multiSelect
                      ? _vm.$style.multiSelection
                      : _vm.$style.singleSelection},[_c('i',[_vm._v(" "+_vm._s(listItem)+" ")])])}),0):_vm._e()])]),_c('td',[_vm._v(_vm._s(attribute.groupUserAttributeType.type))])])})},proxy:true}])})],1),_c('footer',{staticClass:"modal-card-foot"},[_c('b-button',{staticClass:"button is-primary",on:{"click":_vm.closeModal}},[_vm._v("Done")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }