var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.container},[_c('p',{class:_vm.$style.titleContainer},[(_vm.fileName)?[_c('b',{class:_vm.$style.title},[_vm._v(" "+_vm._s(_vm.fileName)+" successfully uploaded. ")]),_c('a',{on:{"click":function($event){return _vm.$emit('clear')}}},[_vm._v("Reupload file")])]:_vm._e()],2),_c('div',{class:_vm.$style.listContainer},[_c('table',{staticClass:"table is-fullwidth"},[_c('thead',[_c('tr',[_vm._l((_vm.userAttributes),function(attr){return _c('th',{key:attr.groupUserAttribute.id},[_vm._v(" "+_vm._s(attr.groupUserAttribute.name)+" ")])}),_c('th')],2)]),_c('tbody',_vm._l((_vm.syncedMembersData),function(user,i){
var _obj;
return _c('tr',{key:("member_" + i)},[_vm._l((_vm.userAttributes),function(attr){return _c('td',{key:("attribute_" + (attr.groupUserAttribute.slug)),attrs:{"data-key":attr.groupUserAttribute.slug}},[_c('b-tooltip',{class:_vm.$style.tooltip,attrs:{"type":"is-dark","square":"","target":_vm.syncedMembersData[i][attr.groupUserAttribute.slug],"label":_vm.syncedMembersData[i][attr.groupUserAttribute.slug]
                  .errorMessage,"active":_vm.syncedMembersData[i][attr.groupUserAttribute.slug]
                  .errorMessage
                  ? true
                  : false}},[_c('b-field',{class:[
                  attr.groupUserAttribute.groupUserAttributeType.type ===
                  'image'
                    ? _vm.$style.imageType
                    : _vm.$style.field
                ],attrs:{"type":!_vm.syncedMembersData[i][attr.groupUserAttribute.slug].isValid
                    ? 'is-danger'
                    : undefined}},[(
                    attr.groupUserAttribute.groupUserAttributeType.type ===
                    'date'
                  )?_c('b-datepicker',{attrs:{"icon-pack":"mdi","icon":"calendar","trap-focus":""},on:{"input":function($event){return _vm.validateField(
                      _vm.syncedMembersData[i][attr.groupUserAttribute.slug]
                        .value,
                      i,
                      attr.groupUserAttribute.slug
                    )}},model:{value:(
                    _vm.syncedMembersData[i][attr.groupUserAttribute.slug].value
                  ),callback:function ($$v) {_vm.$set(_vm.syncedMembersData[i][attr.groupUserAttribute.slug], "value", $$v)},expression:"\n                    syncedMembersData[i][attr.groupUserAttribute.slug].value\n                  "}},[_c('b-button',{class:_vm.$style.calendarCancelButton,attrs:{"label":"Clear"},on:{"click":function($event){return _vm.removeDate(i, attr.groupUserAttribute.slug)}}})],1):(
                    attr.groupUserAttribute.groupUserAttributeType.type ===
                    'image'
                  )?_c('ImageUploadComponent',{attrs:{"imageSrc":_vm.syncedMembersData[i][attr.groupUserAttribute.slug].value},on:{"getImage":function (imageUrl) { return _vm.getImage(imageUrl, i, attr.groupUserAttribute.slug); },"isUploading":function (imageUploadStatus) { return _vm.isUploading(
                        imageUploadStatus,
                        i,
                        attr.groupUserAttribute.slug
                      ); }}}):(
                    attr.groupUserAttribute.groupUserAttributeType.type ===
                    'list'
                  )?_c('ListDropdownComponent',{attrs:{"memberAttributeValue":_vm.syncedMembersData[i][attr.groupUserAttribute.slug].value,"groupUserAttributeSpec":attr,"isValid":_vm.syncedMembersData[i][attr.groupUserAttribute.slug].isValid},on:{"getListValue":function (selectedListValues) { return _vm.getListValue(
                        selectedListValues,
                        i,
                        attr.groupUserAttribute.slug
                      ); }}}):(
                    attr.groupUserAttribute.groupUserAttributeType.type ===
                    'text area'
                  )?_c('b-input',{class:_vm.$style.textAreaTrigger,attrs:{"icon-right":"pencil","value":_vm.renderTextAreaContent(i, attr.groupUserAttribute.slug)},nativeOn:{"click":function($event){return _vm.openRichTextEditor(
                      i,
                      attr.required,
                      attr.groupUserAttribute.name,
                      attr.groupUserAttribute.slug
                    )}}}):_c('b-input',{on:{"input":function($event){return _vm.validateField(
                      _vm.syncedMembersData[i][attr.groupUserAttribute.slug]
                        .value,
                      i,
                      attr.groupUserAttribute.slug
                    )}},model:{value:(
                    _vm.syncedMembersData[i][attr.groupUserAttribute.slug].value
                  ),callback:function ($$v) {_vm.$set(_vm.syncedMembersData[i][attr.groupUserAttribute.slug], "value", $$v)},expression:"\n                    syncedMembersData[i][attr.groupUserAttribute.slug].value\n                  "}})],1)],1)],1)}),_c('td',{class:( _obj = {}, _obj[_vm.$style.removeImportedButton] = true, _obj )},[_c('a',{staticClass:"icon has-text-grey fa fa-times",on:{"click":function($event){return _vm.removeImportedUser(i)}}})])],2)}),0)])]),(_vm.showNotify)?[_c('div',{staticClass:"is-divider",staticStyle:{"padding":"0","margin":"10px 0px"}}),(_vm.getCurrentRoute && _vm.groupDetail.members.length !== 0)?_c('div',{class:_vm.$style.csvDownloadButtonContainer,on:{"click":_vm.handleDownloadCsv}},[_c('p',[_vm._v("Download Members List (CSV)")])]):_vm._e(),_c('b-checkbox',{staticClass:"m-t-2 m-b-1",model:{value:(_vm.syncedNotify),callback:function ($$v) {_vm.syncedNotify=$$v},expression:"syncedNotify"}},[_c('span',[_vm._v(" Notify members ")])])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }