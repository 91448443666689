var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Name","type":errors.length ? 'is-danger' : undefined,"message":errors[0] || ''}},[_c('b-input',{attrs:{"disabled":_vm.isProtected},model:{value:(_vm.syncedName),callback:function ($$v) {_vm.syncedName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"syncedName"}})],1)]}}])})],1),(_vm.typeOptions)?_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Group type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Type","type":errors.length ? 'is-danger' : undefined,"message":errors[0] || ''}},[_c('b-dropdown',{class:_vm.$style.dropdown,attrs:{"expanded":"","aria-role":"list","disabled":_vm.isProtected},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var _obj;

var active = ref.active;return [_vm._t("customTrigger",[_c('div',{class:_vm.$style.trigger},[_c('div',{class:_vm.$style.text},[_c('span',{class:( _obj = {
                        'has-text-dark': _vm.syncedTypes
                      }, _obj[_vm.$style.capitalize] = _vm.syncedTypes, _obj )},[_vm._v(" "+_vm._s(_vm.syncedTypes ? _vm.syncedTypes.name : 'Select a type')+" ")])]),_c('b-icon',{attrs:{"icon":active ? 'chevron-up' : 'chevron-down'}})],1)])]}}],null,true),model:{value:(_vm.syncedTypes),callback:function ($$v) {_vm.syncedTypes=$$v},expression:"syncedTypes"}},_vm._l((_vm.filteredGroupTypes),function(type,index){return _c('b-dropdown-item',{key:index,class:_vm.$style.dropdownItem,attrs:{"value":type,"aria-role":"listitem"}},[_vm._v(" "+_vm._s(type.name)+" ")])}),1)],1)]}}],null,false,1127287165)})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }