var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.wrapper},[_c('div',{class:_vm.$style.buttonControl},[(
        _vm.useTemplate &&
        _vm.isUserAllowed(
          _vm.PermissionsMatrixActionsEnum.CREATE,
          'group_administration-groups-update_groups-update_user_attributes-create_existing_user_attributes',
          true
        )
      )?_c('b-button',{staticClass:"is-square",class:_vm.$style.manageAttributeButton,on:{"click":_vm.openUserTemplateModal}},[_vm._v(" Use Existing Template ")]):_vm._e(),(_vm.isSettings && _vm.isAllowedToManageUserAttributes)?_c('b-button',{staticClass:"is-square is-primary",class:_vm.$style.manageAttributeButton,on:{"click":_vm.openManageAttributeModal}},[_vm._v(" Manage Attribute(s) ")]):_vm._e()],1),(
      _vm.isUserAllowed(
        _vm.PermissionsMatrixActionsEnum.READ,
        'group_administration-groups-update_groups-update_user_attributes-read_existing_user_attributes'
      )
    )?_c('UserAttributeMultiSelect',{attrs:{"userAttributeUpdateKey":_vm.userAttributeUpdateKey,"userAttributes":_vm.syncedAttributes,"groupUserAttributes":_vm.groupUserAttributes,"newlyCreatedAttribute":_vm.newlyCreatedAttribute,"parentAttributeIds":_vm.parentAttributeIds,"isSettings":_vm.isSettings,"groupId":_vm.groupId,"groupTypeName":_vm.groupTypeName,"group-exceptions":_vm.groupExceptions},on:{"update:userAttributes":function($event){_vm.syncedAttributes=$event},"update:user-attributes":function($event){_vm.syncedAttributes=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }