
































import { Component, Vue, PropSync, Prop, Watch } from 'vue-property-decorator';
import { remove as _remove } from 'lodash';
import { GetProtectedGroupTypesResponsePayload } from '@/store/modules/admin/types/admin.types';
import { ProtectedGroupTypes } from '../../../store/modules/admin/types/group.types';
import { ValidationProvider } from 'vee-validate';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';

@Component({
  components: {}
})
export default class ProtectedGroupSection extends Vue {
  @Prop() protectedGroupTypes!: GetProtectedGroupTypesResponsePayload;
  @PropSync('selectedProtectedGroupData')
  syncedSelectedProtectedGroupData!: ProtectedGroupTypes;
  @PropSync('protectGroup', Boolean) syncedProtectGroup!: boolean;
  @Prop() parentGroupIsProtected!: boolean;

  @Watch('syncedProtectGroup')
  @isTruthy
  public watchSyncedProtectGroup() {
    this.syncedSelectedProtectedGroupData = this.protectedGroupTypes[0];
  }

  public mounted() {
    this.syncedProtectGroup = this.parentGroupIsProtected;
  }
}
