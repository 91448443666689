var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"rootValidationObserver",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validationErrors = ref.errors;
return [_c('Container',{attrs:{"title":"Create Subgroup"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h1',[_vm._v("Create Subgroup")])]},proxy:true}],null,true)},[_c('b-loading',{attrs:{"is-full-page":true,"active":!_vm.parentGroupDetailByParentGroupId}}),(_vm.parentGroupDetailByParentGroupId)?_c('div',{staticClass:"box"},[_c('GroupNameAndTypeForm',{attrs:{"name":_vm.name,"types":_vm.types},on:{"update:name":function($event){_vm.name=$event},"update:types":function($event){_vm.types=$event}}}),_c('b-tabs',{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('b-tab-item',{attrs:{"label":"User Attributes"}},[_c('UserAttributeTab',{attrs:{"userAttributes":_vm.userAttributes,"name":_vm.name,"subGroupType":_vm.parentType,"parentAttributes":_vm.parentAttributes,"group-id":_vm.parentGroupId},on:{"update:userAttributes":function($event){_vm.userAttributes=$event},"update:user-attributes":function($event){_vm.userAttributes=$event}}}),(_vm.allowedEmailDomains !== undefined)?_c('EmailDomainForm',{attrs:{"allowedEmailDomains":_vm.allowedEmailDomains,"isMaster":false}}):_vm._e(),_c('ProtectedGroupSection',{attrs:{"protectedGroupTypes":_vm.protectedGroupTypes,"selectedProtectedGroupData":_vm.selectedProtectedGroupData,"protectGroup":_vm.protectGroup,"parentGroupIsProtected":_vm.parentGroupIsProtected},on:{"update:selectedProtectedGroupData":function($event){_vm.selectedProtectedGroupData=$event},"update:selected-protected-group-data":function($event){_vm.selectedProtectedGroupData=$event},"update:protectGroup":function($event){_vm.protectGroup=$event},"update:protect-group":function($event){_vm.protectGroup=$event}}}),(_vm.isMemberSelectorVisible)?_c('ExistingMembersSelector',{staticClass:"m-t-3 m-b-2",attrs:{"parentGroupAttributeSpecs":_vm.parentGroupDetailByParentGroupId
                ? _vm.parentGroupDetailByParentGroupId.groupUserAttributeSpecs
                : [],"parentGroupMembers":_vm.parentGroupDetailByParentGroupId
                ? _vm.parentGroupDetailByParentGroupId.members
                : [],"membersData":_vm.membersData,"notify":_vm.notify},on:{"update:membersData":function($event){_vm.membersData=$event},"update:members-data":function($event){_vm.membersData=$event},"update:notify":function($event){_vm.notify=$event}}}):_c('MembersUploadSection',{attrs:{"showMemberLabel":"","shouldDownloadExistingMemberInCsv":"","membersData":_vm.membersData,"userAttributes":_vm.userAttributes,"membersFile":_vm.membersFile,"validationErrors":validationErrors,"csvFileName":("existing-member-data_" + _vm.parentGroupName)},on:{"update:membersData":function($event){_vm.membersData=$event},"update:members-data":function($event){_vm.membersData=$event},"update:membersFile":function($event){_vm.membersFile=$event},"update:members-file":function($event){_vm.membersFile=$event},"clear":function($event){_vm.membersData = null;
              _vm.membersFile = null;}}}),_c('AgreementSection',{attrs:{"agreementFiles":_vm.agreementFiles},on:{"update:agreementFiles":function($event){_vm.agreementFiles=$event},"update:agreement-files":function($event){_vm.agreementFiles=$event}}})],1),_c('b-tab-item',{attrs:{"label":"Group Attributes"}},[_c('GroupLevelAttribute',{attrs:{"groupId":_vm.groupId,"groupName":_vm.name,"isSettings":false,"isGroupLevelAttributeTabOpened":_vm.currentTab === 1},on:{"updateValuesAndError":_vm.handleUpdateGroupLevelAttributeValues,"templateDetails":_vm.templateDetails}})],1)],1)],1):_vm._e(),_c('GroupCreationSubmitButtons',{attrs:{"loading":_vm.createNewGroupLoading,"invalid":_vm.subGroupCreationFormHasError(invalid),"isProtectGroup":_vm.protectGroup,"exitRoute":{
        name: 'admin-group-management',
        query: {
          tab: 'Groups'
        }
      },"btnText":"Create Subgroup"},on:{"submit":_vm.handleCreateGroup}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }