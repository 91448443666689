/* tslint:disable */
import {
  LoadingProgrammatic as Loader,
  ToastProgrammatic as Toast,
} from 'buefy';
import { BLoadingComponent } from 'buefy/types/components';
import { Job } from 'bull';
import Vue from 'vue';
import { WorkerJobStatus } from '../jbi-shared/types/cplus-worker.types';
import { EVENTS } from '../store/modules/websocket/websocket.state';
import { useAction } from './store.util';
import { useWebsocket } from './websocket.util';

const displayToastError = () => {
  Toast.open({
    message: `Error processing. Please try again later.`,
    duration: 3000,
    type: 'is-danger',
  });
};

/* tslint:disable */
export const handleUserListUploading = async function (
  this: Vue,
  job: Job,
  element?: HTMLDivElement,
) {
  if (!job) {
    displayToastError();
  }

  const {
    connectToWs,
    disconnectWs,
    joinRoom,
    listenOnceTo,
  } = useWebsocket.call(this);

  const { id: jobId } = job;

  const markWorkerJobAsCompleted: (payload: Job) => void = useAction.call(
    this,
    'websocket/markWorkerJobAsCompleted',
  );
  const markWorkerJobAsErrored: (payload: Job) => void = useAction.call(
    this,
    'websocket/markWorkerJobAsErrored',
  );
  const loaderComponent: BLoadingComponent = Loader.open({
    container: element,
  });

  await connectToWs();

  joinRoom(jobId);

  try {
    job = (await listenOnceTo(
      EVENTS.VALIDATE_IMPORTED_MEMBER_LIST_EVENT,
    )) as Job;
  } catch (error) {
    loaderComponent.close();
    markWorkerJobAsErrored(job);
    displayToastError();
    await disconnectWs();
    return;
  }

  const { status, message, metadata, userList } = job.returnvalue;
  switch (status) {
    case WorkerJobStatus.PROCESSED:
      loaderComponent.close();
      markWorkerJobAsCompleted(job);
      Toast.open({
        queue: true,
        position: 'is-top',
        message: `Member list import success`,
      });
      return userList;
    case WorkerJobStatus.FAILED:
      loaderComponent.close();
      markWorkerJobAsErrored(job);
      Toast.open({
        message,
        duration: 3000,
        type: 'is-danger',
      });
      break;
    default:
      loaderComponent.close();
      markWorkerJobAsErrored(job);
      displayToastError();
  }

  await disconnectWs();

  return;
};
