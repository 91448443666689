import Container from '@/components/Container.vue';
import { StringInputOption } from '@/jbi-shared/types/form.types';
import BaseMultiSelect from '@/jbi-shared/vue-components/BaseMultiSelect.vue';
import { GroupUserAttributeWithValue } from '@/store/modules/admin/types/group-user-attribute.types';
import {
  CreateNewGroupRequestPayload,
  CreateNewGroupResponsePayload,
  ProtectedGroupTypes,
  UserAttributesStringInputOption
} from '@/store/modules/admin/types/group.types';
import { GetSignedUrlForUploadResponsePayload } from '@/store/modules/static-file/types/static-file.types';
import { RootState } from '@/store/store';
import AgreementSection from '@/views/AdminCreateGroup/components/AgreementSection.vue';
import EmailDomainForm from '@/views/AdminCreateGroup/components/EmailDomainForm.vue';
import GroupAttributeForm from '@/views/AdminCreateGroup/components/GroupAttributeForm.vue';
import GroupCreationSubmitButtons from '@/views/AdminCreateGroup/components/GroupCreationSubmitButtons.vue';
import MembersUploadSection from '@/views/AdminCreateGroup/components/MembersUploadSection.vue';
import ExistingMembersSelector from '@/views/AdminCreateSubGroup/components/ExistingMembersSelector.vue';
import { DialogProgrammatic, ToastProgrammatic } from 'buefy';
import { cloneDeep } from 'lodash';
import {
  ValidationObserver,
  ValidationObserverInstance,
  ValidationProvider
} from 'vee-validate';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import {
  GroupUserAttributeTypeStatus,
  MyjbiGroupDetail,
  MyjbiGroupType,
  MyjbiGroupUserAttributeSpec
} from '../../../jbi-shared/types/myjbi-group.types';
import {
  GroupLevelAttributeListType,
  GroupLevelAttributeValue,
  GroupLevelAttributeValuePayload,
  GroupTemplateAttribute,
  GroupTemplateAttributeStatus,
  GroupTemplatePayload
} from '../../../store/modules/admin/types/group-level-attribute.types';
import {
  hasSameAttributesAsParent,
  MemberObject,
  userAttributesArrWithValues
} from '../../../utils/group.util';

export const defaultAttributes: MyjbiGroupUserAttributeSpec[] = [
  {
    groupUserAttribute: {
      id: 1,
      name: 'Email',
      slug: 'email',
      option: null,
      status: GroupUserAttributeTypeStatus.ACTIVE,
      groupUserAttributeType: {
        id: 1,
        type: 'email',
        option: null,
        status: GroupUserAttributeTypeStatus.ACTIVE
      }
    },
    required: true,
    isDefault: true
  },
  {
    groupUserAttribute: {
      id: 2,
      name: 'First Name',
      slug: 'first-name',
      option: null,
      status: GroupUserAttributeTypeStatus.ACTIVE,
      groupUserAttributeType: {
        id: 1,
        type: 'text',
        option: null,
        status: GroupUserAttributeTypeStatus.ACTIVE
      }
    },
    required: true,
    isDefault: true
  },
  {
    groupUserAttribute: {
      id: 3,
      name: 'Last Name',
      slug: 'last-name',
      option: null,
      status: GroupUserAttributeTypeStatus.ACTIVE,
      groupUserAttributeType: {
        id: 1,
        type: 'text',
        option: null,
        status: GroupUserAttributeTypeStatus.ACTIVE
      }
    },
    required: true,
    isDefault: true
  }
];

export function generateGroupCreationMixin() {
  @Component({
    components: {
      Container,
      BaseMultiSelect,
      AgreementSection,
      ValidationObserver,
      ValidationProvider,
      GroupAttributeForm,
      GroupCreationSubmitButtons,
      MembersUploadSection,
      ExistingMembersSelector,
      EmailDomainForm
    }
  })
  class GroupCreationMixin extends Vue {
    name = '';
    types: UserAttributesStringInputOption[] = [];
    userAttributes: MyjbiGroupUserAttributeSpec[] = [];
    agreementFiles: Array<File | GetSignedUrlForUploadResponsePayload> = [];
    membersFile: File | null = null;
    membersData: MemberObject[] | null = null;
    notify = false;
    emailDomains = [];
    protectedTypes: ProtectedGroupTypes | null = null;
    isValidGroup = true;

    @Action('admin/createNewGroup')
    createNewGroup!: (
      payload: CreateNewGroupRequestPayload
    ) => Promise<CreateNewGroupResponsePayload>;

    @Action('admin/getParentGroupDetailByParentGroupId')
    getParentGroupDetailByParentGroupId!: (
      id: number
    ) => Promise<MyjbiGroupDetail>;

    @Action('admin/resetParentGroupDetailByParentGroupId')
    resetParentGroupDetailByParentGroupId!: () => void;

    @Action('admin/createGroupLevelAttributesValues')
    createGroupLevelAttributeValues!: (
      payload: GroupLevelAttributeValuePayload
    ) => void;

    @Action('admin/createGroupTemplate')
    public createGroupTemplate!: (payload: GroupTemplatePayload) => void;

    @State((state: RootState) => state.admin.verifyGroupTemplate)
    public verifyGroupTemplateResponse!: boolean;

    public currentTab: number = 0;
    public selectedGroupLevelAttributeValues: GroupLevelAttributeValue[] = [];
    public activeGroupAttributes: GroupLevelAttributeValue[] = [];
    public deleteGroupAttributes: GroupLevelAttributeValue[] = [];
    public groupLevelAttributeFormHasError: boolean = true;
    public isGroupLevelAttributeLoading: boolean = false;
    public templateAttributes: GroupTemplateAttribute[] = [];
    public saveAsTemplate: boolean = false;
    public templateName: string = '';
    subGroupCreationError: boolean = false;

    get createNewGroupLoading() {
      return (
        (this.$store.state as RootState).admin.apiState.createNewGroup
          .loading || this.isGroupLevelAttributeLoading
      );
    }

    get typeOptions(): StringInputOption[] {
      return Object.values(MyjbiGroupType).map((v) => ({ id: v, name: v }));
    }

    get parentGroupDetailByParentGroupId() {
      return (this.$store.state as RootState).admin
        .parentGroupDetailByParentGroupId;
    }

    get parentGroupId() {
      return +this.$route.params.parentGroupId;
    }

    get groupId() {
      return +this.$route.params.groupId;
    }

    get getParentGroupDetailByParentGroupIdLoading() {
      return (this.$store.state as RootState).admin.apiState
        .getParentGroupDetailByParentGroupId.loading;
    }

    get hasSameAttributesAsParent() {
      return hasSameAttributesAsParent(
        this.userAttributes,
        this.parentGroupDetailByParentGroupId
      );
    }

    get parentAttributes(): MyjbiGroupUserAttributeSpec[] | undefined {
      return this.parentGroupDetailByParentGroupId?.groupUserAttributeSpecs;
    }

    get parentGroupName() {
      return this.parentGroupDetailByParentGroupId?.name;
    }

    get parentGroupType() {
      return this.parentGroupDetailByParentGroupId?.types
        ? this.parentGroupDetailByParentGroupId?.types
        : [];
    }

    get parentProtectedTypes() {
      return this.parentGroupDetailByParentGroupId?.protectedTypes;
    }

    get isMemberSelectorVisible(): boolean {
      return this.hasSameAttributesAsParent && !this.subGroupCreationError;
    }

    async handleCreateGroup() {
      const userAttributesData: GroupUserAttributeWithValue[][] =
        this.membersData && this.userAttributes
          ? userAttributesArrWithValues(this.membersData, this.userAttributes)
          : [];
      const rootValidationObserver = this.$refs
        .rootValidationObserver as ValidationObserverInstance;
      if ((await rootValidationObserver.validate()) === false) {
        const message = !this.name?.trim()
          ? 'Name is required'
          : !this.userAttributes?.length
          ? 'User Attributes are required'
          : !this.membersData?.length
          ? 'Member data is required'
          : !this.types?.length
          ? 'Group type is requried'
          : 'Please fill in all the required fields';

        return DialogProgrammatic.alert({
          title: `Error`,
          message: `<p class="subtitle">${message}<p>`,
          // confirmText: 'Go Back',
          type: 'is-primary'
        });
      }
      try {
        const payload: CreateNewGroupRequestPayload = {
          name: this.name,
          attributesSpecs: this.userAttributes,
          agreements: this.agreementFiles.map((f) => ({
            fileUri: (f as GetSignedUrlForUploadResponsePayload).storageUri,
            fileName: (f as GetSignedUrlForUploadResponsePayload).fileName
          })),
          userAttributesArr: userAttributesData,
          parentPath: this.parentGroupDetailByParentGroupId?.path,
          types: this.parentGroupType.map((type) => type as MyjbiGroupType),
          notify: this.notify,
          emailDomains: [],
          protectedGroup: this.parentProtectedTypes
            ? {
                isProtected: this.parentProtectedTypes.isProtected,
                protectedGroupType: this.parentProtectedTypes.protectedType
              }
            : undefined
        };
        this.subGroupCreationError = false;

        const { id } = await this.createNewGroup(payload);

        if (this.activeGroupAttributes.length) {
          this.isGroupLevelAttributeLoading = true;
          await this.createGroupLevelAttributeValues({
            groupId: id,
            attributeValues: {
              selected: this.activeGroupAttributes
            }
          });
          this.isGroupLevelAttributeLoading = false;
        }

        if (
          !this.verifyGroupTemplateResponse &&
          this.saveAsTemplate &&
          this.templateName
        ) {
          const templateParams: GroupTemplatePayload = {
            templateName: this.templateName,
            groupId: id,
            attributes: {
              active: this.getGroupTemplateAttributes()
            }
          };
          this.createGroupTemplate(templateParams);
        }

        ToastProgrammatic.open({
          queue: true,
          type: 'is-dark',
          position: 'is-top',
          message: this.parentGroupDetailByParentGroupId
            ? `Subgroup created`
            : `Group created`
        });

        this.$router.push({
          name: 'admin-view-group',
          params: {
            groupId: String(id)
          }
        });
      } catch (error: any) {
        this.subGroupCreationError = true;
        // switch view to user attribute view on error
        this.currentTab = 0;
        ToastProgrammatic.open({
          queue: true,
          type: 'is-danger',
          position: 'is-top',
          message: error?.response?.data?.message || error,
          duration: 5000
        });
      }
    }

    /*
     * "activeGroupAttributes" is an array of group LEVEL attributes.
     * This method extracts the necessary properties from group level attributes
     * to construct group TEMPLATE attributes.
     *
     * Note:
     * Template attribute ordering should start with 1.
     */
    getGroupTemplateAttributes(): GroupTemplateAttribute[] {
      return this.activeGroupAttributes
        .filter((attribute: GroupLevelAttributeValue) => {
          return attribute.isDefault === false;
        })
        .map((selectedAttribute: GroupLevelAttributeValue, index: number) => {
          return {
            label: selectedAttribute.label,
            option:
              selectedAttribute.groupLevelAttributeType.type === 'list'
                ? this.clearListAttributeSelectedValue(selectedAttribute)
                : selectedAttribute.groupLevelAttributeType.option,
            status: GroupTemplateAttributeStatus.ACTIVE,
            groupLevelAttributeType: selectedAttribute.groupLevelAttributeType,
            order: index + 1,
            isRequired: selectedAttribute.isRequired
          };
        });
    }

    /**
     * When creating group template with list attribute,
     * selected value of lists should be cleared,
     * and only the selections should be preserved.
     */
    clearListAttributeSelectedValue(
      attribute: GroupLevelAttributeValue
    ): GroupLevelAttributeListType {
      return {
        options: (attribute.value as GroupLevelAttributeListType).options,
        selected: (attribute.value as GroupLevelAttributeListType)
          .isSingleSelection
          ? ''
          : [],
        isSingleSelection: (attribute.value as GroupLevelAttributeListType)
          .isSingleSelection
      };
    }

    public isValidGroupMember(members: MemberObject[] | null) {
      if (!members) {
        return (this.isValidGroup = true);
      }

      if (members.length > 0 && typeof members[0].email === 'string') {
        return (this.isValidGroup = true);
      }

      const checkMemberListIsValid = members.filter((user) => {
        const invalidAttribute = Object.entries(user)
          .map((attribute) => attribute[1].isValid)
          .filter((attribute) => !!attribute === false);

        if (
          invalidAttribute.findIndex((attribute) => !!attribute === false) !==
          -1
        ) {
          return invalidAttribute;
        }
      });

      this.isValidGroup = checkMemberListIsValid?.length > 0 ? false : true;
    }

    handleUpdateGroupLevelAttributeValues(
      activeValues: GroupLevelAttributeValue[],
      deleteValues: GroupLevelAttributeValue[],
      error: boolean
    ) {
      this.activeGroupAttributes = activeValues;
      this.deleteGroupAttributes = deleteValues;
      this.groupLevelAttributeFormHasError = error;
    }

    templateDetails(saveAsTemplate: boolean, templateName: string) {
      this.saveAsTemplate = saveAsTemplate;
      this.templateName = templateName;
    }

    @Watch('membersData', { deep: true })
    public watchMembersData() {
      this.isValidGroupMember(this.membersData);
    }

    @Watch('userAttributes')
    onAttributeChange() {
      if (!this.parentGroupId) {
        this.membersData = null;
        this.membersFile = null;
      }
    }

    @Watch('parentGroupId', { immediate: true })
    onParentGroupIdChange() {
      if (this.parentGroupId) {
        this.getParentGroupDetailByParentGroupId(this.parentGroupId);
      } else {
        this.resetParentGroupDetailByParentGroupId();
      }
    }

    @Watch('parentAttributes', { immediate: true })
    onParentAttributesChange() {
      this.userAttributes =
        this.parentAttributes || cloneDeep(defaultAttributes);
    }
  }
  return GroupCreationMixin;
}
