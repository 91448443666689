













































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseLoading from '../../../../components/base/BaseLoading.vue';
import { MyjbiGroupUserAttributeSpec } from '../../../../jbi-shared/types/myjbi-group.types';

@Component({
  components: { BaseLoading }
})
export default class ListDropdownComponent extends Vue {
  @Prop(String) memberAttributeValue!: string;
  @Prop() groupUserAttributeSpec!: MyjbiGroupUserAttributeSpec;
  @Prop(Boolean) isValid!: boolean;
  @Prop(String) errorMessage!: string;
  @Prop(Boolean) fullWidth!: boolean;
  @Prop(Boolean) disabled!: boolean;
  @Prop(Number) maxCharLength!: boolean;

  syncedMemberAttributeValue: string = this.memberAttributeValue
    ? this.memberAttributeValue
    : '';
  syncedListOptions: string[] = this.memberAttributeValue
    ? this.memberAttributeValue.split(', ')
    : [];

  syncedListToggleOption(listOption: string) {
    const { option } = this.groupUserAttributeSpec.groupUserAttribute;
    if (!option?.multiSelect) {
      if (this.isSelected(listOption)) {
        this.syncedListOptions = [];
        this.syncedMemberAttributeValue = '';
      } else {
        this.syncedListOptions = [listOption];
        this.syncedMemberAttributeValue = listOption;
      }
      return this.$emit('getListValue', this.syncedMemberAttributeValue);
    }

    if (this.isSelected(listOption)) {
      this.syncedListOptions = this.syncedListOptions.filter(
        (value) => value !== listOption
      );
    } else {
      this.syncedListOptions = [...this.syncedListOptions, listOption];
    }

    this.syncedMemberAttributeValue =
      this.syncedListOptions.map((value) => value).join(', ') || '';
    this.$emit(
      'getListValue',
      this.syncedListOptions.map((value) => value).join(', ') || ''
    );
  }

  isSelected(slug: string): boolean {
    return this.syncedListOptions.find((value) => value === slug)
      ? true
      : false;
  }

  @Watch('memberAttributeValue')
  onMemberAttributeValueChange() {
    this.syncedMemberAttributeValue = this.memberAttributeValue
      ? this.memberAttributeValue
      : '';
    this.syncedListOptions = this.memberAttributeValue
      ? this.memberAttributeValue.split(', ')
      : [];
  }
}
