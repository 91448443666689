
































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import BasePagination from '../../../../components/base/BasePagination.vue';
import BaseLoading from '../../../../components/base/BaseLoading.vue';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '../../../../components/base/pagination.mixin';
import BaseTable from '../../../../components/BaseTable.vue';
import TableActionDropdown from '../../../../components/TableActionDropdown.vue';
import DropdownMultiselect from '../../../../components/DropdownMultiselect.vue';
import SortableTableHeader from '../../../../components/SortableTableHeader.vue';
import {
  GroupTemplate,
  TemplateAttributeDisplayCount
} from '../../../../store/modules/admin/types/group-level-attribute.types';
import TemplateView from './TemplateView.vue';
import { SortOrder } from '@/store/types/general.types';

@Component({
  components: {
    BasePagination,
    SortableTableHeader,
    BaseTable,
    BaseLoading,
    TableActionDropdown,
    DropdownMultiselect,
    TemplateView
  }
})
export default class TemplateList extends mixins(PaginationMixin) {
  @Prop() public items!: GroupTemplate[];
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop(String) public sortColumn!: string;
  @Prop(String) public sortOrder!: SortOrder.ASC | SortOrder.DESC;

  public selectTemplate: GroupTemplate[] = [];

  get attributeDisplayCount(): number {
    return TemplateAttributeDisplayCount;
  }

  isViewMoreVisible(template: GroupTemplate): boolean {
    return template.groupTemplateAttributes.length > this.attributeDisplayCount;
  }

  getViewMoreText(template: GroupTemplate): string {
    return `+${
      template.groupTemplateAttributes.length - this.attributeDisplayCount
    } more`;
  }

  openTemplatesDetails(template: GroupTemplate) {
    this.$buefy.modal.open({
      parent: this,
      component: TemplateView,
      hasModalCard: true, // added to prevent breaking on mobile
      trapFocus: true,
      fullScreen: false,
      props: {
        template
      }
    });
  }

  public onHeaderColumnClick(columnName: string) {
    if (this.sortColumn === columnName) {
      return this.$emit('sort', {
        sortColumn: columnName,
        sortOrder:
          this.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC
      });
    }

    this.$emit('sort', {
      sortColumn: columnName,
      sortOrder: SortOrder.DESC
    });
  }

  public getSortOrderOfColumn(name: string) {
    if (this.sortColumn === name) {
      return this.sortOrder;
    }
    return SortOrder.ASC;
  }

  @Watch('selectTemplate')
  onSelect() {
    this.$emit('selectedTemplate', this.selectTemplate);
  }
}
