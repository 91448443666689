
































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  GroupLevelAttributeValue,
  GroupLevelAttributeUploadableSignedUrlRequestPayload,
  ImageFileSizeLimit
} from '../../../../../store/modules/admin/types/group-level-attribute.types';
import { Action, State } from 'vuex-class';
import { ApiState } from '@/store/types/general.types';
import { RootState } from '../../../../../store/store';
import { GetSignedUrlForUploadResponsePayload } from '../../../../../store/modules/static-file/types/static-file.types';
import { ToastProgrammatic as Toast } from 'buefy';
import { BLoadingComponent } from 'buefy/types/components';
import { PermissionsMatrixActionsEnum } from '../../../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '../../../../../utils/rbac.util';
import { EntityTypes } from '../../../../../store/modules/module-tree/enums/module-tree.enums';

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {}
})
export default class LogoAttributeField extends Vue {
  @Prop() attributeValue!: GroupLevelAttributeValue;
  @Prop() fieldClasses!: any;
  @Prop({ default: false }) isSettings!: boolean;
  @Prop() groupTypeName!: string;
  @Prop() groupId!: number;
  @Prop() deleteAttribute!: () => void;
  @Prop() updateAttribute!: (attribute: GroupLevelAttributeValue) => void;

  @Action('staticFile/getSignedUrlForGroupLevelAttributeUploadable')
  getUploadableSignedUrl!: (payload: {
    data: GroupLevelAttributeUploadableSignedUrlRequestPayload[];
  }) => void;

  @State(
    ({ staticFile }: RootState) =>
      staticFile.apiState.getGroupLevelAttributeSignedUrls
  )
  public getSignedUrlsState!: ApiState;

  @State(
    ({ staticFile }: RootState) => staticFile.groupLevelAttributeSignedUrls
  )
  public uploadableSignedUrls!:
    | GetSignedUrlForUploadResponsePayload[]
    | undefined;

  showField: boolean = false;
  labelErrorMsg: string = '';
  fieldErrorMsg: string = '';

  // file
  logoFile: File | null = null;
  isUploading: boolean | undefined = false;
  spinner: BLoadingComponent | undefined = undefined;

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string
  ): boolean {
    const instance = EntityTypes.GROUP + '_' + this.groupId;
    return this.isSettings
      ? isUserAllowed(
          action,
          module,
          EntityTypes.GROUP,
          this.groupTypeName,
          this.groupId
        )
      : true;
  }

  mounted() {
    this.emitValueToParent();
  }

  removeContent() {
    this.attributeValue.value = '';
  }

  processFile(logo: File) {
    if (logo.size > ImageFileSizeLimit) {
      Toast.open({
        message: `Logo '${logo.name}' has exceeded size limitation.`,
        type: 'is-danger',
        position: 'is-top'
      });
      this.clearUploadingState();
      return;
    }

    this.activateLoadingSpinner();
    this.isUploading = true;
    this.getSignedUrl([logo]);
  }

  getSignedUrl(files: File[]) {
    return this.getUploadableSignedUrl({
      data: files.map((file: File) => {
        return {
          fileName: file.name
            .replaceAll(' ', '_')
            .replaceAll('(', '_')
            .replaceAll(')', '_'),
          contentType: file.type,
          isLogo: true
        };
      })
    });
  }

  async uploadFileToSignedUrl(
    files: File[],
    signedUrls: GetSignedUrlForUploadResponsePayload[]
  ) {
    try {
      files.forEach((file, fileIndex) => {
        const formData = new FormData();
        formData.append('file', file as Blob);

        return new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.open('PUT', signedUrls[fileIndex].signedUrl, true);

          xhr.onload = (e) => {
            this.attributeValue.value = signedUrls[fileIndex].storageUrl;
            return resolve(signedUrls);
          };

          xhr.onerror = (error) => {
            return reject(error);
          };

          xhr.send(file);
        });
      });
    } catch (error) {
      Toast.open({
        message: 'Upload failed. Please try again.',
        type: 'is-danger',
        position: 'is-top'
      });
    }
  }

  showUploadToastError(message?: string) {
    Toast.open({
      message: message || 'Upload failed. Please try again.',
      type: 'is-danger',
      position: 'is-top'
    });
  }

  activateLoadingSpinner() {
    this.spinner = this.$buefy.loading.open({
      container: this.$refs.imageDiv
    });
  }

  clearUploadingState() {
    this.logoFile = null;
    this.isUploading = false;
    this.spinner?.close();
  }

  @Watch('getSignedUrlsState', { deep: true })
  onStateChange(state: ApiState) {
    if (state.error) {
      // reset on error
      this.showUploadToastError();
      this.clearUploadingState();
    }
  }

  @Watch('uploadableSignedUrls', { deep: true })
  uploadOnUrlRequestSuccess(
    signedUrls: GetSignedUrlForUploadResponsePayload[]
  ) {
    // prettier-ignore
    if (signedUrls && signedUrls.some((signedUrl: GetSignedUrlForUploadResponsePayload) => signedUrl.isLogo)) {
      // additional checking to workaround when logo gets replaced when state changes
      // TODO: Look into this shared watcher issue
      this.uploadFileToSignedUrl([this.logoFile] as File[], signedUrls)
        .then()
        .catch((e) => this.showUploadToastError())
        .finally(() => this.clearUploadingState());
    }
  }

  @Watch('attributeValue.value')
  emitValueToParent() {
    this.updateAttribute(this.attributeValue);
  }
}
