

































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  GroupLevelAttributeValue,
  GroupLevelAttributeOption
} from '../../../../store/modules/admin/types/group-level-attribute.types';
import AttributeButtons from './AttributeButtons.vue';
import DraggableIcon from './DraggableIcon.vue';

@Component({
  components: {
    AttributeButtons,
    DraggableIcon
  }
})
export default class GroupLevelAttributeCoordinatesField extends Vue {
  @Prop() attributeValue!: GroupLevelAttributeValue;
  @Prop() fieldClasses!: any;
  @Prop() duplicationError!: boolean;
  @Prop() deleteAttribute!: () => void;
  @Prop() updateAttribute!: (attribute: GroupLevelAttributeValue) => void;
  showField: boolean = false;
  labelErrorMsg: string = '';
  latitudeErrorMsg: string = '';
  longitudeErrorMsg: string = '';

  // shorthand referenced for rendering only
  latitude: GroupLevelAttributeOption = (this.attributeValue
    .value as GroupLevelAttributeOption[])[0];
  longitude: GroupLevelAttributeOption = (this.attributeValue
    .value as GroupLevelAttributeOption[])[1];

  mounted() {
    this.validateLabel();
    this.validateRequiredFieldValues();
  }

  /**
   * Validates attribute label content.
   * Displays label field if any error present.
   */
  validateLabel(): void {
    if (this.attributeValue.label === '') {
      this.labelErrorMsg = 'Label cannot be empty.';
      this.attributeValue.hasFieldError = true;
      this.showField = true;
    } else if (this.duplicationError) {
      this.labelErrorMsg = `Duplicated label '${this.attributeValue.label}'.`;
      this.attributeValue.hasFieldError = true;
      this.showField = true;
    } else {
      this.attributeValue.hasFieldError = false;
      this.labelErrorMsg = '';
    }
  }

  hideLabelAndValidate(event: any): void {
    this.validateLabel();
    this.validateRequiredFieldValues();

    // hide field on enter, esc, or blur + no error
    if (event.keyCode === 13 || event.keyCode === 27 || event.type === 'blur') {
      if (this.labelErrorMsg.length === 0) {
        this.showField = false;
      }
    }
  }

  /* Valid latitude range: from 90 to -90
   * Valid longitude range: from 180 to -180
   *
   * Non-mandatory field can be left empty,
   * but any input values must be valid coordinate values.
   * Validate each field separately. */
  areCoordinatesValid(): boolean {
    const latitude: number = parseFloat(this.latitude.value as string);
    const longitude: number = parseFloat(this.longitude.value as string);

    if (latitude < -90 || latitude > 90) {
      this.latitudeErrorMsg =
        'Latitude value out of range. (Value should range between -90 and 90.)';
    } else {
      this.latitudeErrorMsg = '';
    }

    if (longitude < -180 || longitude > 180) {
      this.longitudeErrorMsg =
        'Longitude value out of range. (Value should range between -180 and 180.)';
    } else {
      this.longitudeErrorMsg = '';
    }

    if (this.latitudeErrorMsg.length || this.longitudeErrorMsg.length) {
      return false;
    }

    return true;
  }

  /**
   * Add a separate watcher for "hasDuplicationError" property
   * because this property is updated by parent.
   */
  @Watch('duplicationError')
  duplicationCallback(currentError: boolean, previousError: boolean): void {
    this.validateLabel();
    this.updateAttribute(this.attributeValue);
  }

  @Watch('attributeValue.value', { deep: true })
  validateRequiredFieldValues() {
    const coordinatesValidated = this.areCoordinatesValid();
    if (!coordinatesValidated) {
      this.attributeValue.hasFieldError = true;
      this.updateAttribute(this.attributeValue);
      return;
    }

    if (this.attributeValue.isRequired) {
      for (const coordinate of [
        this.latitude,
        this.longitude
      ] as GroupLevelAttributeOption[]) {
        switch (coordinate.placeholder) {
          case 'Latitude':
            this.latitudeErrorMsg = !coordinate.value
              ? 'Latitude cannot be empty.'
              : '';

          case 'Longitude':
            this.longitudeErrorMsg = !coordinate.value
              ? 'Longitude cannot be empty.'
              : '';
        }
      }

      this.attributeValue.hasFieldError = ([
        this.latitude,
        this.longitude
      ] as GroupLevelAttributeOption[]).some(
        (coordinate: GroupLevelAttributeOption) => {
          return coordinate.value === '' || coordinate.value === null;
        }
      );

      if (this.attributeValue.hasFieldError) {
        this.updateAttribute(this.attributeValue);
        return;
      }
    }

    this.latitudeErrorMsg = '';
    this.longitudeErrorMsg = '';
    this.attributeValue.hasFieldError = false;
    this.updateAttribute(this.attributeValue);
  }
}
