import {
  GroupUserAttributeOption,
  GroupUserAttributeType,
  GroupUserAttributeTypeStatus,
  MyjbiGroupUserAttribute,
  MyjbiGroupUserAttributeList
} from '@/jbi-shared/types/myjbi-group.types';
import { ApiState, SortOrder } from '@/store/types/general.types';
import { DeepPartial } from 'typeorm';
import { CurrentUserAttribute } from './admin.types';

type Nullable<T> = T | null;

export interface GroupUserAttribute {
  id?: number;
  name: string;
  slug: string;
  option: Nullable<GroupUserAttributeOption>;
  status: GroupUserAttributeStatus;
  createdAt?: Date;
  updatedAt?: Date;
  groupUserAttributeType: GroupUserAttributeType;
}

export interface GroupUserAttributeWithGroupCount extends GroupUserAttribute {
  groupCount: number;
}

export interface GroupUserAttributePayload {
  label: string;
  status: GroupUserAttributeTypeStatus;
  option: GroupUserAttributeOption | null;
  groupUserAttributeType: GroupUserAttributeType;
}

export interface UpdateGroupsWithAttributePayload {
  attributeId: number;
  selectedGroupIds: number[];
  selectedRequiredGroupIds: number[];
  selectedLockedGroupIds: number[];
}

export interface UpdateGroupsUserAttributePayload {
  label: string;
  groupUserAttribute: DeepPartial<MyjbiGroupUserAttribute>;
}

export interface UpdateGroupsUserAttributeResponse {
  message: string;
  group_user_attribute: MyjbiGroupUserAttribute;
}

export interface GroupUserAttributeWithValue {
  groupUserAttribute: MyjbiGroupUserAttribute;
  value: string;
}

export interface NewGroupUserAttributeResponse {
  message: string;
  group_user_attribute: MyjbiGroupUserAttribute;
}

export interface GetUploadImageSignedUrlResponse {
  signedUrl: string;
  storageUrl: string;
  storageUri: string;
}

export interface GetUploadImageSignedUrlPayload {
  fileName: string;
  contentType: string;
}

export interface GetGroupUserAttributeListPayload {
  attributeLabel?: string;
  sortOrder?: SortOrder;
  sortColumn?: string;
  page?: number;
  limit?: number;
}

export interface GetGroupUserAttributeListResponse {
  totalCount: number;
  items: MyjbiGroupUserAttributeList[];
}

export const ImageFileSizeLimit = 400000; // Size 400Kb

export enum GroupUserAttributeStatus {
  'DELETE' = 'DELETE',
  'ACTIVE' = 'ACTIVE'
}

export interface BulkUpdateMemberGroupUserAttributeValuePayload {
  memberGroupUserAttributeValues: MemberGroupUserAttributeValue[];
}

export interface MemberGroupUserAttributeValue {
  groupId: number;
  userAttributeId: number;
  value: string;
}

export interface CurrentUserAttributeByGroup {
  [key: string]: CurrentUserAttributeByGroupBody[];
}

export interface CurrentUserAttributeByGroupBody {
  parentGroup: CurrentUserAttribute;
  subgroups: CurrentUserAttribute[];
}

export interface UserAttributeState {
  apiState: UserAttributeStateApiState;
  isMissingAttributeValue: boolean | undefined;
}

export interface UserAttributeStateApiState {
  getCurrentUserAttributeValueValidity: ApiState;
}
