var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-card has-modal-large"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{class:_vm.$style.title},[_vm._v("Create New Attribute")]),_c('button',{staticClass:"close-icon",attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_c('i',{staticClass:"mdi mdi-close"})])]),_c('section',{staticClass:"modal-card-body"},[_c('div',{class:_vm.$style.container},[_c('div',[_c('b-field',{class:_vm.$style.requiredToggle},[_c('b-switch',{attrs:{"disabled":_vm.selectedAttributeType.type === 'image'},model:{value:(_vm.isRequired),callback:function ($$v) {_vm.isRequired=$$v},expression:"isRequired"}},[_vm._v("Required")])],1),_c('b-field',{attrs:{"label":"Content Type"}},[_c('b-dropdown',{class:_vm.$style.dropdown,attrs:{"expanded":"","aria-role":"list"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var _obj;

var active = ref.active;return [_vm._t("customTrigger",[_c('div',{class:_vm.$style.trigger},[_c('div',{class:_vm.$style.text},[_c('span',{class:( _obj = {
                        'has-text-dark': _vm.selectedAttributeType.type
                      }, _obj[_vm.$style.capitalize] = _vm.selectedAttributeType.type, _obj )},[_vm._v(" "+_vm._s(_vm.selectedAttributeType.type ? _vm.selectedAttributeType.type : 'Select an attribute type')+" ")])]),_c('b-icon',{attrs:{"icon":active ? 'chevron-up' : 'chevron-down'}})],1)])]}}],null,true),model:{value:(_vm.selectedAttributeType),callback:function ($$v) {_vm.selectedAttributeType=$$v},expression:"selectedAttributeType"}},_vm._l((_vm.allGroupUserAttributeTypes),function(attributeType,index){return _c('b-dropdown-item',{key:index,class:_vm.$style.dropdownItem,attrs:{"value":attributeType,"aria-role":"listitem"},nativeOn:{"click":function($event){return _vm.validateLabelAndType($event)}}},[_vm._v(" "+_vm._s(attributeType.type)+" ")])}),1)],1),_c('b-field',{attrs:{"label":"Label","type":_vm.isDuplicateAttributeFound ? 'is-warning' : ''}},[_c('b-input',{attrs:{"placeholder":"Attribute Label Name"},model:{value:(_vm.attributeLabelName),callback:function ($$v) {_vm.attributeLabelName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"attributeLabelName"}})],1),(_vm.isDuplicateAttributeFound)?_c('div',{staticClass:"columns is-vcentered",class:_vm.$style.duplicateLabel},[_c('div',{staticClass:"column is-three-quarters p-3"},[_vm._v(" An attribute with the same label already exists: "),_c('strong',[_vm._v(_vm._s(_vm.attributeLabelName))]),_c('br'),_vm._v(" Are you sure you want to use this label? ")]),_c('div',{staticClass:"column has-text-primary",class:_vm.$style.actionBtn,on:{"click":_vm.onUseLabel}},[_vm._v(" Use Label ")])]):_vm._e()],1),(_vm.selectedAttributeType.option !== null)?_c('div',{class:_vm.$style.attrOptions},[(
            _vm.selectedAttributeType.type === 'list' &&
            _vm.attributeLabelName.length >= 1
          )?_c('div',[_c('label',{class:_vm.$style.label},[_vm._v("Checkbox Type")]),_c('div',{staticClass:"columns",class:_vm.$style.noMarginTop},_vm._l((_vm.selectedAttributeType.option),function(typeOption,index){return _c('div',{key:index,staticClass:"column is-half",class:_vm.$style.attributeTypeOption,attrs:{"value":typeOption}},[_c('b-field',[_c('b-radio',{attrs:{"name":"typeOption","native-value":typeOption},model:{value:(_vm.selectedTypeOption),callback:function ($$v) {_vm.selectedTypeOption=$$v},expression:"selectedTypeOption"}},[_vm._v(" "+_vm._s(typeOption.placeholder)+" ")])],1)],1)}),0),(_vm.selectedTypeOption)?_c('div',{staticClass:"label-container align-left"},[_c('label',{class:_vm.$style.label},[_vm._v("Answer")]),_vm._l((_vm.attributeOptionValues),function(item,index){return _c('li',{key:index,class:_vm.$style.optionlistItem,on:{"click":function($event){return _vm.removeOptionListItem(
                  $event,
                  _vm.$style.optionlistItemDeleteBtn,
                  index
                )}}},[_c('b-icon',{class:_vm.$style.optionlistItemSquareBox,attrs:{"icon":"square-outline","size":"is-small"}}),_c('b-field',{class:_vm.$style.field,staticStyle:{"width":"100%","margin-bottom":"5px"},attrs:{"type":_vm.attributeOptionErrorIndexes.includes(index)
                    ? 'is-danger'
                    : undefined,"message":_vm.attributeOptionErrorIndexes.includes(index)
                    ? 'This Option already exists.'
                    : ''}},[_c('b-input',{attrs:{"placeholder":""},on:{"input":_vm.checkAttributeOptionDuplicateValues},model:{value:(_vm.attributeOptionValues[index]),callback:function ($$v) {_vm.$set(_vm.attributeOptionValues, index, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"attributeOptionValues[index]"}})],1),_c('b-icon',{class:_vm.$style.optionlistItemDeleteBtn,attrs:{"icon":"delete","size":"is-small","type":"is-danger"}})],1)}),_c('div',[_c('a',{staticClass:"has-text-weight-semibold",attrs:{"href":"#"},on:{"click":_vm.addOptionListItem}},[_vm._v(" + Add Item ")])])],2):_vm._e()]):_vm._e()]):_vm._e(),_c('hr',{class:_vm.$style.hrMargin}),_c('b-field',[_c('b-checkbox',{model:{value:(_vm.lockAttribute),callback:function ($$v) {_vm.lockAttribute=$$v},expression:"lockAttribute"}},[_c('p',{staticClass:"m-t-1"},[_vm._v(" Lock Attribute "),_c('i',{staticClass:"mdi mdi-lock-outline",class:_vm.$style.lock})]),_c('p',{class:_vm.$style.label},[_vm._v(" This attribute can only be edited by admin once it is locked. ")])])],1)],1)]),_c('footer',{staticClass:"modal-card-foot"},[_c('b-button',{attrs:{"disabled":_vm.isButtonDisabled(),"type":"is-primary"},on:{"click":_vm.createAttribute}},[_vm._v(" Create ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }