


















import { Vue, Component, Prop } from 'vue-property-decorator';
import { VueConstructor } from 'vue';
import { GroupLevelAttributeValue } from '../../../../store/modules/admin/types/group-level-attribute.types';

@Component({})
export default class FieldWrapper extends Vue {
  @Prop({ type: Function, required: true })
  public component!: VueConstructor;

  get groupLevelAttributeProps() {
    return {
      ...this.$props,
      ...this.$attrs,
      // generic functions
      // to prompt parent to update values in children
      updateAttribute: (attribute: GroupLevelAttributeValue) => {
        this.$emit('updateAttribute', attribute);
      },

      deleteAttribute: () => {
        this.$emit('deleteAttribute');
      }
    };
  }
}
