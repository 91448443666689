






































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import {
  FilteredGroupTemplatesPayload,
  GroupLevelAttributeValue,
  GroupLevelAttributeValueStatus,
  GroupTemplate,
  GroupTemplateAttribute,
  AttributeValueType
} from '../../../../store/modules/admin/types/group-level-attribute.types';
import BasePaginatorHoc from '../../../../components/base/BasePaginatorHoc.vue';
import TemplateList from './TemplateList.vue';
import BaseLoading from '../../../../components/base/BaseLoading.vue';
import { RootState } from '../../../../store/store';
import { Debounce } from '../../../../jbi-shared/util/debounce.vue-decorator';
import { Pagination } from 'nestjs-typeorm-paginate';
import { SortOrder } from '@/store/types/general.types';

@Component({
  components: {
    BasePaginatorHoc,
    TemplateList,
    BaseLoading
  }
})
export default class GroupTemplateModal extends Vue {
  public perPage = 50;
  public page = 1;
  public sortColumn: string = 'templateName';
  public sortOrder: SortOrder = SortOrder.ASC;
  filteredParams = '';
  selectedTemplateName: string = '';

  public allSelectedAttributes: GroupLevelAttributeValue[] = [];

  @Action('admin/getGroupTemplates')
  public getGroupTemplates!: (options: FilteredGroupTemplatesPayload) => void;

  @State((state: RootState) => state.admin.groupTemplates)
  public groupTemplates!: Pagination<GroupTemplate>;

  @State((state: RootState) => state.admin.apiState.getGroupTemplates.success)
  public groupTemplatesSuccess!: boolean;

  get TemplateList() {
    return TemplateList;
  }

  get templates(): GroupTemplate[] {
    return this.groupTemplates ? this.groupTemplates?.items : [];
  }

  getRouteConfig() {
    return {
      name: 'admin-group-management',
      query: {
        tab: 'GroupTemplates',
        templateName: this.filteredParams,
        limit: this.perPage.toString() || '50',
        page: this.page.toString() || '1',
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder || SortOrder.ASC
      }
    };
  }

  /**
   * Close modal on click.
   * Should there be any template changes, in group management page,
   * it should be reflected when modal opens up again.
   */
  handleLinkClick(): void {
    this.closeModal();
  }

  selectedTemplate(template: GroupTemplate) {
    this.allSelectedAttributes = template.groupTemplateAttributes.map(
      (attr) => {
        return {
          order: 0,
          label: attr.label,
          value: this.getAttributeValueFromOption(attr),
          isRequired: attr.isRequired ? attr.isRequired : false,
          status: GroupLevelAttributeValueStatus.ACTIVE,
          groupLevelAttributeType: attr.groupLevelAttributeType,
          hasFieldError: attr.isRequired ? true : false,
          hasDuplicationError: false,
          isDefault: false,
          isTitle: false
        };
      }
    );

    this.selectedTemplateName = template.name;
  }

  getAttributeValueFromOption(
    attribute: GroupTemplateAttribute
  ): AttributeValueType {
    switch (attribute.groupLevelAttributeType.type) {
      case 'images':
      case 'documents':
        return [];
      default:
        /* default case covers 'list' type as well */
        return attribute.option;
    }
  }

  isButtonDisabled(): boolean {
    return this.allSelectedAttributes.length ? false : true;
  }

  useTemplate() {
    if (this.allSelectedAttributes.length) {
      this.$emit('addGroupTemplateAttributes', this.allSelectedAttributes);
      this.$emit('updateTemplateName', this.selectedTemplateName);
      this.selectedTemplateName = '';
      this.closeModal();
    }
  }

  public handlePaginator({ perPage, page }: { perPage: number; page: number }) {
    this.perPage = perPage;
    this.page = page;
    this.getGroupTemplates({
      templateName: this.filteredParams,
      limit: this.perPage,
      page: this.page,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder
    });
  }

  public handleSort(data: any) {
    this.sortColumn = data.sortColumn;
    this.sortOrder = data.sortOrder;

    this.getGroupTemplates({
      templateName: this.filteredParams,
      limit: this.perPage,
      page: this.page,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder
    });
  }

  public handleFilter() {
    this.perPage = this.perPage;
    this.getGroupTemplates({
      templateName: this.filteredParams,
      limit: this.perPage,
      page: 1,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder
    });
  }

  public mounted() {
    this.getGroupTemplates({
      templateName: this.filteredParams,
      limit: this.perPage,
      page: 1,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder
    });
  }

  closeModal() {
    this.$emit('close');
  }

  @Watch('filteredParams')
  @Debounce(600)
  public onChange() {
    this.handleFilter();
  }
}
