import { SortOrder } from '@/store/types/general.types';

/* NOTE:
 * Whenever there's any update in this file,
 * please remember to update their counterparts in jbi-shared.
 * file path: jbi-shared/types/group-level-attributes.types.ts */

export enum GroupLevelAttributeValueStatus {
  'DELETE' = 'DELETE',
  'ACTIVE' = 'ACTIVE'
}

export enum GroupLevelAttributeTemplateStatus {
  'DELETE' = 'DELETE',
  'ACTIVE' = 'ACTIVE'
}

export enum AddressCountryStatus {
  'DELETE' = 'DELETE',
  'ACTIVE' = 'ACTIVE'
}

export enum AddressStateStatus {
  'DELETE' = 'DELETE',
  'ACTIVE' = 'ACTIVE'
}

export enum GroupTemplateStatus {
  'DELETE' = 'DELETE',
  'ACTIVE' = 'ACTIVE'
}

export enum GroupTemplateAttributeStatus {
  'DELETE' = 'DELETE',
  'ACTIVE' = 'ACTIVE'
}

/**
 * This interface covers the overlapped properties for both
 * Group Level Attributes and Group Template Attributes.
 */
export interface GroupAttribute {
  order: number;
  label: string;
  isRequired: boolean;
  groupLevelAttributeType: GroupLevelAttributeType;
  id?: number;
  createdDate?: Date;
  updatedDate?: Date;
  deletedDate?: Date | null;
  hasDuplicationError?: boolean;
}

export type AttributeValueType =
  | GroupLevelAttributeOption[]
  | GroupLevelAttributeListType
  | string
  | string[]
  | null;

export type AttributeOptionValueType = string | string[] | null;

export interface GroupLevelAttributeListType {
  options: string[];
  selected: string[] | string;
  isSingleSelection: boolean;
}

export interface GroupLevelAttributeOptionType {
  id: number;
  type: string;
}

export interface GroupLevelAttributeOption {
  placeholder: string;
  value: AttributeOptionValueType;
  status: GroupLevelAttributeValueStatus;
  attributeType: GroupLevelAttributeOptionType;
  isRequired?: boolean;
}

export interface GroupLevelAttributeType {
  id: number;
  type: string;
  option: GroupLevelAttributeOption[] | GroupLevelAttributeListType | null;
}

export interface GroupLevelAttributeValue extends GroupAttribute {
  value: AttributeValueType;
  status: GroupLevelAttributeValueStatus;
  // props below are only being used in front end for rendering
  hasFieldError?: boolean;
  isTitle?: boolean;
  isDefault?: boolean;
  isDisabled?: boolean;
}

export interface DraggedAttributeValueEvent {
  moved: {
    element: GroupLevelAttributeValue;
    oldIndex: number;
    newIndex: number;
  };
}

export interface GroupLevelAttributeValuePayload {
  groupId: number;
  attributeValues: {
    selected: GroupLevelAttributeValue[];
    deleted?: GroupLevelAttributeValue[];
  };
}

export interface GroupLevelAttributeValueErrorMsg {
  label: string;
  field: string;
}

export interface GroupLevelAttributeUploadableSignedUrlRequestPayload {
  fileName: string;
  contentType: string;
  isLogo?: boolean;
}

export interface GroupTitleAndLogo {
  title: string;
  logoUrl: string;
}

export interface UploadableFieldMessage {
  message: string;
  type: string;
}

export const DocumentFileSizeLimit = 5000000;
export const ImageFileSizeLimit = 2000000;

export interface GetSignedUrlForUploadResponsePayload {
  signedUrl: string;
  storageUrl: string;
  storageUri: string;
  fileName: string;
}

export interface GroupTemplateAttribute extends GroupAttribute {
  option: AttributeValueType;
  status: GroupTemplateAttributeStatus;
}

export interface GroupTemplatePayload {
  id?: number;
  templateName: string;
  attributes: {
    active: GroupTemplateAttribute[];
    deleted?: GroupTemplateAttribute[];
  };
  groupId?: number;
}

export interface GroupTemplateSortSpecs {
  sortColumn: string;
  sortOrder: SortOrder;
}

/**
 * // Endpoint hidden for upcoming restructuring of attribute templates.
 *
 * export interface GroupLevelAttributeTemplate {
 *  id: number;
 *  name: string;
 *  label: string;
 *  option: AttributeValueType;
 *  isRequired?: boolean;
 *  status: GroupLevelAttributeTemplateStatus;
 *  groupLevelAttributeType: GroupLevelAttributeType;
 * }
 */

export interface GroupTemplate {
  id?: number;
  name: string;
  status?: GroupTemplateStatus;
  groupTemplateAttributes: GroupTemplateAttribute[];
}

/* Total number of template attributes to display before "view more" */
export const TemplateAttributeDisplayCount: number = 5;

/* Filtered payload used when fetching group templates */
export interface FilteredGroupTemplatesPayload {
  templateName?: string;
  sortOrder?: SortOrder;
  sortColumn?: string;
  page?: number;
  limit?: number;
}

export interface AddressFilterOption {
  countryName?: string;
  stateName?: string;
}

export interface AddressCountry {
  id: number;
  name: string;
  country_code: string;
  country_code_alpha3: string;
  phone: string;
  currency: string;
  status: AddressCountryStatus;
  states: AddressState[];
}

export interface AddressState {
  id: number;
  name: string;
}
