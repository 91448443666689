var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-card has-modal-large",class:_vm.$style.modal},[_c('header',{class:['modal-card-head', _vm.$style.modalHeader]},[_c('div',{class:_vm.$style.headerContent},[_c('h1',{staticClass:"title has-text-centered"},[_vm._v("Create new attribute")]),_c('b-button',{class:_vm.$style.closeModalButton,on:{"click":_vm.closeModal}},[_c('b-icon',{attrs:{"icon":"close"}})],1)],1)]),_c('section',{staticClass:"modal-card-body",class:_vm.$style.modalBody},[_c('div',{class:_vm.$style.bodyContent},[_c('div',[_c('b-field',{class:_vm.$style.requiredToggle},[_c('b-switch',{model:{value:(_vm.isRequired),callback:function ($$v) {_vm.isRequired=$$v},expression:"isRequired"}},[_vm._v("Required")])],1),_c('b-field',{attrs:{"label":"Content Type"}},[_c('b-dropdown',{attrs:{"expanded":"","aria-role":"list"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var _obj;

var active = ref.active;return [_vm._t("customTrigger",[_c('div',{class:_vm.$style.trigger},[_c('div',{class:_vm.$style.text},[_c('span',{class:( _obj = {
                        'has-text-dark': _vm.selectedAttributeType.type
                      }, _obj[_vm.$style.capitalize] = _vm.selectedAttributeType.type, _obj )},[_vm._v(" "+_vm._s(_vm.selectedAttributeType.type ? _vm.selectedAttributeType.type : 'Select an attribute type')+" ")])]),_c('b-icon',{attrs:{"icon":active ? 'chevron-up' : 'chevron-down'}})],1)])]}}],null,true),model:{value:(_vm.selectedAttributeType),callback:function ($$v) {_vm.selectedAttributeType=$$v},expression:"selectedAttributeType"}},_vm._l((_vm.allAttributeTypes),function(attributeType,index){return _c('b-dropdown-item',{key:index,attrs:{"value":attributeType,"aria-role":"listitem"},on:{"click":_vm.validateLabelAndTypeDuplication}},[_vm._v(" "+_vm._s(attributeType.type)+" ")])}),1)],1),_c('b-field',{class:_vm.$style.nameField,attrs:{"label":"Label","message":_vm.duplicationErrorMsg,"type":_vm.duplicationErrorMsg.length ? 'is-danger' : ''}},[_c('b-input',{attrs:{"placeholder":"Attribute Label Name"},nativeOn:{"keyup":[function($event){return _vm.validateLabelAndTypeDuplication($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.processAttribute($event)}]},model:{value:(_vm.attributeLabelName),callback:function ($$v) {_vm.attributeLabelName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"attributeLabelName"}})],1)],1),(_vm.selectedAttributeType.option)?_c('div',[(
            _vm.selectedAttributeType.type === 'address' ||
            _vm.selectedAttributeType.type === 'coordinates'
          )?_c('div',{class:_vm.$style.attrOptions},_vm._l((_vm.selectedAttributeType.option),function(configObj,confIndex){return _c('b-field',{key:confIndex,class:_vm.$style.paddingRight},[_c('b-switch',{class:_vm.$style.switch,attrs:{"left-label":true,"false-value":'DELETE',"true-value":'ACTIVE',"disabled":configObj.isRequired},model:{value:(configObj.status),callback:function ($$v) {_vm.$set(configObj, "status", $$v)},expression:"configObj.status"}},[_vm._v(" "+_vm._s(configObj.placeholder)+" ")])],1)}),1):_vm._e()]):_vm._e(),(_vm.selectedAttributeType.type === 'list')?_c('div',{class:_vm.$style.listOptions},[_c('b-field',[_c('b-radio',{attrs:{"native-value":true},model:{value:(_vm.isSingleSelection),callback:function ($$v) {_vm.isSingleSelection=$$v},expression:"isSingleSelection"}},[_vm._v(" Single selection ")])],1),_c('b-field',[_c('b-radio',{attrs:{"native-value":false},model:{value:(_vm.isSingleSelection),callback:function ($$v) {_vm.isSingleSelection=$$v},expression:"isSingleSelection"}},[_vm._v(" Multiple selections ")])],1)],1):_vm._e()])]),_c('footer',{class:['modal-card-foot', _vm.$style.modalFooter]},[_c('div',{class:_vm.$style.footerContent},[_c('b-button',{attrs:{"disabled":_vm.isButtonDisabled(),"type":"is-primary"},on:{"click":_vm.processAttribute}},[_vm._v(" Create ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }