








































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  GroupAttribute,
  GroupLevelAttributeType,
  GroupLevelAttributeOption,
  GroupLevelAttributeValue,
  GroupLevelAttributeValueStatus,
  GroupTemplateAttribute,
  GroupTemplateAttributeStatus,
  GroupLevelAttributeListType
} from '../../../../store/modules/admin/types/group-level-attribute.types';

@Component({})
export default class AddGroupLevelAttributeModal extends Vue {
  @Prop() allAttributeTypes!: GroupLevelAttributeType[];
  @Prop() groupId!: number;
  @Prop() attributes!: GroupAttribute[];
  @Prop() isTemplateAttribute!: boolean;

  defaultAttribute = {
    id: 0,
    type: '',
    attributeType: undefined,
    option: null,
    label: '',
    value: null
  };
  isRequired: boolean = false;
  selectedAttributeType: GroupLevelAttributeType = this.defaultAttribute;
  attributeLabelName: string = '';
  duplicationError: boolean = false;
  duplicationErrorMsg: string = '';
  isSingleSelection: boolean = true;

  get isDefault(): boolean {
    return (
      (this.attributeLabelName.trim().toLowerCase() === 'title' &&
        this.selectedAttributeType.type === 'text') ||
      (this.attributeLabelName.trim().toLowerCase() === 'logo' &&
        this.selectedAttributeType.type === 'images')
    );
  }

  /**
   * This modal can be used to create (by emitting) two types of attributes:
   * 1. group level attributes
   * 2. group template attributes
   *
   * It creates group level attribute by default.
   */
  validateLabelAndTypeDuplication(): void {
    // skip validation when label is empty
    if (this.attributeLabelName === '') {
      return;
    }

    if (this.isDefault) {
      this.duplicationErrorMsg = `'${this.attributeLabelName}' is a default attribute. Please use a different type or label name.`;
      this.duplicationError = true;
      return;
    }

    const existingAttributeValue:
      | GroupAttribute
      | undefined = this.attributes.find((attribute) => {
      return (
        attribute.label.toLowerCase() ===
          this.attributeLabelName.toLowerCase() &&
        attribute.groupLevelAttributeType.type ===
          this.selectedAttributeType.type
      );
    });

    if (existingAttributeValue) {
      this.duplicationErrorMsg = `Attribute type '${this.selectedAttributeType.type}' with label '${this.attributeLabelName}' already exists. Please use a different type or label name.`;
      this.duplicationError = true;
      return;
    }

    this.duplicationErrorMsg = '';
    this.duplicationError = false;
  }

  isButtonDisabled(): boolean {
    return (
      this.attributeLabelName === '' ||
      this.selectedAttributeType.type === '' ||
      this.duplicationError
    );
  }

  processAttribute(): void {
    if (this.duplicationError) {
      return;
    }

    const newAttribute = {
      /**
       * New attribute item carries order of 0
       * once it's been emitted to the parent.
       */
      order: 0,
      label: this.attributeLabelName,
      isRequired: this.isRequired,
      groupLevelAttributeType: this.selectedAttributeType,
      hasDuplicationError: false
    } as GroupTemplateAttribute | GroupLevelAttributeValue;

    /**
     * Extra checking to set up value / option array
     * depending on whether this modal is used
     * to create group level attribute or group template attributes.
     *
     * Even though they carry the same value type,
     * "option" key only exists in template attributes,
     * "value" key only exists in group level attributes.
     */
    if (this.isTemplateAttribute) {
      (newAttribute as GroupTemplateAttribute).option = this
        .selectedAttributeType.option
        ? []
        : null;
      (newAttribute as GroupTemplateAttribute).status =
        GroupTemplateAttributeStatus.ACTIVE;
    } else {
      (newAttribute as GroupLevelAttributeValue).value = this
        .selectedAttributeType.option
        ? []
        : null;
      (newAttribute as GroupLevelAttributeValue).hasFieldError = this.isRequired;
      (newAttribute as GroupLevelAttributeValue).status =
        GroupLevelAttributeValueStatus.ACTIVE;
      (newAttribute as GroupLevelAttributeValue).isDefault = false; // only title and logo are true
    }

    // setup value key to appropriate types and value types for complex attributes
    switch (this.selectedAttributeType.type) {
      case 'address':
      case 'coordinates':
        (this.selectedAttributeType
          .option as GroupLevelAttributeOption[]).forEach((option) => {
          const valueOption: GroupLevelAttributeOption = {
            placeholder: option.placeholder,
            status: option.status,
            attributeType: option.attributeType,
            value: '',
            isRequired: option.isRequired
          };

          if (this.isTemplateAttribute) {
            ((newAttribute as GroupTemplateAttribute)
              .option as GroupLevelAttributeOption[]).push(valueOption);
          } else {
            ((newAttribute as GroupLevelAttributeValue)
              .value as GroupLevelAttributeOption[]).push(valueOption);
          }
        });
        break;
      case 'list':
        const listValue: GroupLevelAttributeListType = {
          options: [],
          selected: this.isSingleSelection ? '' : [],
          isSingleSelection: this.isSingleSelection
        };

        if (this.isTemplateAttribute) {
          ((newAttribute as GroupTemplateAttribute)
            .option as GroupLevelAttributeListType) = listValue;
        } else {
          ((newAttribute as GroupLevelAttributeValue)
            .value as GroupLevelAttributeListType) = listValue;
        }
        break;
      case 'documents':
      case 'images':
        // list, documents, images attributes value hold only string array
        if (this.isTemplateAttribute) {
          ((newAttribute as GroupTemplateAttribute).option as string[]) = [];
        } else {
          ((newAttribute as GroupLevelAttributeValue).value as string[]) = [];
        }
        break;
      default:
        break;
    }

    this.$emit('addNewAttribute', newAttribute);

    // clear and reset values after emit
    this.attributeLabelName = '';
    this.selectedAttributeType = this.defaultAttribute;

    this.closeModal();
  }

  closeModal() {
    this.$emit('close');
  }
}
