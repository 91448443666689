import { getHtmlContent } from '@/jbi-shared/util/group-level-attributes.util';
import {
  isContact,
  isEmail,
  isLink,
  validateEmailDomain
} from '@/jbi-shared/util/validate-email-domains.util';
import { GroupUserAttributeWithValue } from '@/store/modules/admin/types/group-user-attribute.types';
import { cloneDeep, isEqual } from 'lodash';
import {
  GroupUserAttributeType,
  MyjbiGroupDetail,
  MyjbiGroupUserAttributeSpec
} from '../jbi-shared/types/myjbi-group.types';
import { UserAttributesStringInputOption } from '../store/modules/admin/types/group.types';

export interface MemberObject {
  [s: string]: MemberObjectData | any;
}

export interface MemberObjectData {
  isRequired: boolean;
  isValid: boolean;
  value: string;
  errorMessage: string;
}

export interface AddExistingMemberPayload {
  data: MemberObject[];
  notify: boolean;
}

export interface UserAttributeValueValidationResult {
  isValid: boolean;
  errorMessage: string | null;
}

export const getAttributesAsStringInputOptions = (
  group: MyjbiGroupDetail | undefined
) =>
  group?.groupUserAttributeSpecs?.map(
    (spec) =>
      ({
        id: spec.groupUserAttribute.id ? spec.groupUserAttribute.id : null,
        slug: spec.groupUserAttribute.slug,
        name: spec.groupUserAttribute.name
      } as UserAttributesStringInputOption)
  );

export const getRequiredAttributesAsStringInputOptions = (
  group: MyjbiGroupDetail | undefined
) =>
  group?.groupUserAttributeSpecs
    ?.filter((spec) => spec.required)
    ?.map(
      (spec) =>
        ({
          id: spec.groupUserAttribute?.id ? spec.groupUserAttribute?.id : null,
          slug: spec.groupUserAttribute.slug,
          name: spec.groupUserAttribute.name
        } as UserAttributesStringInputOption)
    );

export const userAttributesArrWithValues = (
  membersData: MemberObject[],
  userAttributes: MyjbiGroupUserAttributeSpec[]
): GroupUserAttributeWithValue[][] => {
  if (membersData.length && userAttributes.length) {
    return membersData.map((user: MemberObject) => {
      return userAttributes.map((attribute: MyjbiGroupUserAttributeSpec) => {
        const { slug } = attribute.groupUserAttribute;
        if (typeof user[slug] === 'object') {
          return {
            groupUserAttribute: attribute.groupUserAttribute,
            value: user[slug]?.value ? user[slug]?.value : ''
          };
        } else {
          return {
            groupUserAttribute: attribute.groupUserAttribute,
            value: user[slug] ? user[slug] : ''
          };
        }
      });
    });
  } else {
    return [];
  }
};

export function hasSameAttributesAsParent(
  attributes: MyjbiGroupUserAttributeSpec[],
  parentGroup: MyjbiGroupDetail | undefined
) {
  attributes = cloneDeep(attributes);
  parentGroup = cloneDeep(parentGroup);
  const requiredAttributes = attributes.filter((attr) => attr.required);

  if (!parentGroup) {
    return true;
  }

  const parentAttributes = getAttributesAsStringInputOptions(parentGroup) || [];
  const parentRequiredAttributes =
    getRequiredAttributesAsStringInputOptions(parentGroup) || [];

  const attributesName = attributes
    .map((attr) => attr.groupUserAttribute.name)
    .sort();
  const attributesId = attributes
    .map((attr) => attr.groupUserAttribute.id)
    .sort();
  const parentAttributesName = parentAttributes
    ?.map((attr) => attr.name)
    .sort();
  const parentAttributesId = parentAttributes?.map((attr) => attr.id).sort();
  const hasSameAttributes =
    isEqual(attributesName, parentAttributesName) ||
    isEqual(attributesId, parentAttributesId);

  const requiredAttributesName = requiredAttributes
    .map((attr) => attr.groupUserAttribute.name)
    .sort();
  const requiredAttributesId = requiredAttributes
    .map((attr) => attr.groupUserAttribute.id)
    .sort();
  const requiredParentAttributesName = parentRequiredAttributes
    ?.map((a) => a.name)
    .sort();
  const requiredParentAttributesId = parentRequiredAttributes
    ?.map((a) => a.id)
    .sort();
  const hasSameRequiredAttributes =
    isEqual(requiredAttributesName, requiredParentAttributesName) ||
    isEqual(requiredAttributesId, requiredParentAttributesId);

  return hasSameAttributes && hasSameRequiredAttributes;
}

export function attributesHasChanged(
  attributes: MyjbiGroupUserAttributeSpec[],
  group: MyjbiGroupDetail | undefined
) {
  return !hasSameAttributesAsParent(attributes, group);
}

export const validatedGroupUserAttributeValue = (
  groupUserAttributeSpec: MyjbiGroupUserAttributeSpec,
  user: MemberObject,
  allowedEmailDomains: string[]
): MemberObject => {
  const slug: string = groupUserAttributeSpec.groupUserAttribute.slug;
  const groupUserAttributeType: GroupUserAttributeType =
    groupUserAttributeSpec.groupUserAttribute.groupUserAttributeType;

  user[slug] = {
    value: user[slug]?.value ? user[slug].value : null,
    isRequired: groupUserAttributeSpec.required ? true : false,
    isValid: true,
    errorMessage: null
  };

  if (user[slug].value) {
    switch (groupUserAttributeType.type) {
      case 'email':
        user[slug].isValid =
          isEmail(user[slug].value.toString()) &&
          validateEmailDomain(user[slug].value, allowedEmailDomains)
            ? true
            : false;
        user[slug].errorMessage = user[slug].isValid
          ? null
          : 'Enter Valid Email';
        break;
      case 'telephone':
        user[slug].isValid = isContact(user[slug].value.toString())
          ? true
          : false;
        user[slug].errorMessage = user[slug].isValid
          ? null
          : 'Telephone must include +(country code) and it should be 9-13 Digits.';
        break;
      case 'link':
        user[slug].isValid = isLink(user[slug].value.toString()) ? true : false;
        user[slug].errorMessage = user[slug].isValid ? null : 'Invalid link';
        break;
      default:
        user[slug].isValid = true;
    }
  } else {
    if (groupUserAttributeSpec.required) {
      if (groupUserAttributeType.type === 'text area') {
        user[slug].isValid =
          getHtmlContent((user[slug].value as string) || '').trim().length > 0;
        user[slug].errorMessage = !user[slug].isValid
          ? 'This Field is Required'
          : null;
      }
      user[slug].isValid = false;
      user[slug].errorMessage = user[slug].isValid
        ? null
        : 'This Field is Required';
    } else {
      user[slug].isValid = true;
    }
  }
  return user[slug];
};

const isStringValue = (value: any): value is string => {
  return typeof value === 'string';
};

export const isValidGroupUserAttributeValue = (
  groupUserAttributeSpec: MyjbiGroupUserAttributeSpec,
  userAttrValue: string | Date | null | number,
  allowedEmailDomains?: string[]
): UserAttributeValueValidationResult => {
  allowedEmailDomains = allowedEmailDomains ? allowedEmailDomains : [];
  const { groupUserAttributeType } = groupUserAttributeSpec.groupUserAttribute;
  let isValid = true;
  let errorMessage = null;
  if (isStringValue(userAttrValue) && userAttrValue.trim().length === 0) {
    userAttrValue = null;
  }
  if (userAttrValue) {
    switch (groupUserAttributeType.type) {
      // switch case to check value format
      case 'email':
        isValid =
          // Only checks email domain restriction when the attribute is default
          // and type "email"
          isEmail(userAttrValue.toString()) &&
          (groupUserAttributeSpec.isDefault
            ? validateEmailDomain(userAttrValue.toString(), allowedEmailDomains)
            : true);
        errorMessage = !isValid ? 'Invalid Email' : null;
        break;
      case 'telephone':
        isValid = isContact(userAttrValue.toString()) ? true : false;
        errorMessage = !isValid
          ? 'Telephone must include +(country code) and it should be 9-13 Digits.'
          : null;
        break;
      case 'link':
        isValid = isLink(userAttrValue.toString()) ? true : false;
        errorMessage = !isValid ? 'Invalid link' : null;
        break;
      case 'text area':
        /**
         * Quill editor output preserves the empty tags (ie <p>, <h1>) as string.
         * Additional checking to get the content is needed to make sure
         * the content is actually "empty".
         *
         * This check only needs to be ran when attribute is required.
         */
        if (groupUserAttributeSpec.required) {
          isValid =
            getHtmlContent((userAttrValue as string) || '').trim().length > 0;
          errorMessage = !isValid ? 'This Field is Required' : null;
          break;
        }
        isValid = true;
        errorMessage = null;
        break;
      case 'list':
        // TODO: add proper validation that checks against the latest option values.
        isValid = true;
        errorMessage = null;
        break;
      default:
        isValid = true;
        errorMessage = null;
    }
  } else {
    if (groupUserAttributeSpec.required) {
      isValid = false;
      errorMessage = !isValid ? 'This Field is Required' : null;
    } else {
      isValid = true;
    }
  }

  return { isValid, errorMessage };
};
