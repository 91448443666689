


































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import BasePaginatorHoc from '../../../../components/base/BasePaginatorHoc.vue';
import { MyjbiGroupUserAttributeSpec } from '../../../../jbi-shared/types/myjbi-group.types';
import { clone } from '../../../../jbi-shared/util/group-level-attributes.util';
import ExistingUserAttributesModal from '@/views/components/PaginatedUserAttributes/ExistingUserAttributesModal.vue';
import CreateNewAttribute from './CreateNewAttribute.vue';
import { UserAttributesStringInputOption } from '@/store/modules/admin/types/group.types';
import AttributesList from '@/views/components/PaginatedUserAttributes/AttributesList.vue';
import {
  SortOrder,
  SortEventData,
  PaginatorSpecs
} from '@/store/types/general.types';
import { BaseUserAttributeId } from '@/store/modules/profile/profile.state';
import {
  PermissionsMatrixActionsEnum,
  ResourceExceptions
} from '../../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '../../../../utils/rbac.util';
import { EntityTypes } from '../../../../store/modules/module-tree/enums/module-tree.enums';
import { State } from 'vuex-class';
import { RootState } from '@/store/store';

/**
 * This component only serve to provide an "overview" of selected user attributes.
 *
 * At the time of writing, this component used in 3 group pages:
 * 1. Create master group page
 * 2. Create subgroup page
 * 3. Group settings page
 *
 * Business logic:
 *
 * In each of these pages, the list of attributes available for selection
 * is supplied by parent/consumer.
 *
 * Expected values of this list in different pages:
 * 1. Create master group:
 *  - [mandatory] 3 base attributes
 *
 * 2. Create subgroup:
 *  - [mandatory] 3 base attributes
 *  - [mandatory, if any] all of its parent attributes
 * (subgroups inherit parent attributes during creation)
 *
 * 3. Group settings page (master):
 *  - [mandatory] 3 base attributes
 *  - [optional, if any] attributes added AFTER group creation
 *
 * 4. Group settings page (subgroup):
 *  - [mandatory] 3 base attributes
 *  - [mandatory, if any] other attributes inherited from its parent during group creation
 *  - [optional, if any] attributes added AFTER group creation
 *
 * The specs of the mandatory attributes listed in EACH SCENARIO should NOT be changed.
 * Hence function to disable a row exists. @see disableRowFn
 *
 * Note:
 * Most of the functions in this file
 * are mirrored from ExistingUserAttributesModal.vue
 *
 * To avoid bloating this file, @see ExistingUserAttributesModal for
 * in-depth explanations.
 */
@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {
    BasePaginatorHoc,
    CreateNewAttribute,
    AttributesList
  }
})
export default class ManageUserAttribute extends Vue {
  @Prop() selectedAttributes!: MyjbiGroupUserAttributeSpec[];
  @Prop(String) name!: string;
  @Prop(Object) type!: UserAttributesStringInputOption;
  @Prop(String) subGroupType!: string;
  @Prop(Array) parentAttributeIds!: number[];
  @Prop() groupTypeName!: string;
  @Prop(Number) groupId!: number;
  @Prop() groupExceptions!: ResourceExceptions;

  @State((state: RootState) => state.rbac.groupTypesUserHasAccessTo)
  public groupTypesUserHasAccessTo!: string[];

  perPage: number = 50;
  page: number = 1;
  sortColumn: string = 'attributeLabel';
  sortOrder: SortOrder = SortOrder.ASC;

  // local copy of selected attributes for manipulation
  clonedSelection: MyjbiGroupUserAttributeSpec[] = [];

  /**
   * Unlike ExistingUserAttributesModal,
   * the list of attribute specs are supplied by parent/consumer.
   */
  availableSelection: MyjbiGroupUserAttributeSpec[] = [];

  /**
   * FIXME: every time some one uses "render/updateKey",
   * a panda falls out of a tree and dies.
   *
   * Love panda, stop using them.
   */
  newlyCreatedAttribute: MyjbiGroupUserAttributeSpec[] = [];

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string | string[],
    skipImplicitCheck?: boolean
  ): boolean {
    const instance = EntityTypes.GROUP + '_' + this.groupId;
    const groupTypeName = this.groupTypeName
      ? this.groupTypeName
      : this.groupTypesUserHasAccessTo[0];
    return isUserAllowed(
      action,
      module,
      EntityTypes.GROUP,
      groupTypeName,
      this.groupId,
      this.groupExceptions,
      skipImplicitCheck
    );
  }

  get isManageUserAttributes(): boolean {
    return (
      this.isUserAllowed(PermissionsMatrixActionsEnum.CREATE, [
        'group_administration-groups-update_groups-update_user_attributes-create_existing_user_attributes',
        'group_administration-groups-update_groups-update_user_attributes-create_new_user_attributes'
      ]) ||
      this.isUserAllowed(
        PermissionsMatrixActionsEnum.DELETE,
        'group_administration-groups-update_groups-update_user_attributes-delete_added_user_attributes'
      )
    );
  }

  mounted() {
    this.clonedSelection = clone(this.selectedAttributes);
    this.availableSelection = clone(this.selectedAttributes);
  }

  get AttributesList() {
    return AttributesList;
  }

  get filteredUserAttributes(): MyjbiGroupUserAttributeSpec[] {
    if (this.availableSelection === undefined) {
      return [];
    }

    const startItemIndex = this.perPage * (this.page - 1);
    const endItemIndex = startItemIndex + this.perPage;
    return this.availableSelection.slice(startItemIndex, endItemIndex);
  }

  get groupType(): string {
    if (this.subGroupType) {
      return this.subGroupType;
    }

    if (this.type) {
      return this.type.name;
    }

    return '';
  }

  get totalCount(): number {
    return this.availableSelection.length;
  }

  /**
   * Criteria to disable rows:
   *
   * 1. When attribute is base attribute
   * 2. When attribute is inherited from parent group
   */
  disableRowFn({ groupUserAttribute }: MyjbiGroupUserAttributeSpec): boolean {
    if (this.isBaseAttribute(groupUserAttribute) === true) {
      return true;
    }

    if (this.parentAttributeIds.includes(groupUserAttribute.id)) {
      return true;
    }

    return false;
  }

  isBaseAttribute(
    groupUserAttribute: MyjbiGroupUserAttributeSpec['groupUserAttribute']
  ): boolean {
    return Object.values(BaseUserAttributeId).includes(groupUserAttribute.id);
  }

  selectAllInCurrentPage() {
    const selectedAttrIds = this.clonedSelection.map(
      (spec) => spec.groupUserAttribute.id
    );
    const attrsToAdd: MyjbiGroupUserAttributeSpec[] = [];

    this.filteredUserAttributes
      .filter((currPageSpec) => {
        const isSelectable = this.disableRowFn(currPageSpec) === false;
        return isSelectable;
      })
      .forEach((spec) => {
        if (selectedAttrIds.includes(spec.groupUserAttribute.id) === false) {
          attrsToAdd.push(spec);
        }
      });

    this.clonedSelection = [...this.clonedSelection, ...attrsToAdd];
  }

  removeAllInCurrentPage() {
    const currPageAttrIds = this.filteredUserAttributes.map(
      (spec) => spec.groupUserAttribute.id
    );

    const attrsToRetain = this.clonedSelection.filter((spec) => {
      const attrInOtherPage =
        currPageAttrIds.includes(spec.groupUserAttribute.id) === false;
      const shouldRetain = this.disableRowFn(spec) === true;

      if (attrInOtherPage || shouldRetain) {
        return true;
      }
    });

    this.clonedSelection = clone(attrsToRetain);
  }

  addAttribute(item: MyjbiGroupUserAttributeSpec) {
    this.updateRowSpecVisual(item); // workaround
    this.clonedSelection.push(item);
  }

  removeAttribute(item: MyjbiGroupUserAttributeSpec) {
    this.updateRowSpecVisual(item); // workaround
    this.clonedSelection.forEach((spec, index, arr) => {
      if (item.groupUserAttribute.id === spec.groupUserAttribute.id) {
        arr.splice(index, 1);
      }
    });
  }

  /**
   * Unlike its mirrored counter part in ExistingUserAttributesModal,
   * this function needs to be invoked when adding/removing selected items.
   *
   * Without this, both attribute spec lists don't update as expected.
   */
  updateRowSpecVisual(updatedSpec: MyjbiGroupUserAttributeSpec) {
    // Update 1: update available spec
    const copyOfAllAttributes = clone(this.availableSelection);
    const index = copyOfAllAttributes.findIndex(
      (spec) => spec.groupUserAttribute.id === updatedSpec.groupUserAttribute.id
    );

    if (index > -1) {
      copyOfAllAttributes[index] = updatedSpec;
      this.availableSelection = copyOfAllAttributes;
    }

    // Update 2: update selection
    const copyOfSelection = clone(this.clonedSelection);
    const indexInSelection = copyOfSelection.findIndex(
      (spec) => spec.groupUserAttribute.id === updatedSpec.groupUserAttribute.id
    );

    if (indexInSelection > -1) {
      copyOfSelection[indexInSelection] = updatedSpec;
      this.clonedSelection = copyOfSelection;
    }
  }

  openAddUserAttributesModal(): void {
    this.$buefy.modal.open({
      parent: this,
      component: ExistingUserAttributesModal,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: true,
      props: {
        hideBaseAttributes: false, // i know what i'm doing. do YOU?
        selectedAttributes: this.clonedSelection,
        disableRow: this.disableRowFn,
        groupTypeName: this.groupTypeName,
        groupId: this.groupId
      },
      events: {
        updateSelection: (data: MyjbiGroupUserAttributeSpec[]) => {
          this.clonedSelection = data;
          this.availableSelection = data;
        }
      }
    });
  }

  openCreateAttributeModal(): void {
    this.$buefy.modal.open({
      parent: this,
      component: CreateNewAttribute,
      hasModalCard: true,
      trapFocus: true,
      events: {
        addNewAttribute: (attribute: MyjbiGroupUserAttributeSpec) => {
          this.clonedSelection.push(attribute);
          this.availableSelection.push(attribute);
          this.newlyCreatedAttribute.push(attribute);
        }
      }
    });
  }

  updateAttributes(): void {
    this.$emit(
      'updateUserAttributes',
      this.clonedSelection,
      this.newlyCreatedAttribute
    );

    this.$emit('close');
  }

  shiftDefaultAttributeToFront(
    attributes: MyjbiGroupUserAttributeSpec[]
  ): MyjbiGroupUserAttributeSpec[] {
    const baseAttributes = attributes.filter(
      (spec) => this.isBaseAttribute(spec.groupUserAttribute) === true
    );

    const otherAttributes = attributes.filter(
      (spec) => this.isBaseAttribute(spec.groupUserAttribute) === false
    );

    return [...baseAttributes, ...otherAttributes];
  }

  closeModal(): void {
    this.$emit('close');
  }

  /* Paginator staples */
  sortItems(sortEvent: SortEventData): void {
    this.sortColumn = sortEvent.sortColumn;
    this.sortOrder = sortEvent.sortOrder;

    this.availableSelection = this.shiftDefaultAttributeToFront(
      this.availableSelection.sort((a, b) => {
        const columnA = a.groupUserAttribute.name.toLowerCase();
        const columnB = b.groupUserAttribute.name.toLowerCase();

        if (this.sortOrder === SortOrder.ASC) {
          return columnA > columnB ? 1 : columnB > columnA ? -1 : 0;
        } else {
          return columnB > columnA ? 1 : columnA > columnB ? -1 : 0;
        }
      })
    );
  }

  paginateItems({ perPage, page }: PaginatorSpecs): void {
    this.perPage = perPage;
    this.page = page;
  }
}
