


















































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { RootState } from '../../../../store/store';
import { ApiState } from '@/store/types/general.types';
import {
  GroupLevelAttributeValue,
  GroupLevelAttributeOption,
  GroupLevelAttributeValueStatus,
  AddressCountry,
  AddressState,
  AddressFilterOption
} from '../../../../store/modules/admin/types/group-level-attribute.types';
import AttributeButtons from '../GroupLevelAttributes/AttributeButtons.vue';
import DraggableIcon from '../GroupLevelAttributes/DraggableIcon.vue';
import { ToastProgrammatic as Toast } from 'buefy';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { clone } from '@/jbi-shared/util/group-level-attributes.util';

@Component({
  components: {
    AttributeButtons,
    DraggableIcon
  }
})
export default class GroupLevelAttributeAddressField extends Vue {
  @Prop() attributeValue!: GroupLevelAttributeValue;
  @Prop() fieldClasses!: any;
  @Prop() duplicationError!: boolean;
  @Prop() updateAttribute!: (attribute: GroupLevelAttributeValue) => void;
  @Prop() deleteAttribute!: () => void;
  showField: boolean = false;
  labelErrorMsg: string = '';
  lineOneErrorMsg: string = '';
  cityErrorMsg: string = '';
  postcodeErrorMsg: string = '';
  stateErrorMsg: string = '';
  countryErrorMsg: string = '';
  currentCountry: AddressCountry | undefined = undefined;
  currentStates: AddressState[] = [];
  selectedCountryId: number = 0; // used to determine whether to store fetched states
  isStateless: boolean = true;

  // shorthand referenced for rendering only
  building = (this.attributeValue.value as GroupLevelAttributeOption[])[0];
  lineOne = (this.attributeValue.value as GroupLevelAttributeOption[])[1];
  lineTwo = (this.attributeValue.value as GroupLevelAttributeOption[])[2];
  city = (this.attributeValue.value as GroupLevelAttributeOption[])[3];
  postcode = (this.attributeValue.value as GroupLevelAttributeOption[])[4];
  state = (this.attributeValue.value as GroupLevelAttributeOption[])[5];
  country = (this.attributeValue.value as GroupLevelAttributeOption[])[6];

  @Action('admin/getCountries')
  getAllCountries!: (addressFilter?: AddressFilterOption) => void;

  @State(({ admin }: RootState) => admin.apiState.getCountries)
  getAllCountriesApiState!: ApiState;

  @State(({ admin }: RootState) => admin.countries)
  allCountries!: AddressCountry[] | undefined;

  @Action('admin/getCountryByCountryId')
  getCountryById!: (countryId?: number) => void;

  @State(({ admin }: RootState) => admin.apiState.getCountryById)
  getCountryByIdApiState!: ApiState;

  @State(({ admin }: RootState) => admin.countryById)
  countryById!: AddressCountry | undefined;

  mounted() {
    if (this.allCountries === undefined) {
      this.getAllCountries();
    }
    this.validateLabel();
    this.validateRequiredFieldValues();
  }

  get countriesList(): AddressCountry[] {
    if (this.allCountries) {
      return this.allCountries.filter((country) => {
        return country.name
          .toLowerCase()
          .includes((this.country.value as string).toLowerCase());
      });
    }

    return [];
  }

  get statesList(): AddressState[] {
    if (this.currentStates.length) {
      return this.currentStates.filter((state) => {
        return state.name
          .toLowerCase()
          .includes((this.state.value as string).toLowerCase());
      });
    }

    return [];
  }

  /**
   * DO NOT convert this method into a getter.
   */
  fieldErrorMessages(): string[] {
    return [
      this.labelErrorMsg,
      this.lineOneErrorMsg,
      this.cityErrorMsg,
      this.postcodeErrorMsg,
      this.stateErrorMsg,
      this.countryErrorMsg
    ];
  }

  /**
   * Validates attribute label content.
   * Displays label field if any error present.
   */
  validateLabel(): void {
    if (this.attributeValue.label === '') {
      this.labelErrorMsg = 'Label cannot be empty.';
      this.showField = true;
    } else if (this.duplicationError) {
      this.labelErrorMsg = `Duplicated label '${this.attributeValue.label}'.`;
      this.showField = true;
    } else {
      this.labelErrorMsg = '';
    }
  }

  /**
   * Check for country name when user types manually.
   * Assign corrected value (string case) if input matched
   */
  verifyCountryInput(): void {
    if (!this.allCountries) {
      this.countryErrorMsg = '';
      return;
    }

    if (this.country.value) {
      const targetCountry: AddressCountry | undefined = this.allCountries.find(
        (country) =>
          country.name.toLowerCase() ===
          (this.country.value as string).toLowerCase()
      );

      if (!targetCountry) {
        this.countryErrorMsg = 'Invalid country name.';
        return;
      }

      if (targetCountry) {
        this.country.value = targetCountry.name;
        this.selectedCountryId = targetCountry.id;
        this.getCountryById(targetCountry.id);
      }
    } else {
      if (this.attributeValue.isRequired) {
        this.countryErrorMsg = 'Country cannot be empty.';
        return;
      }
    }

    this.countryErrorMsg = '';
    return;
  }

  /**
   * Verifies manual input and update case to exact match
   */
  verifyStateInput(): void {
    if (this.isStateless) {
      this.stateErrorMsg = '';
      return;
    }

    // verify state value
    if (this.state.value) {
      const targetState: AddressState | undefined = this.statesList.find(
        (state) =>
          state.name.toLowerCase() ===
          (this.state.value as string).toLowerCase()
      );

      if (!targetState) {
        this.stateErrorMsg = 'Invalid state name.';
        return;
      }

      if (targetState) {
        this.state.value = targetState.name;
        this.stateErrorMsg = '';
      }
    } else {
      // state value can only be left blank when country doesn't have state
      if (this.attributeValue.isRequired && !this.isStateless) {
        this.stateErrorMsg = 'State cannot be empty.';
        return;
      }
    }

    this.stateErrorMsg = '';

    return;
  }

  verifyLineOneInput(lineOneInput?: string): void {
    lineOneInput = lineOneInput || (this.lineOne.value as string);

    if (this.attributeValue.isRequired && !lineOneInput) {
      this.lineOneErrorMsg = 'Line one cannot be empty.';
    } else {
      this.lineOneErrorMsg = '';
    }
  }

  verifyCityInput(cityInput?: string): void {
    cityInput = cityInput || (this.city.value as string);

    if (this.attributeValue.isRequired && !cityInput) {
      this.cityErrorMsg = 'City cannot be empty.';
    } else {
      this.cityErrorMsg = '';
    }
  }

  verifyPostcodeInput(postcodeInput?: string): void {
    postcodeInput = postcodeInput || (this.postcode.value as string);

    if (this.attributeValue.isRequired && !postcodeInput) {
      this.postcodeErrorMsg = 'Postcode cannot be empty.';
    } else {
      this.postcodeErrorMsg = '';
    }
  }

  updateErrorState(): boolean {
    this.attributeValue.hasFieldError =
      this.fieldErrorMessages().join('').length > 0;
    return this.attributeValue.hasFieldError;
  }

  assignCountryValue(selectedCountry: AddressCountry | null): void {
    this.country.value = selectedCountry ? selectedCountry.name : '';
    this.state.value = '';

    if (selectedCountry) {
      this.selectedCountryId = selectedCountry.id;
      this.getCountryById(selectedCountry.id);
    }
  }

  assignStateValue(selectedState: AddressState | null): void {
    this.state.value = selectedState ? selectedState.name : '';
  }

  hideLabelAndValidate(event: any): void {
    this.validateLabel();
    this.validateRequiredFieldValues();

    // hide field on enter, esc, or blur + no error
    if (event.keyCode === 13 || event.keyCode === 27 || event.type === 'blur') {
      if (this.labelErrorMsg.length === 0) {
        this.showField = false;
      }
    }
  }

  validateRequiredFieldValues(): void {
    this.verifyLineOneInput();
    this.verifyCityInput();
    this.verifyPostcodeInput();
    this.verifyStateInput();
    this.verifyCountryInput();
  }

  @isTruthy
  @Watch('getAllCountriesApiState.error')
  @Watch('getCountryByIdApiState.error')
  addressApiErrorCallback(): void {
    Toast.open('Error fetching countries and states.');
  }

  @Watch('getAllCountriesApiState.success')
  validateCountryCallback(): void {
    this.verifyCountryInput();

    // clear state value when there's error with country name
    if (this.countryErrorMsg) {
      this.state.value = '';
    }
  }

  @isTruthy
  @Watch('getCountryByIdApiState.success')
  validateStateCallback(): void {
    if (
      this.countryById &&
      (this.selectedCountryId === 0 ||
        this.selectedCountryId === this.countryById.id)
    ) {
      this.currentCountry = clone(this.countryById) as AddressCountry;
      this.currentStates = clone(this.countryById.states) as AddressState[];
      this.isStateless = this.currentStates.length === 0;
    }
  }

  /**
   * Add a separate watcher for "hasDuplicationError" property
   * because this property is updated by parent.
   */
  @Watch('duplicationError')
  duplicationCallback(currentError: boolean, previousError: boolean): void {
    this.validateLabel();
  }

  @Watch('attributeValue.label')
  @Watch('lineOne.value')
  @Watch('lineTwo.value')
  @Watch('city.value')
  @Watch('postcode.value')
  @Watch('country.value')
  @Watch('state.value')
  validateAndUpdate(): void {
    this.updateAttribute(this.attributeValue);
  }

  @Watch('labelErrorMsg')
  @Watch('lineOneErrorMsg')
  @Watch('cityErrorMsg')
  @Watch('postcodeErrorMsg')
  @Watch('stateErrorMsg')
  @Watch('countryErrorMsg')
  checkErrorMessageCallback(): void {
    this.updateErrorState();
    this.updateAttribute(this.attributeValue);
  }
}
