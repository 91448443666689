











































































































































import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { RootState } from '@/store/store';
import {
  GroupLevelAttributeType,
  GroupLevelAttributeValue,
  GroupLevelAttributeValueStatus
} from '@/store/modules/admin/types/group-level-attribute.types';
import draggable from 'vuedraggable';
import AddGroupLevelAttributeModal from './GroupLevelAttributes/AddGroupLevelAttributeModal.vue';
import { ApiState } from '@/store/types/general.types';
import GroupLevelAttributeTextField from './GroupLevelAttributes/GroupLevelAttributeTextField.vue';
import GroupLevelAttributeTextArea from './GroupLevelAttributes/GroupLevelAttributeTextArea.vue';
import GroupLevelAttributeEmailField from './GroupLevelAttributes/GroupLevelAttributeEmailField.vue';
import GroupLevelAttributeNumberField from './GroupLevelAttributes/GroupLevelAttributeNumberField.vue';
import GroupLevelAttributeListField from './GroupLevelAttributes/GroupLevelAttributeListField.vue';
import GroupLevelAttributeBooleanField from './GroupLevelAttributes/GroupLevelAttributeBooleanField.vue';
import GroupLevelAttributeAddressField from './GroupLevelAttributes/GroupLevelAttributeAddressField.vue';
import GroupLevelAttributePhoneField from './GroupLevelAttributes/GroupLevelAttributePhoneField.vue';
import GroupLevelAttributeCoordinatesField from './GroupLevelAttributes/GroupLevelAttributeCoordinatesField.vue';
import GroupLevelAttributeUploadable from './GroupLevelAttributes/GroupLevelAttributeUploadable.vue';
import GroupLevelAttributeLinkField from './GroupLevelAttributes/GroupLevelAttributeLinkField.vue';
import GroupLevelAttributeDateField from './GroupLevelAttributes/GroupLevelAttributeDateField.vue';
import FieldWrapper from './GroupLevelAttributes/FieldWrapper.vue';
import TitleAttributeField from './GroupLevelAttributes/DefaultAttributes/TitleAttributeField.vue';
import LogoAttributeField from './GroupLevelAttributes/DefaultAttributes/LogoAttributeField.vue';
import {
  clone,
  isDefault,
  isLogo,
  isTitle
} from '@/jbi-shared/util/group-level-attributes.util';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import GroupTemplateModal from './GroupLevelAttributes/GroupTemplateModal.vue';
import delay from 'delay';
import { ToastProgrammatic as Toast } from 'buefy';
import { isUserAllowed } from '@/utils/rbac.util';
import {
  PermissionsMatrixActionsEnum,
  ResourceExceptions
} from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { EntityTypes } from '@/store/modules/module-tree/enums/module-tree.enums';

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {
    draggable,
    AddGroupLevelAttributeModal,
    GroupTemplateModal,
    FieldWrapper,
    TitleAttributeField,
    LogoAttributeField
  }
})
export default class AdminGroupLevelAttribute extends Vue {
  @PropSync('groupName') syncedGroupName!: string;
  @Prop() groupId!: number;
  @Prop() isSettings!: boolean;
  @Prop() isGroupLevelAttributeTabOpened!: boolean;
  @Prop() groupTypeName!: string;
  @Prop() groupExceptions!: ResourceExceptions;

  availableAttributeTypes: GroupLevelAttributeType[] = [];
  selectedGroupAttributeValues: GroupLevelAttributeValue[] = [];
  activeGroupAttributes: GroupLevelAttributeValue[] = [];
  deleteGroupAttributes: GroupLevelAttributeValue[] = []; /* Values in this array will be processed for soft deletion */
  defaultGroupLevelAttributes: GroupLevelAttributeValue[] = [];
  hasValueFetchedBefore: boolean = false;

  textType: GroupLevelAttributeType | undefined = undefined;
  imageType: GroupLevelAttributeType | undefined = undefined;

  duplicationError: boolean = false;
  saveAsTemplate: boolean = false;
  templateName: string = '';
  templateExistsInfo: string = '';

  @Action('admin/getGroupLevelAttributeTypes')
  getGroupLevelAttributeTypes!: () => void;

  @Action('admin/verifyGroupTemplate')
  public verifyGroupTemplate!: (name: string) => void;

  @Action('admin/getGroupLevelAttributeValuesByGroupId')
  getGroupLevelAttributeValuesByGroupId!: (groupId: number) => void;

  @State(({ admin }: RootState) => admin.verifyGroupTemplate)
  public verifyGroupTemplateResponse!: boolean;

  @State(({ admin }: RootState) => admin.apiState.verifyGroupTemplate.success)
  public verifyGroupTemplateSuccess!: boolean;

  @State(({ admin }: RootState) => admin.apiState.getGroupLevelAttributeTypes)
  public getGroupLevelAttributeTypesState!: ApiState;

  @State(({ admin }: RootState) => admin.groupLevelAttributeTypes)
  public allAttributeTypes!: GroupLevelAttributeType[] | undefined;

  @State(
    ({ admin }: RootState) =>
      admin.apiState.getGroupLevelAttributeValuesByGroupId
  )
  public getGroupLevelAttributeValuesByGroupIdState!: ApiState;

  @State(({ admin }: RootState) => admin.groupLevelAttributeValuesByGroupId)
  public currentGroupAttributeValues!: GroupLevelAttributeValue[] | undefined;

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string,
    skipImplicitCheck?: boolean
  ): boolean {
    const instance = EntityTypes.GROUP + '_' + this.groupId;
    return this.isSettings
      ? isUserAllowed(
          action,
          module,
          EntityTypes.GROUP,
          this.groupTypeName,
          this.groupId,
          this.groupExceptions,
          skipImplicitCheck
        )
      : true;
  }

  mounted() {
    /* Attribute types are fetched on component mount
     * because text and image types are needed to create default attributes
     * (ie Title, and Logo) */
    this.getGroupLevelAttributeTypes();
  }

  getTitleAttributeFieldComponent() {
    return TitleAttributeField;
  }

  getLogoAttributeFieldComponent() {
    return LogoAttributeField;
  }

  /*
   * Return field component for dynamic rendering of active attributes.
   */
  getFieldComponent(attrVal: GroupLevelAttributeValue) {
    switch (attrVal.groupLevelAttributeType.type) {
      case 'link':
        return GroupLevelAttributeLinkField;
      case 'text area':
        return GroupLevelAttributeTextArea;
      case 'email':
        return GroupLevelAttributeEmailField;
      case 'address':
        return GroupLevelAttributeAddressField;
      case 'integer':
        return GroupLevelAttributeNumberField;
      case 'boolean':
        return GroupLevelAttributeBooleanField;
      case 'list':
        return GroupLevelAttributeListField;
      case 'images':
      case 'documents':
        return GroupLevelAttributeUploadable;
      case 'coordinates':
        return GroupLevelAttributeCoordinatesField;
      case 'phone':
        return GroupLevelAttributePhoneField;
      case 'date':
        return GroupLevelAttributeDateField;
      default:
        return GroupLevelAttributeTextField;
    }
  }

  get newCanCreateTemplate(): boolean {
    return this.activeGroupAttributes.length > 0;
  }

  /*
   * Attribute to update could be in active or default array.
   * Locate attribute before emitting to parent.
   */
  handleUpdateAttribute(updatedAttribute: GroupLevelAttributeValue) {
    const targetArray = updatedAttribute.isDefault
      ? this.defaultGroupLevelAttributes
      : this.activeGroupAttributes;

    const attributeIndex: number = targetArray.findIndex(
      (attribute) => attribute.order === updatedAttribute.order
    );

    if (attributeIndex !== -1) {
      targetArray[attributeIndex] = updatedAttribute;
    }

    this.validateLabelAndTypeDuplication();
    this.updateParentValue();
  }

  /*
   * Update attribute value for deletion
   * and move attribute to delete array.
   */
  handleDeleteAttribute(attributeIndex: number) {
    // prettier-ignore
    const attributeToDelete: GroupLevelAttributeValue = this.activeGroupAttributes[attributeIndex];
    this.assignDeleteValue(attributeToDelete);
    this.deleteGroupAttributes.push(attributeToDelete);
    this.activeGroupAttributes.splice(attributeIndex, 1);
    this.validateLabelAndTypeDuplication();
    this.updateOrderingAndParentValue();
  }

  openAddGroupLevelAttributeModal() {
    this.$buefy.modal.open({
      parent: this,
      component: AddGroupLevelAttributeModal,
      hasModalCard: true, // added to prevent breaking on mobile
      trapFocus: true,
      fullScreen: false,
      canCancel: true,
      props: {
        allAttributeTypes: this.availableAttributeTypes, // clone types to avoid directly mutating state
        groupId: this.groupId,
        attributes: [
          ...this.defaultGroupLevelAttributes,
          ...this.activeGroupAttributes
        ]
      },
      events: {
        addNewAttribute: (attributeValue: GroupLevelAttributeValue) => {
          this.activeGroupAttributes.push(attributeValue);
          this.updateOrderingAndParentValue();

          Toast.open({
            queue: true,
            type: 'is-dark',
            position: 'is-top',
            message: `New attribute added`
          });
        }
      }
    });
  }

  async handleOpenGroupTemplate() {
    if (this.activeGroupAttributes.length) {
      const dialogElem: Vue = this.$buefy.dialog.confirm({
        message: `<p class="buefy-dialog-title">Use Existing Template?</p><p class="buefy-dialog-content">You are trying to use existing template. This action will remove all the current attributes and add new attributes to the group</p>`,
        // confirm and cancel inverted for button styling purposes
        confirmText: 'Cancel',
        onConfirm: () => undefined,
        canCancel: ['button'],
        cancelText: 'Continue',
        onCancel: () => this.openGroupTemplateModal()
      });

      while (!dialogElem.$el?.querySelector) {
        await delay(50);
      }
      const cancelBtn = dialogElem.$el?.querySelector?.(
        `.modal-card > footer > button:first-child`
      );
      cancelBtn?.classList?.add?.(`is-red`);
    } else {
      this.openGroupTemplateModal();
    }
  }

  openGroupTemplateModal() {
    this.$buefy.modal.open({
      parent: this,
      component: GroupTemplateModal,
      hasModalCard: true, // added to prevent breaking on mobile
      trapFocus: true,
      fullScreen: true,
      props: {
        activeGroupAttributeValues: [...this.activeGroupAttributes]
      },
      events: {
        addGroupTemplateAttributes: this.clearAndAddAttributes,
        updateTemplateName: (templateName: string) => {
          Toast.open({
            queue: true,
            type: 'is-dark',
            position: 'is-top',
            message: `${templateName} added`
          });
        }
      }
    });
  }

  /* Method to replace active attributes with group template attributes */
  clearAndAddAttributes(groupTemplateAttributes: GroupLevelAttributeValue[]) {
    groupTemplateAttributes = clone(groupTemplateAttributes);
    this.clearActiveAttributes();

    groupTemplateAttributes.forEach((attribute) => {
      this.activeGroupAttributes.push(attribute);
    });
    this.updateOrderingAndParentValue();
  }

  /* Moves all active attributes to deletion array */
  clearActiveAttributes() {
    const clonedToDelete: GroupLevelAttributeValue[] = clone(
      this.activeGroupAttributes
    );
    clonedToDelete.forEach((attributeToDelete) => {
      this.assignDeleteValue(attributeToDelete);
    });

    /* Separate operation to avoid splicing in for loops */
    this.activeGroupAttributes.length = 0;
    this.deleteGroupAttributes.push(...clonedToDelete);
    this.updateOrderingAndParentValue();
  }

  /* Set attribute value for deletion */
  assignDeleteValue(attributeValue: GroupLevelAttributeValue) {
    attributeValue.order = 0;
    attributeValue.status = GroupLevelAttributeValueStatus.DELETE;
    attributeValue.hasFieldError = false;
    attributeValue.hasDuplicationError = false;
  }

  /*
   * Update ordering of active attributes, then emit value.
   * Ordering should increment from default attributes.
   */
  updateOrderingAndParentValue() {
    this.activeGroupAttributes.forEach((attribute, index) => {
      attribute.order = index + 1 + this.defaultGroupLevelAttributes.length;
    });

    this.updateParentValue();
  }

  /*
   * This method checks for the three possible errors in each field.
   * (ie label error, value error, duplication error)
   *
   * Note: DO NOT convert this method into a getter.
   * Getter gives inaccurate result.
   */
  getGroupLevelAttributeError(): boolean {
    const allAttributes: GroupLevelAttributeValue[] = [
      ...this.defaultGroupLevelAttributes,
      ...this.activeGroupAttributes
    ];

    if (allAttributes.length) {
      const labelError: boolean = allAttributes.some(
        (attrVal) => attrVal.label === ''
      );

      const valueError: boolean = allAttributes.some(
        (attrVal) => attrVal.hasFieldError
      );

      const duplicationError: boolean = allAttributes.some(
        (attrVal) => attrVal.hasDuplicationError
      );

      if (!labelError && !valueError && !duplicationError) {
        return false;
      }
      return labelError || valueError || duplicationError;
    }
    return false;
  }

  updateParentValue() {
    this.$emit(
      'updateValuesAndError',
      [...this.defaultGroupLevelAttributes, ...this.activeGroupAttributes],
      this.deleteGroupAttributes,
      this.getGroupLevelAttributeError()
    );
  }

  createTitleAttribute(): GroupLevelAttributeValue {
    return {
      order: 1,
      label: 'Title',
      value: null /* value will be bound to groupname on field load */,
      isRequired: true,
      status: GroupLevelAttributeValueStatus.ACTIVE,
      groupLevelAttributeType: this.textType as GroupLevelAttributeType,
      hasFieldError: true,
      isTitle: true,
      isDefault: true
    };
  }

  createLogoAttribute(): GroupLevelAttributeValue {
    return {
      order: 2,
      label: 'Logo',
      value: null /* value will be updated once uploading is complete */,
      isRequired: false,
      status: GroupLevelAttributeValueStatus.ACTIVE,
      groupLevelAttributeType: this.imageType as GroupLevelAttributeType,
      hasFieldError: false,
      isTitle: false,
      isDefault: true
    };
  }

  /*
   * This method checks for duplication in active attributes.
   * Active attributes cannot have the same type with the same label name.
   *
   * Duplication check is done here, because on field level,
   * attributes do not have direct access to sibling component data.
   *
   * Check is done on array that combines both default and active (selected) attributes.
   * Validated values are then reassigned to respective elements in the array.
   */
  validateLabelAndTypeDuplication() {
    const allGroupAttributes = [
      ...this.defaultGroupLevelAttributes,
      ...this.activeGroupAttributes
    ];
    const dataMap = new Map();
    const validatedAttributes: GroupLevelAttributeValue[] = [];

    allGroupAttributes.forEach(
      (attribute: GroupLevelAttributeValue, index: number) => {
        /* Create a string consists of label and type as unique key for identification */
        const uniqueKey: string =
          attribute.groupLevelAttributeType.type +
          attribute.label.trim().toLowerCase();

        if (dataMap.has(uniqueKey)) {
          /*
           * If the key exists, meaning that there's already another attribute
           * with the same label name and type in the array.
           * Updates duplication error.
           */
          dataMap.get(uniqueKey).hasDuplicationError = true;
          attribute.hasDuplicationError = true;
        } else {
          /*
           * If the key doesn't exist, set map value to point to the attribute,
           * with duplication error to false.
           */
          dataMap.set(uniqueKey, attribute);
          attribute.hasDuplicationError = false;
        }

        validatedAttributes[index] = attribute;
      }
    );

    /*
     * Reassign updated values after validation is completed.
     *
     * This step is used to pass the validated value down to field level.
     * So on field level, appropriate error message can be displayed to user.
     *
     * Note: Default attributes are in the beginning of the array.
     */
    this.defaultGroupLevelAttributes = validatedAttributes.slice(
      0,
      this.defaultGroupLevelAttributes.length
    );

    this.activeGroupAttributes = validatedAttributes.slice(
      this.defaultGroupLevelAttributes.length,
      validatedAttributes.length
    );
  }

  @isTruthy
  @Watch('isGroupLevelAttributeTabOpened')
  onTabChange(isAdminOnGroupLevelAttributeTab: boolean) {
    if (
      isAdminOnGroupLevelAttributeTab &&
      this.isSettings &&
      this.hasValueFetchedBefore === false
    ) {
      // fetch only when admin is on the right tab
      this.getGroupLevelAttributeValuesByGroupId(this.groupId);
    }
  }

  @Watch('getGroupLevelAttributeTypesState')
  onGetGroupLevelAttributeTypesStateSuccess(apiState: ApiState) {
    if (apiState.success) {
      this.availableAttributeTypes = clone(
        this.allAttributeTypes
      ) as GroupLevelAttributeType[];

      // prettier-ignore
      this.textType = (this.availableAttributeTypes as GroupLevelAttributeType[]).find(
        (attributeType) => attributeType.type === 'text',
      ) as GroupLevelAttributeType;

      // prettier-ignore
      this.imageType = (this.availableAttributeTypes as GroupLevelAttributeType[]).find(
        (attributeType) => attributeType.type === 'images',
      ) as GroupLevelAttributeType;

      /* Create and insert default attributes after fetch success.
       * This operation should happen in group creation page only */
      if (!this.isSettings) {
        const titleAttribute: GroupLevelAttributeValue = this.createTitleAttribute();
        const logoAttribute: GroupLevelAttributeValue = this.createLogoAttribute();
        this.defaultGroupLevelAttributes.push(titleAttribute, logoAttribute);
      }
    }
  }

  @isTruthy
  @Watch('getGroupLevelAttributeValuesByGroupIdState.success')
  onGetGroupLevelAttributeValuesByGroupIdStateSuccess() {
    this.selectedGroupAttributeValues = clone(
      this.currentGroupAttributeValues
    ) as GroupLevelAttributeValue[];

    /*
     * After fetch success, massage attribute data.
     * isTitle, isDefault are not stored in database.
     * They are only used for UI rendering only.
     *
     * At the moment, only "title" and "logo" are default attributes.
     */
    this.selectedGroupAttributeValues.forEach(
      (attrVal: GroupLevelAttributeValue) => {
        /* Value from db are by default without any error until validation runs. */
        attrVal.hasFieldError = false;
        attrVal.hasDuplicationError = false;
        attrVal.isTitle = isTitle(attrVal);
        attrVal.isDefault = isDefault(attrVal);

        /* add default attributes into a separate array */
        if (attrVal.isDefault) {
          this.defaultGroupLevelAttributes.push(attrVal);
        }
      }
    );

    /*
     * Below "if" block acts as a safety measure.
     * It makes sure title and logo are present in group settings page.
     */
    if (this.isSettings) {
      const currentGroupTitle:
        | GroupLevelAttributeValue
        | undefined = this.selectedGroupAttributeValues.find(
        (attribute: GroupLevelAttributeValue) => {
          return isTitle(attribute);
        }
      );

      const currentGroupLogo:
        | GroupLevelAttributeValue
        | undefined = this.selectedGroupAttributeValues.find(
        (attribute: GroupLevelAttributeValue) => {
          return isLogo(attribute);
        }
      );

      if (!currentGroupTitle) {
        const titleAttribute: GroupLevelAttributeValue = this.createTitleAttribute();
        this.defaultGroupLevelAttributes.push(titleAttribute);
      }

      if (!currentGroupLogo) {
        const logoAttribute: GroupLevelAttributeValue = this.createLogoAttribute();
        this.defaultGroupLevelAttributes.push(logoAttribute);
      }
    }

    /*
     * When all attribute data massaging is done,
     * push attributes into active array.
     */
    this.selectedGroupAttributeValues.forEach((attribute, index) => {
      if (!attribute.isDefault) {
        /**
         * Mark attribute as disabled if it's existing and user has no permission
         * to edit existing attributes.
         *
         * Newly added attributes can still be edited.
         */
        this.activeGroupAttributes.push({
          ...attribute,
          isDisabled:
            'id' in attribute &&
            !this.isUserAllowed(
              PermissionsMatrixActionsEnum.UPDATE,
              'group_administration-groups-update_groups-update_group_attributes-update_existing_attributes',
              true
            )
        });
      }
    });

    this.hasValueFetchedBefore = true;
  }

  @Watch('verifyGroupTemplateSuccess')
  @isTruthy
  public watchCheckGroupTemplateSuccess() {
    if (this.verifyGroupTemplateResponse) {
      this.templateExistsInfo =
        'Template already exists with the same name, label and attribute type.';
    } else {
      this.templateExistsInfo = '';
    }
  }

  @Watch('templateName')
  @Debounce(600)
  public onChange() {
    if (this.templateName) {
      this.verifyGroupTemplate(this.templateName);
      this.$emit('templateDetails', this.saveAsTemplate, this.templateName);
    }
  }
}
