import { GroupAttribute } from '../types/jaas-group-level-attributes.types';

/* Basic clone function
 * TODO: Add proper error throwing. */
export function clone<Type>(argv: Type): Type {
  if (argv && typeof argv !== 'function') {
    return JSON.parse(JSON.stringify(argv)) as Type;
  }
  return argv as Type;
}

/* Default group level attribute related functions.
 *
 * NOTE:
 * As of now, there are only two default group attributes:
 * title and logo. (applies to both group LEVEL attributes and group TEMPLATE attributes.)
 * Title and logo are currently the first attributes for each group.
 *
 * TODO: Consider creating table to store default attributes for better standardization. */
export function isTitle(attribute: GroupAttribute): boolean {
  if (
    attribute.groupLevelAttributeType.type === 'text' &&
    attribute.label.trim().toLowerCase() === 'title'
  ) {
    return true;
  }
  return false;
}

export function isLogo(attribute: GroupAttribute): boolean {
  if (
    attribute.groupLevelAttributeType.type === 'images' &&
    attribute.label.trim().toLowerCase() === 'logo'
  ) {
    return true;
  }
  return false;
}

export function isDefault(attribute: GroupAttribute): boolean {
  return isTitle(attribute) || isLogo(attribute);
}

/**
 * Simple debouncer for synchronous functions
 *
 * Done thru restarting a local setTimeout timer everytime the function is triggered.
 */
let debounceTimer: undefined | ReturnType<typeof setTimeout>;

export function debouncer(
  millisecond: number,
  callbackFn: (cbArgv?: any) => any,
  ...params: any
): any {
  clearTimeout(debounceTimer as ReturnType<typeof setTimeout>);
  debounceTimer = setTimeout(() => {
    if (params) {
      return callbackFn(...params);
    }
    return callbackFn();
  }, millisecond);
}

/**
 * This debouncer is focused on use-cases where multiple debouncers are required concurrently.
 * For example, a form may contain multiple fields and each field may require a debouncer.
 */
const componentLevelDebouncerTimers: {
  [key: string]: ReturnType<typeof setTimeout>;
} = {};

export function componentLevelDebouncer(
  millisecond: number,
  key: string | number,
  callbackFn: (cbArgv?: any) => any,
  ...params: any
): any {
  clearTimeout(
    componentLevelDebouncerTimers['' + key] as ReturnType<typeof setTimeout>,
  );
  componentLevelDebouncerTimers['' + key] = setTimeout(() => {
    if (params) {
      return callbackFn(...params);
    }
    return callbackFn();
  }, millisecond);
}

/**
 * Returns the content that is wrapped between html tags.
 */
export function getHtmlContent(strigifiedHtml: string): string {
  const htmlContentExp: RegExp = /<\/?[^>]+(>|$)/g;
  return strigifiedHtml.replace(htmlContentExp, ' ').trim();
}
