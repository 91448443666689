











































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  GroupLevelAttributeValue,
  GroupLevelAttributeListType
} from '../../../../store/modules/admin/types/group-level-attribute.types';
import AttributeButtons from './AttributeButtons.vue';
import DraggableIcon from '../GroupLevelAttributes/DraggableIcon.vue';
import { clone } from '../../../../jbi-shared/util/group-level-attributes.util';

@Component({
  components: {
    AttributeButtons,
    DraggableIcon
  }
})
export default class GroupLevelAttributeListField extends Vue {
  @Prop() attributeValue!: GroupLevelAttributeValue;
  @Prop() fieldClasses!: any;
  @Prop() duplicationError!: boolean;
  @Prop() deleteAttribute!: () => void;
  @Prop() updateAttribute!: (attribute: GroupLevelAttributeValue) => void;
  showField: boolean = false;
  labelErrorMsg: string = '';
  fieldErrorMsg: string = '';
  listItemContent: string = '';

  listOption: GroupLevelAttributeListType = clone(
    this.attributeValue.value
  ) as GroupLevelAttributeListType;

  mounted() {
    this.validateLabel();
    this.validateRequiredFieldValues();
  }

  get newItemExists(): boolean {
    return this.listOption.options
      .map((option) => option.trim().toLowerCase())
      .includes(this.listItemContent.trim().toLowerCase());
  }

  /**
   * Validates attribute label content.
   * Displays label field if any error present.
   */
  validateLabel(): void {
    if (this.attributeValue.label === '') {
      this.labelErrorMsg = 'Label cannot be empty.';
      this.attributeValue.hasFieldError = true;
      this.showField = true;
    } else if (this.duplicationError) {
      this.labelErrorMsg = `Duplicated label '${this.attributeValue.label}'.`;
      this.attributeValue.hasFieldError = true;
      this.showField = true;
    } else {
      this.attributeValue.hasFieldError = false;
      this.labelErrorMsg = '';
    }
  }

  hideLabelAndValidate(event: any): void {
    this.validateLabel();
    this.validateRequiredFieldValues();

    // hide field on enter, esc, or blur + no error
    if (event.keyCode === 13 || event.keyCode === 27 || event.type === 'blur') {
      if (this.labelErrorMsg.length === 0) {
        this.showField = false;
      }
    }
  }

  addItemToList() {
    if (this.listItemContent === '' || this.newItemExists) {
      return;
    }

    this.listOption.options.push(this.listItemContent);
    this.listItemContent = '';
  }

  removeItemFromList(valueIndex: number) {
    /* Extra step to check and update selected values.
     * Clone selections for selected value comparison after splicing. */
    const clonedSelections = clone(this.listOption.options) as string[];
    const itemToRemove: string = clonedSelections[valueIndex];

    /* Remove item from selections */
    this.listOption.options.splice(valueIndex, 1);

    /* SINGLE selection
     * Clear selected item value if item is selected. */
    if (this.listOption.isSingleSelection) {
      if (this.listOption.selected === itemToRemove) {
        this.listOption.selected = '';
      }
    }

    /* MULTIPLE selections
     * Compare arrays and remove values from selected array if item(s) is selected. */
    const selectedItemIndex: number = this.listOption.selected.indexOf(
      itemToRemove
    );

    if (selectedItemIndex !== -1) {
      (this.listOption.selected as string[]).splice(selectedItemIndex, 1);
    }
  }

  removeListItem(event: any, identifierClass: string, itemIndex: number) {
    if (event.target.parentElement.className.includes(identifierClass)) {
      this.removeItemFromList(itemIndex);
    }
  }

  assignValue(): void {
    this.attributeValue.value = this.listOption;
  }

  clearRadioButtonValue(element: any): void {
    const clickedValue: string = element.target.parentElement.children[0].value;

    if (clickedValue === this.listOption.selected) {
      this.listOption.selected = '';
    }
  }

  /**
   * Add a separate watcher for "hasDuplicationError" property
   * because this property is updated by parent.
   */
  @Watch('duplicationError')
  duplicationCallback(currentError: boolean, previousError: boolean): void {
    this.validateLabel();
    this.updateAttribute(this.attributeValue);
  }

  @Watch('listItemContent')
  onListItemContentChange(): void {
    if (this.newItemExists) {
      this.fieldErrorMsg = `List item '${this.listItemContent}' already exists.`;
      this.attributeValue.hasFieldError = true;
      this.updateAttribute(this.attributeValue);
      return;
    }

    this.validateRequiredFieldValues();
  }

  @Watch('listOption', { deep: true })
  listOptionChangeCallback(): void {
    this.assignValue();
    this.updateAttribute(this.attributeValue);
  }

  @Watch('attributeValue.value', { deep: true })
  validateRequiredFieldValues(): void {
    /**
     * A required list must have:
     * 1. selections
     * 2. selected value(s)
     */
    if (this.attributeValue.isRequired) {
      if (
        (this.attributeValue.value as GroupLevelAttributeListType).options
          .length === 0
      ) {
        this.fieldErrorMsg = 'Required list cannot be empty.';
        this.attributeValue.hasFieldError = true;
        this.updateAttribute(this.attributeValue);
        return;
      }

      if (
        (this.attributeValue.value as GroupLevelAttributeListType).selected
          .length === 0
      ) {
        this.fieldErrorMsg = 'Required list must have selected value(s).';
        this.attributeValue.hasFieldError = true;
        this.updateAttribute(this.attributeValue);
        return;
      }
    }

    this.fieldErrorMsg = '';
    this.attributeValue.hasFieldError = false;
    this.updateAttribute(this.attributeValue);
  }
}
