





































































































import { Component, Prop, Watch, Vue, PropSync } from 'vue-property-decorator';
import BaseTable from '@/components/BaseTable.vue';
import { MyjbiGroupDetail } from '../../../jbi-shared/types/myjbi-group.types';
import { MemberObject } from '../../../utils/group.util';
import dayjs from 'dayjs';
import { getHtmlContent } from '@/jbi-shared/util/group-level-attributes.util';

@Component({
  components: { BaseTable }
})
export default class ExistingMembersSelector extends Vue {
  @Prop(Array) parentGroupMembers!: MyjbiGroupDetail['members'];
  @Prop(Array)
  parentGroupAttributeSpecs!: MyjbiGroupDetail['groupUserAttributeSpecs'];
  @PropSync('membersData', Array)
  syncedMembersData!: MemberObject[] | null;
  @PropSync('notify', Boolean) syncedNotify!: boolean;

  convertTime(time: string) {
    return dayjs(time).format('DD MMM YYYY');
  }

  renderTextAreaContent(attributeValue: string): string {
    return getHtmlContent(attributeValue || '');
  }

  get allMemberSelected() {
    return this.parentGroupMembersData?.every((m1) =>
      this.syncedMembersData?.find((m2) => m2 === m1)
    );
  }

  get parentGroupMembersData() {
    return (
      this.parentGroupMembers?.map((member) => {
        return member?.attributes;
      }) || null
    );
  }

  get isSelectedArr() {
    return this.parentGroupMembersData?.map((m1) =>
      this.syncedMembersData?.some((m2) => this.isSameMember(m1, m2))
    );
  }

  public formatedDate(date: string) {
    return dayjs(date).format('DD MMM YYYY');
  }

  @Watch('parentGroupMembersData', { immediate: true })
  onParentGroupDetailLoaded() {
    this.syncedMembersData = [...(this.parentGroupMembersData || [])];
  }

  handleToggleAll(e: boolean) {
    if (e) {
      this.syncedMembersData = [...(this.parentGroupMembersData || [])];
    } else {
      this.syncedMembersData = [];
    }
  }

  handleToggle(e: boolean, member: MemberObject) {
    if (e) {
      this.syncedMembersData?.push(member);
    } else {
      this.syncedMembersData = this.syncedMembersData!.filter(
        (m) => !this.isSameMember(m, member)
      );
    }
  }

  isSameMember(m1: MemberObject, m2: MemberObject) {
    return (m2.Email || m2.email) === (m1.Email || m1.email);
  }
}
