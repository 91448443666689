























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import delay from 'delay';

@Component({
  components: {}
})
export default class GroupCreationSubmitButtons extends Vue {
  @Prop(Boolean) loading!: boolean;
  @Prop(Boolean) invalid!: boolean;
  @Prop(Object) exitRoute!: Partial<Route>;
  @Prop(String) btnText!: Partial<Route>;
  @Prop(Boolean) isProtectGroup!: boolean;

  public async handleSubmit() {
    const dialogElem: Vue = this.$buefy.dialog.confirm({
      title: `Discard Group Creation`,
      message: `All data entered will be lost once you exit this page`,
      confirmText: 'Cancel',
      canCancel: ['button'],
      onConfirm: () => undefined,
      cancelText: 'Exit',
      onCancel: () => this.$router.replace(this.exitRoute)
    });

    while (!dialogElem.$el?.querySelector) {
      await delay(50);
    }
    const cancelBtn = dialogElem.$el?.querySelector?.(
      `.modal-card > footer > button:first-child`
    );
    cancelBtn?.classList?.add?.(`is-red`);
  }

  public async handleGroupCreation() {
    if (this.isProtectGroup) {
      const dialogElem: Vue = this.$buefy.dialog.confirm({
        title: `Create Locked Group?`,
        message: `Details and settings cannot be edited once a group is locked. You may still add and remove members in this group.`,
        confirmText: this.btnText as string,
        canCancel: ['button'],
        onConfirm: () => this.$emit('submit'),
        cancelText: 'Cancel',
        onCancel: () => undefined
      });
    } else {
      this.$emit('submit');
    }
  }
}
