var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Supporting Documentation (optional)"}}),(_vm.agreementFileTags && _vm.agreementFileTags.length)?_c('div',[_c('b-taginput',{class:_vm.$style.uploadedAgreementFiles,attrs:{"value":_vm.agreementFileTags,"field":"name","disabled":!_vm.isUserAllowed(
            _vm.PermissionsMatrixActionsEnum.DELETE,
            'group_administration-groups-update_groups-update_supporting_documents-delete_supporting_documents',
            true
          )},on:{"input":function($event){_vm.agreementFileTags = $event}}}),(
          _vm.isUserAllowed(
            _vm.PermissionsMatrixActionsEnum.UPDATE,
            'group_administration-groups-update_groups-update_supporting_documents-upload_supporting_documents',
            true
          )
        )?_c('input',{class:_vm.$style.uploadFileBtnInput,attrs:{"type":"file","name":"uploadFileBtn","id":"uploadFileBtn","multiple":"","accept":".pdf"},on:{"input":_vm.handleUploadFileBtn}}):_vm._e(),(
          _vm.isUserAllowed(
            _vm.PermissionsMatrixActionsEnum.UPDATE,
            'group_administration-groups-update_groups-update_supporting_documents-upload_supporting_documents',
            true
          )
        )?_c('label',{staticClass:"has-text-primary has-text-weight-bold",class:_vm.$style.uploadFileBtn,attrs:{"for":"uploadFileBtn"}},[_vm._v(" Upload file ")]):_vm._e()],1):(
        _vm.isUserAllowed(
          _vm.PermissionsMatrixActionsEnum.UPDATE,
          'group_administration-groups-update_groups-update_supporting_documents-upload_supporting_documents',
          true
        )
      )?_c('FileUpload',{attrs:{"accept":".pdf","multiple":""},model:{value:(_vm.agreementFiles),callback:function ($$v) {_vm.agreementFiles=$$v},expression:"agreementFiles"}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }