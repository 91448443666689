















































































import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import BaseTable from '@/components/BaseTable.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import BasePagination from '@/components/base/BasePagination.vue';
import SortableTableHeader from '@/components/SortableTableHeader.vue';
import AttributeRow from './AttributeRow.vue';

import { MyjbiGroupUserAttributeSpec } from '@/jbi-shared/types/myjbi-group.types';
import { SortOrder } from '@/jbi-shared/types/search.types';
import {
  PermissionsMatrixActionsEnum,
  ResourceExceptions
} from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { EntityTypes } from '@/store/modules/module-tree/enums/module-tree.enums';
import { isUserAllowed } from '@/utils/rbac.util';
import { State } from 'vuex-class';
import { RootState } from '@/store/store';

enum AttributesListContext {
  ADD_EXISTING_ATTRIBUTE = 'add_existing_attribute',
  REMOVE_EXISTING_ATTRIBUTE = 'remove_existing_attribute'
}

/**
 * This intermediate component aggregates an array of attribute specs
 * via a paginated table. It relies heavily on its parent/consumer
 * to RENDER props, and also its "rightful" child AttributeRow.
 *
 * Prop `disableRow` (optional): Accepts a function that returns a boolean.
 * This function will be used on every row item to determine
 * which row should be disabled. (`true` disables the row.)
 *
 * Note:
 * Please keep this component simple,
 * and house main logic in its parent/consumer.
 *
 * Available events:
 * - `@updateAttributeSpec`, `@addAttribute`, and `@removeAttribute`
 * These events are "bubbled" from AttributeRow, and will be propagated
 * to parent/consumer.
 *
 * - `@selectAll` and `@deselectAll`
 * Tells parent/consumer to perform bulk selection (main logic)
 */
@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {
    BaseTable,
    BaseLoading,
    BasePagination,
    PaginationMixin,
    SortableTableHeader,
    AttributeRow
  }
})
export default class AttributesList extends mixins(PaginationMixin) {
  @Prop() items!: MyjbiGroupUserAttributeSpec[];
  @Prop(Number) totalNumberOfPage!: number;
  @Prop(Number) totalCount!: number;
  @Prop(Boolean) isFirstPage!: boolean;
  @Prop(Boolean) isLastPage!: boolean;
  @Prop(Number) startItemIndex!: number;
  @Prop(Number) endItemIndex!: number;
  @Prop(String) sortColumn!: string;
  @Prop(String) sortOrder!: SortOrder;
  @Prop({ default: false }) isSettings!: boolean;
  @Prop(Boolean) isLoading!: boolean;
  @Prop() groupTypeName!: string;
  @Prop(Number) groupId!: number;
  @Prop() groupExceptions!: ResourceExceptions;
  @Prop() context: AttributesListContext =
    AttributesListContext.REMOVE_EXISTING_ATTRIBUTE;

  /**
   * This prop allows parent/consumer to supply a dynamic function
   * to indicate when a row should be disabled.
   */
  @Prop(Function) disableRow!: (spec: MyjbiGroupUserAttributeSpec) => boolean;
  @Prop() rowStyling!: string | string[];
  @Prop() attributeSelection!: MyjbiGroupUserAttributeSpec[];

  @State((state: RootState) => state.rbac.groupTypesUserHasAccessTo)
  public groupTypesUserHasAccessTo!: string[];

  // boolean to disable the "select all" checkbox
  get isMainListEmpty(): boolean {
    if (this.items === undefined) {
      return true;
    }

    if (this.items.length === 0) {
      return true;
    }

    return false;
  }

  /**
   * This computed value correctly reflects if every SELECTABLE row is selected.
   * And since disabled rows are not selectable, they should NOT be counted.
   * (selectable = not disabled)
   */
  get areAllSelected(): boolean {
    if (this.isMainListEmpty) {
      return false;
    }

    const selectedAttrIds = this.attributeSelection.map(
      (spec) => spec.groupUserAttribute.id
    );

    return this.items
      .filter((item) => {
        const isSelectable = this.shouldRowBeDisabled(item) === false;
        return isSelectable;
      })
      .every((item) => {
        return selectedAttrIds.includes(item.groupUserAttribute.id) === true;
      });
  }

  // This function is what makes `disableRow` prop optional.
  shouldRowBeDisabled(spec: MyjbiGroupUserAttributeSpec): boolean {
    if (this.disableRow instanceof Function) {
      return this.disableRow(spec);
    }

    return false;
  }

  isAttributeSelected(attributeId: number): boolean {
    if (this.attributeSelection === undefined) {
      return false;
    }

    const attribute = this.attributeSelection.find((selectedAttr) => {
      return selectedAttr.groupUserAttribute.id === attributeId;
    });

    return Boolean(attribute);
  }

  selectAllInCurrentPage(checkboxValue: boolean): void {
    if (checkboxValue === true) {
      this.$emit('selectAll');
    } else {
      this.$emit('deselectAll');
    }
  }

  /* pagination mixin staples */
  onClickHeaderColumn(columnName: string): void {
    this.$emit('sort', {
      sortColumn: columnName,
      sortOrder:
        this.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC
    });
  }

  getSortOrderOfColumn(): SortOrder {
    return this.sortOrder;
  }

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string | string[],
    skipImplicitCheck?: boolean
  ): boolean {
    const groupTypeName = this.groupTypeName
      ? this.groupTypeName
      : this.groupTypesUserHasAccessTo[0];
    return isUserAllowed(
      action,
      module,
      EntityTypes.GROUP,
      groupTypeName,
      this.groupId,
      this.groupExceptions,
      skipImplicitCheck
    );
  }

  /**
   * Depending on context, attribute is disabled.
   *
   * There are two states here that need to accounted for:
   * - Removing existing attributes from a group
   * - Adding existing attributes to a group
   */
  get attributeDisabled() {
    switch (this.context) {
      case AttributesListContext.REMOVE_EXISTING_ATTRIBUTE:
        return this.userAllowedToRemoveAddedUserAttributes();
      case AttributesListContext.ADD_EXISTING_ATTRIBUTE:
        return this.userAllowedToAddExistingUserAttributes();
      default:
        return false;
    }
  }

  private userAllowedToAddExistingUserAttributes() {
    return this.isUserAllowed(
      PermissionsMatrixActionsEnum.CREATE,
      'group_administration-groups-update_groups-update_user_attributes-create_existing_user_attributes'
    );
  }

  private userAllowedToRemoveAddedUserAttributes() {
    return this.isUserAllowed(
      PermissionsMatrixActionsEnum.DELETE,
      'group_administration-groups-update_groups-update_user_attributes-delete_added_user_attributes'
    );
  }
}
