var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{class:_vm.$style.memberSelected},[_vm._v(_vm._s(_vm.membersData ? _vm.membersData.length : 0)+" Selected Member(s)")]),(_vm.parentGroupAttributeSpecs && _vm.parentGroupAttributeSpecs.length)?_c('BaseTable',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',[_c('th',[_c('b-checkbox',{attrs:{"value":_vm.allMemberSelected},on:{"input":_vm.handleToggleAll}})],1),_vm._l((_vm.parentGroupAttributeSpecs),function(attributeSpec){return _c('th',{key:attributeSpec.groupUserAttribute.id},[_vm._v(" "+_vm._s(attributeSpec.groupUserAttribute.name)+" ")])})],2)]},proxy:true},{key:"body",fn:function(){return _vm._l((_vm.parentGroupMembers),function(member,i){return _c('tr',{key:member.userId},[_c('td',[_c('b-checkbox',{attrs:{"value":_vm.isSelectedArr[i]},on:{"input":function($event){return _vm.handleToggle($event, _vm.parentGroupMembersData[i])}}})],1),_vm._l((_vm.parentGroupAttributeSpecs),function(attributeSpec){return _c('td',{key:attributeSpec.groupUserAttribute.id},[(
              attributeSpec['groupUserAttribute']['groupUserAttributeType'][
                'type'
              ] === 'image'
            )?_c('span',{class:[
              _vm.$style.imageIconBox,
              !member.attributes[attributeSpec.groupUserAttribute.slug]
                ? _vm.$style.imageIconBoxExtraPadding
                : ''
            ]},[(member.attributes[attributeSpec.groupUserAttribute.slug])?_c('img',{class:_vm.$style.imageIcon,attrs:{"src":member.attributes[attributeSpec.groupUserAttribute.slug]}}):_c('b-icon',{class:_vm.$style.imageIcon,attrs:{"icon":"image"}})],1):(
              attributeSpec['groupUserAttribute']['groupUserAttributeType'][
                'type'
              ] === 'date'
            )?_c('p',[_vm._v(" "+_vm._s(member.attributes[attributeSpec.groupUserAttribute.slug] ? _vm.formatedDate( member.attributes[attributeSpec.groupUserAttribute.slug] ) : '-')+" ")]):(
              attributeSpec['groupUserAttribute']['groupUserAttributeType'][
                'type'
              ] === 'text area'
            )?_c('p',{class:_vm.$style.clampedContent},[_vm._v(" "+_vm._s(member.attributes[attributeSpec.groupUserAttribute.slug] ? _vm.renderTextAreaContent( member.attributes[attributeSpec.groupUserAttribute.slug] ) : '-')+" ")]):_c('p',[_vm._v(" "+_vm._s(member.attributes[attributeSpec.groupUserAttribute.slug] || '-')+" ")])])})],2)})},proxy:true}],null,false,3119787805)}):_vm._e(),(_vm.notify)?_c('b-checkbox',{staticClass:"m-t-1 m-b-1",model:{value:(_vm.syncedNotify),callback:function ($$v) {_vm.syncedNotify=$$v},expression:"syncedNotify"}},[_c('span',[_vm._v(" Notify members ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }