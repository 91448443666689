var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-dropdown',{class:[
      _vm.$style.dropdown,
      _vm.fullWidth ? _vm.$style.dropdownFullWidth : '',
      _vm.disabled ? _vm.$style.disable : ''
    ],attrs:{"aria-role":"list","placeholder":'No ' + _vm.groupUserAttributeSpec.groupUserAttribute.name + ' selected',"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_vm._t("customTrigger",[_c('div',{class:[
            _vm.$style.trigger,
            !_vm.isValid && !_vm.syncedMemberAttributeValue ? _vm.$style.isDanger : '',
            _vm.disabled ? _vm.$style.triggerDisable : ''
          ]},[_c('p',{class:_vm.$style.ellipsisOverflow,style:({
              'max-width': _vm.maxCharLength ? _vm.maxCharLength + 'ch' : '15ch'
            }),attrs:{"title":_vm.syncedMemberAttributeValue}},[(_vm.syncedMemberAttributeValue === '')?_c('span',{class:_vm.$style.placeholder},[_vm._v(" "+_vm._s('No ' + _vm.groupUserAttributeSpec.groupUserAttribute.name + ' selected')+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.syncedMemberAttributeValue)+" ")]),_c('b-icon',{class:_vm.$style.icon,attrs:{"icon":"chevron-down"}})],1)])]},proxy:true}],null,true)},_vm._l((_vm.groupUserAttributeSpec
        .groupUserAttribute.option.value),function(listOption,listIndex){return _c('div',{key:listIndex,class:_vm.$style.dropdownItem},[_c('div',{class:_vm.$style.dropdownItemRow},[_c('div',[_c('label',{staticClass:"b-checkbox checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.syncedListOptions),expression:"syncedListOptions"}],attrs:{"type":"checkbox"},domProps:{"value":listOption,"checked":Array.isArray(_vm.syncedListOptions)?_vm._i(_vm.syncedListOptions,listOption)>-1:(_vm.syncedListOptions)},on:{"click":function($event){return _vm.syncedListToggleOption(listOption)},"change":function($event){var $$a=_vm.syncedListOptions,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=listOption,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.syncedListOptions=$$a.concat([$$v]))}else{$$i>-1&&(_vm.syncedListOptions=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.syncedListOptions=$$c}}}}),_c('span',{staticClass:"check"}),_c('span',{class:_vm.$style.dropdownItemTitle},[_vm._v(" "+_vm._s(listOption)+" ")])])])])])}),0),_c('span',{class:_vm.$style.errorText},[_vm._v(_vm._s(_vm.errorMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }