var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-card has-modal-large"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{class:_vm.$style.title},[_vm._v("Manage User Attribute(s)")]),_c('button',{staticClass:"close-icon",attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_c('i',{staticClass:"mdi mdi-close"})])]),_c('section',{staticClass:"modal-card-body",class:_vm.$style.attributeContainer},[_c('div',{staticClass:"my-5"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Name"}},[_c('b-input',{attrs:{"value":_vm.name,"disabled":""}})],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Type"}},[_c('b-input',{attrs:{"value":_vm.groupType,"disabled":""}})],1)],1)])]),_c('hr'),_c('div',{class:_vm.$style.buttonControl},[(
          _vm.isUserAllowed(
            _vm.PermissionsMatrixActionsEnum.CREATE,
            'group_administration-groups-update_groups-update_user_attributes-create_existing_user_attributes',
            true
          )
        )?_c('b-button',{staticClass:"is-square",on:{"click":_vm.openAddUserAttributesModal}},[_vm._v(" Add Existing Attribute ")]):_vm._e(),(
          _vm.isUserAllowed(
            _vm.PermissionsMatrixActionsEnum.CREATE,
            'group_administration-groups-update_groups-update_user_attributes-create_new_user_attributes',
            true
          )
        )?_c('b-button',{staticClass:"is-square is-primary",on:{"click":_vm.openCreateAttributeModal}},[_vm._v(" Create New Attribute ")]):_vm._e()],1),_c('p',{class:_vm.$style.hint},[_vm._v(" "+_vm._s(_vm.clonedSelection.length)+" Selected Attribute(s) ")]),_c('BasePaginatorHoc',{attrs:{"component":_vm.AttributesList,"isSettings":true,"groupId":_vm.groupId,"groupTypeName":_vm.groupTypeName,"attributeSelection":_vm.clonedSelection,"disableRow":_vm.disableRowFn,"page":_vm.page,"perPage":_vm.perPage,"sortOrder":_vm.sortOrder,"sortColumn":_vm.sortColumn,"items":_vm.filteredUserAttributes,"totalCount":_vm.totalCount,"group-exceptions":_vm.groupExceptions,"context":"remove_existing_attribute"},on:{"update:page":function($event){_vm.page=$event},"update:perPage":function($event){_vm.perPage=$event},"update:per-page":function($event){_vm.perPage=$event},"sort":_vm.sortItems,"paginate":_vm.paginateItems,"selectAll":_vm.selectAllInCurrentPage,"deselectAll":_vm.removeAllInCurrentPage,"addAttribute":_vm.addAttribute,"removeAttribute":_vm.removeAttribute,"updateAttributeSpec":_vm.updateRowSpecVisual}})],1),_c('footer',{staticClass:"modal-card-foot"},[_c('b-button',{class:_vm.$style.cancelButton,on:{"click":_vm.closeModal}},[_vm._v(" Cancel ")]),_c('b-button',{staticClass:"button is-primary",attrs:{"disabled":!_vm.isManageUserAttributes},on:{"click":_vm.updateAttributes}},[_vm._v(" Manage ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }