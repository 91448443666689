var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-field',{class:_vm.$style.dropdownField,attrs:{"label":"User Attributes"}},[_c('b-dropdown',_vm._b({class:_vm.$style.dropdown,attrs:{"aria-role":"list","expanded":"","can-close":true,"trap-focus":"","close-on-click":false,"disable":_vm.disabled},on:{"active-change":_vm.onDropdownToggle},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_vm._t("customTrigger",[_c('div',{class:_vm.$style.trigger},[_c('div',{class:_vm.$style.attributeWrapper},[_vm._l((_vm.syncedAttributes),function(attributeSpecs,index){return [(index < 5)?_c('div',{key:index,class:_vm.$style.attribute},[(attributeSpecs.required || attributeSpecs.isDefault)?_c('span',{attrs:{"title":attributeSpecs.groupUserAttribute.name}},[_c('i',{staticClass:"mdi mdi-asterisk",class:_vm.$style.asterisk})]):_vm._e(),_c('span',{class:_vm.$style.contentWrapper,style:(attributeSpecs.groupUserAttribute.name.length > 12
                      ? {
                          textOverflow: 'ellipsis',
                          width: '12ch'
                        }
                      : {})},[_vm._v(_vm._s(attributeSpecs.groupUserAttribute.name)+" ")])]):_vm._e(),(index === 5)?_c('div',{key:index,class:_vm.$style.moreAttributeLabel},[_vm._v(" "+_vm._s(("+" + (_vm.syncedAttributes.length - 5) + " more"))+" ")]):_vm._e()]})],2),_c('b-icon',{class:_vm.$style.icon,attrs:{"icon":"chevron-down"}})],1)])]},proxy:true}],null,true)},'b-dropdown',_vm.$props,false),[_vm._l((_vm.dropdownSelection),function(attribute){
                      var _obj;
return _c('b-dropdown-item',{key:attribute.groupUserAttribute.id,class:( _obj = {}, _obj[_vm.$style.noPadding] = true, _obj[_vm.$style.isDropdownDisabled] = _vm.isDefault(
          attribute.groupUserAttribute.id
        ), _obj ),attrs:{"aria-role":"listitem","disabled":_vm.canDeleteUserAttribute(attribute.groupUserAttribute.id) ||
        !_vm.userAllowedToRemoveAddedUserAttributes},nativeOn:{"click":function($event){_vm.isDefault(attribute.groupUserAttribute.id)
          ? undefined
          : _vm.toggleOption(attribute)}}},[_c('b-checkbox',{attrs:{"native-value":attribute.groupUserAttribute.id,"disabled":_vm.canDeleteUserAttribute(attribute.groupUserAttribute.id) ||
          !_vm.userAllowedToRemoveAddedUserAttributes},model:{value:(_vm.selectedAttributesIds),callback:function ($$v) {_vm.selectedAttributesIds=$$v},expression:"selectedAttributesIds"}},[(_vm.isRequired(attribute.groupUserAttribute.id))?_c('span',{class:_vm.$style.asteriskWrapper},[_c('i',{staticClass:"mdi mdi-asterisk",class:_vm.$style.asterisk})]):_vm._e(),_c('span',{class:_vm.$style.itemWrapper},[_vm._v(" "+_vm._s(attribute.groupUserAttribute.name)+" "),(_vm.isLocked(attribute.groupUserAttribute.id))?_c('i',{staticClass:"mdi mdi-lock",class:_vm.$style.lock}):_vm._e()])])],1)}),_vm._t("footer")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }