















































import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { UserAttributesStringInputOption } from '@/store/modules/admin/types/group.types';
import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import {
  MyjbiGroupUserAttribute,
  MyjbiGroupUserAttributeSpec
} from '@/jbi-shared/types/myjbi-group.types';
import { RootState } from '@/store/store';
import UserAttributeTemplateModal from '../../../AdminUserManagement/components/UserAttributeTemplate/UserAttributeTemplateModal.vue';
import ManageUserAttribute from './ManageUserAttribute.vue';
import UserAttributeMultiSelect from './UserAttributeMultiSelect.vue';
import { ToastProgrammatic as Toast } from 'buefy';
import {
  PermissionsMatrixActionsEnum,
  ResourceExceptions
} from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '@/utils/rbac.util';
import { EntityTypes } from '@/store/modules/module-tree/enums/module-tree.enums';

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {
    UserAttributeMultiSelect,
    UserAttributeTemplateModal,
    ManageUserAttribute
  }
})
export default class UserAttributeTab extends Vue {
  @PropSync('userAttributes', { type: Array })
  syncedAttributes!: MyjbiGroupUserAttributeSpec[];
  @Prop(String) name!: string;
  @Prop(Object) type!: UserAttributesStringInputOption;
  @Prop(String) subGroupType!: string;
  @Prop() parentAttributes!: MyjbiGroupUserAttributeSpec[];
  @Prop({ default: true }) useTemplate!: boolean;
  @Prop({ default: true }) isSettings!: boolean;
  @Prop() groupTypeName!: string;
  @Prop() groupId!: number;
  @Prop() groupExceptions!: ResourceExceptions;

  @Action('admin/getGroupUserAttributes')
  public getGroupUserAttributes!: () => void;

  @State((state: RootState) => state.admin.groupUserAttributes)
  public groupUserAttributes!: MyjbiGroupUserAttribute[];

  @State((state: RootState) => state.rbac.groupTypesUserHasAccessTo)
  public groupTypesUserHasAccessTo!: string[];

  public parentAttributeIds: number[] = [];
  public userAttributeUpdateKey: number = Math.floor(Math.random() * 999);
  public newlyCreatedAttribute: MyjbiGroupUserAttributeSpec[] = [];

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string | string[],
    skipImplicitCheck?: boolean
  ): boolean {
    const instance = EntityTypes.GROUP + '_' + this.groupId;
    const groupTypeName = this.groupTypeName
      ? this.groupTypeName
      : this.groupTypesUserHasAccessTo[0];
    return isUserAllowed(
      action,
      module,
      EntityTypes.GROUP,
      groupTypeName,
      this.groupId,
      this.groupExceptions,
      skipImplicitCheck
    );
  }

  get isAllowedToManageUserAttributes(): boolean {
    if (!this.isAllowedToViewExistingUserAttributes) {
      return false;
    }

    return (
      this.isAllowedToCreateNewUserAttributes ||
      this.isAllowedToDeleteAddedUserAttributes
    );
  }

  get isAllowedToCreateNewUserAttributes() {
    return this.isUserAllowed(
      PermissionsMatrixActionsEnum.CREATE,
      [
        'group_administration-groups-update_groups-update_user_attributes-create_existing_user_attributes',
        'group_administration-groups-update_groups-update_user_attributes-create_new_user_attributes'
      ],
      true
    );
  }

  get isAllowedToDeleteAddedUserAttributes() {
    return this.isUserAllowed(
      PermissionsMatrixActionsEnum.DELETE,
      'group_administration-groups-update_groups-update_user_attributes-delete_added_user_attributes',
      true
    );
  }

  get isAllowedToViewExistingUserAttributes() {
    return this.isUserAllowed(
      PermissionsMatrixActionsEnum.READ,
      'group_administration-groups-update_groups-update_user_attributes-read_existing_user_attributes',
      true
    );
  }

  mounted(): void {
    this.onGroupUserAttributesChange();
    this.getGroupUserAttributes();
  }

  openManageAttributeModal(): void {
    this.$buefy.modal.open({
      parent: this,
      component: ManageUserAttribute,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: true,
      props: {
        selectedAttributes: this.syncedAttributes,
        name: this.name,
        type: this.type,
        subGroupType: this.subGroupType,
        groupId: this.groupId,
        groupTypeName: this.groupTypeName,
        parentAttributeIds: this.parentAttributeIds,
        groupExceptions: this.groupExceptions
      },
      events: {
        updateUserAttributes: (
          attributes: MyjbiGroupUserAttributeSpec[],
          newlyCreatedAttribute: MyjbiGroupUserAttributeSpec[]
        ) => {
          this.syncedAttributes = attributes;
          this.newlyCreatedAttribute = newlyCreatedAttribute;
          this.userAttributeUpdateKey += 1;
        }
      }
    });
  }

  clearAndAddAttributes(attributes: MyjbiGroupUserAttributeSpec[]) {
    const defaultAttributes: MyjbiGroupUserAttributeSpec[] = [];
    const nonDefaultAttributes: MyjbiGroupUserAttributeSpec[] = [];

    this.syncedAttributes.forEach((attr) => {
      if (attr.isDefault) {
        defaultAttributes.push(attr);
      } else {
        nonDefaultAttributes.push(attr);
      }
    });

    const allAttributes = {
      ...nonDefaultAttributes.reduce(
        (acc, attr) => ({
          ...acc,
          [attr.groupUserAttribute.id]: attr
        }),
        {}
      ),
      ...attributes.reduce(
        (acc, attr) => ({ ...acc, [attr.groupUserAttribute.id]: attr }),
        {}
      ),
      ...defaultAttributes.reduce(
        (acc, attr) => ({ ...acc, [attr.groupUserAttribute.id]: attr }),
        {}
      )
    };
    this.userAttributeUpdateKey += 1;
    this.syncedAttributes = Object.values(allAttributes);
  }

  openUserTemplateModal() {
    this.$buefy.modal.open({
      parent: this,
      component: UserAttributeTemplateModal,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: true,
      events: {
        addUserAttributeTemplate: this.clearAndAddAttributes,
        updateTemplateName: (templateName: string) => {
          Toast.open({
            queue: true,
            type: 'is-dark',
            position: 'is-top',
            message: `${templateName} added`
          });
        }
      }
    });
  }

  @Watch('groupUserAttributes')
  @isTruthy
  public onGroupUserAttributesChange() {
    if (this.parentAttributes?.length) {
      this.parentAttributeIds = this.parentAttributes.map(
        (attr) => attr.groupUserAttribute.id
      );
    }
  }
}
