import {
  CplusDocumentStatus,
  RevisionPublicationStatus,
} from './document-status.types';
import { CplusDocumentType } from './document.types';
import {
  CriterionRecord,
  DateOrString,
  FullDocumentRevisionObject,
} from './full-document-revision-object.types';
import { OicPayload } from './jaas-oauth2.types';

export interface DocumentIndexingPayload {
  title: string;
  title_as_keyword: string;
  body: string;
  primaryNode: string;
  secondaryNodes: string[];
  updatedAt: string;
  searchDate: string;
  uniquePublicationId: string;
  defaultDocDisplayId: string;
  documentType: CplusDocumentType;
  projectId: number;
  documentId: number;
  revisionId: number;
  versionId: number;
  publicationId?: number;
  documentOwner: string;
  collaborators?: string[];
  documentOwnerId: number;
  collaboratorsIds?: number[];
  publishedAt: string;
  archivedAt: string;
  withdrawnAt?: string;
  publicationStatus: RevisionPublicationStatus;
  participants: string[];
  pendingEmails: string[];
  tags?: string[];
  plainTags?: string[];
  authors?: string[];
  assigneeId: number;
  assigneeFullName: string;
  isLegacy: boolean;
  legacyBaseDocumentId?: number;
  isLatestLegacyVersion?: boolean;
  sourceLegacyDocumentLatestBaseDocumentId?: number;
  sourceLegacyDocumentGlobalBaseDocumentId?: number;
  legacyIsActive?: boolean;
  // migratedFromLegacyBaseDocumentId should be the same as sourceLegacyDocumentLatestBaseDocumentId,
  // we only migrate latest version
  migratedFromLegacyBaseDocumentId?: number;
  migratedToNewDocumentId?: number;
  // for BPIS
  resourceUrl?: string;
  criteria?: CriterionRecord[];
  onlyShowDocumentsWithAuditCriteria?: boolean;
}

export interface DocumentIndexingJobPayload {
  revisionId: string;
  body: DocumentIndexingPayload;
}

export interface LatestDocumentIndexingJobPayload {
  id: string;
  body: DocumentIndexingPayload;
}

export interface LatestPublishedDocumentIndexingJobPayload {
  id: string;
  body: DocumentIndexingPayload;
}

export interface ProjectIndexingPayload {
  projectTitle: string;
  primaryNode: string;
  secondaryNodes: string[];
  updatedAt: string;
  projectId: number;
  documents: ProjectDocumentPayload[];
}

export interface ProjectIndexingJobPayload {
  id: string;
  body: ProjectIndexingPayload;
}
export interface PartialProjectIndexingJobPayload {
  id: string;
  body: Partial<ProjectIndexingPayload>;
}

// should be exactly the same as user entity
export interface BackendUserEntity {
  id: number;
  jaasSubjectIdentifier: string;
  updatedAt: DateOrString;
  createdAt: DateOrString;
  oicPayload: OicPayload;
  email: string;
}
export type UserIndexingJobPayload = BackendUserEntity;

export interface UserIndexingPayload {
  id: number;
  updatedAt: string;
  createdAt: string;
  email: string;
  fullName: string;
  username: string;
}

export interface ProjectDocumentPayload {
  documentId: number;
  documentTitle: string;
  documentOwner: string;
  documentOwnerId: number;
  collaborators?: string[];
  collaboratorsIds?: number[];
  participants: string[];
  pendingEmails: string[];
  documentType: CplusDocumentType;
}

export interface KeywordIndexingPayload {
  id?: number;
  keyword: string;
}

export interface KeywordIndexingJobPayload {
  id: string;
  body: KeywordIndexingPayload;
}

export interface CriterionIndexingPayload {
  documentId: string;
  document: {
    id: string;
    title: string;
    description: string;
    link: string;
  };
  criteria: CriteriaIndexPayload[];
}

export interface CriteriaIndexPayload {
  id: string;
  title: string;
  type: string;
  tags: {
    plain: string[];
    mesh: string[];
  };
  bestPracticeRecommendations: string[];
  [s: string]: any;
}

export interface CriterionIndexingJobPayload {
  id: string;
  body: CriterionIndexingPayload;
}

export type RemoveCriterionsFromIndexByDocumentIdJobPayload = number;

export type IndexCriterionsByDocumentJobPayload = FullDocumentRevisionObject;

export interface UpdateDocumentAssigneeIndexingJobPayload {
  projectDocumentId: number;
  assignee: BackendUserEntity | null;
}

export interface UpdateDocumentStatusIndexingJobPayload {
  documentId: number;
  documentStatus: CplusDocumentStatus;
}

export interface UpdateDocumentProjectNodePayload {
  projectId: string;
  primaryNode: string;
  secondaryNodes: string[];
}

export interface UpdatePublicationStatusIndexingJobPayload {
  projectDocumentId: number;
  revisionId: number;
  publicationStatus: RevisionPublicationStatus;

  // NOTE: these fields are optional based on each case of updating status, such as publish, archive, withdrawn
  archivedAt?: DateOrString;
  publishedAt?: DateOrString;
  withdrawnAt?: DateOrString;
  publicationId?: number;
  uniquePublicationId?: string;
}

export interface UpdateLegacyDocIndexingJobPayload {
  id: string;
  body: Partial<DocumentIndexingPayload>;
}

export enum SortOrder {
  'ASC' = 'ASC',
  'DESC' = 'DESC',
}

export enum UnaryOperator {
  'INCREMENT' = 'INCREMENT',
  'DECREMENT' = 'DECREMENT',
}
