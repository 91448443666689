
















































import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator';
import { GroupLevelAttributeValue } from '../../../../../store/modules/admin/types/group-level-attribute.types';
import { EntityTypes } from '../../../../../store/modules/module-tree/enums/module-tree.enums';
import { PermissionsMatrixActionsEnum } from '../../../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '../../../../../utils/rbac.util';

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {}
})
export default class TitleAttributeField extends Vue {
  @PropSync('groupName') syncedGroupName!: string;
  @Prop() attributeValue!: GroupLevelAttributeValue;
  @Prop({ default: false }) isSettings!: boolean;
  @Prop() groupTypeName!: string;
  @Prop() groupId!: number;
  @Prop() fieldClasses!: any;
  @Prop() deleteAttribute!: () => void;
  @Prop() updateAttribute!: (attribute: GroupLevelAttributeValue) => void;

  showField: boolean = false;
  labelErrorMsg: string = '';
  fieldErrorMsg: string = '';

  // show title value input field with extra listener
  valueIsGroupName: boolean =
    (this.attributeValue.isTitle as boolean) && !this.isSettings; // on creation page only

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string
  ): boolean {
    const instance = EntityTypes.GROUP + '_' + this.groupId;
    return this.isSettings
      ? isUserAllowed(
          action,
          module,
          EntityTypes.GROUP,
          this.groupTypeName,
          this.groupId
        )
      : true;
  }

  mounted() {
    this.validateRequiredFieldValues();
  }

  @Watch('syncedGroupName')
  assignGroupName() {
    // watch the synced name and assign group name
    if (this.attributeValue.isTitle && this.valueIsGroupName) {
      this.attributeValue.value = this.syncedGroupName;
    }

    this.updateAttribute(this.attributeValue);
  }

  @Watch('attributeValue.value')
  validateRequiredFieldValues() {
    // validate null / empty values for required fields
    if (!this.attributeValue.value) {
      this.fieldErrorMsg = 'Title cannot be empty.';
      this.attributeValue.hasFieldError = true;
      this.updateAttribute(this.attributeValue);
      return;
    }

    this.fieldErrorMsg = '';
    this.attributeValue.hasFieldError = false;
    this.updateAttribute(this.attributeValue);
  }
}
