














































































import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseTable from '../../../../components/BaseTable.vue';
import { GroupUserAttributeTemplate } from '../../../../store/modules/admin/types/group-user-attribute-template.types';

/**
 * This is a dumb component that only renders what was given
 */
@Component({
  components: {
    BaseTable
  }
})
export default class TemplateView extends Vue {
  @Prop() template!: GroupUserAttributeTemplate;

  closeModal() {
    this.$emit('close');
  }
}
