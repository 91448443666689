






































































































import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import {
  MyjbiGroupUserAttribute,
  MyjbiGroupUserAttributeSpec
} from '@/jbi-shared/types/myjbi-group.types';
import { UserAttributesMultiSelectOption } from '@/store/modules/admin/types/group.types';
import { EntityTypes } from '@/store/modules/module-tree/enums/module-tree.enums';
import {
  PermissionsMatrixActionsEnum,
  ResourceExceptions
} from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '@/utils/rbac.util';
import { State } from 'vuex-class';
import { RootState } from '@/store/store';

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  }
})
export default class UserAttributeMultiSelect extends Vue {
  @PropSync('userAttributes', { type: Array })
  syncedAttributes!: MyjbiGroupUserAttributeSpec[];
  @Prop(Array) groupUserAttributes!: MyjbiGroupUserAttribute[];
  @Prop(Array) options!: UserAttributesMultiSelectOption[];
  @Prop(Array) newlyCreatedAttribute!: MyjbiGroupUserAttributeSpec[];
  @Prop(Number) userAttributeUpdateKey!: number;
  @Prop(Array) parentAttributeIds!: number[];
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) isSettings!: boolean;
  @Prop() groupTypeName!: string;
  @Prop() groupId!: number;
  @Prop() groupExceptions!: ResourceExceptions;

  @State((state: RootState) => state.rbac.groupTypesUserHasAccessTo)
  public groupTypesUserHasAccessTo!: string[];

  public attributeSelection: MyjbiGroupUserAttributeSpec[] = [];
  public dropdownSelection: MyjbiGroupUserAttributeSpec[] = [];
  public selectedAttributesIds: number[] = [];

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string,
    skipImplicitCheck?: boolean
  ): boolean {
    const groupTypeName = this.groupTypeName
      ? this.groupTypeName
      : this.groupTypesUserHasAccessTo[0];
    return isUserAllowed(
      action,
      module,
      EntityTypes.GROUP,
      groupTypeName,
      this.groupId,
      this.groupExceptions,
      skipImplicitCheck
    );
  }

  get userAllowedToRemoveAddedUserAttributes() {
    return this.isUserAllowed(
      PermissionsMatrixActionsEnum.DELETE,
      'group_administration-groups-update_groups-update_user_attributes-delete_added_user_attributes',
      true
    );
  }

  canDeleteUserAttribute(groupUserAttributeId: number) {
    const groupType = this.groupTypesUserHasAccessTo
      ? this.groupTypesUserHasAccessTo[0]
      : undefined;
    return this.isDefault(groupUserAttributeId)
      ? this.isDefault(groupUserAttributeId)
      : !isUserAllowed(
          PermissionsMatrixActionsEnum.DELETE,
          'group_administration-groups-update_groups-update_user_attributes-delete_added_user_attributes',
          EntityTypes.GROUP,
          groupType,
          this.groupId,
          undefined,
          true
        );
  }

  toggleOption(selectedAttribute: MyjbiGroupUserAttributeSpec): void {
    const { id } = selectedAttribute.groupUserAttribute;
    if (this.isSelected(id)) {
      this.syncedAttributes = this.syncedAttributes.filter(
        (attribute) => attribute.groupUserAttribute.id !== id
      );
    } else {
      this.syncedAttributes = [...this.syncedAttributes, selectedAttribute];
    }
  }

  updateSelectedAttributesIds() {
    this.selectedAttributesIds = this.syncedAttributes.map(
      (attribute) => attribute.groupUserAttribute.id!
    );
  }

  onDropdownToggle(isOpen: boolean): void {
    if (isOpen) {
      const defaultAttributes: MyjbiGroupUserAttributeSpec[] = [];
      this.dropdownSelection = this.attributeSelection
        .filter((item) => this.isSelected(item.groupUserAttribute.id))
        .filter((item) => {
          if (this.isDefault(item.groupUserAttribute.id)) {
            defaultAttributes.push(item);
          }
          return !this.isDefault(item.groupUserAttribute.id);
        });
      this.dropdownSelection = [
        ...defaultAttributes,
        ...this.dropdownSelection
      ];
    }
  }

  isSelected(id: number): MyjbiGroupUserAttributeSpec | undefined {
    return this.syncedAttributes.find(
      (attribute) => attribute.groupUserAttribute.id === id
    );
  }

  isDefault(id: number): boolean {
    return (
      !!this.isSelected(id)?.isDefault || this.parentAttributeIds.includes(id)
    );
  }

  isLocked(id: number): boolean {
    return !!this.isSelected(id)?.lockAttribute;
  }

  isRequired(id: number): boolean {
    return !!this.isSelected(id)?.required;
  }

  constructAttributeSpec(
    selectedAttribute: MyjbiGroupUserAttribute
  ): MyjbiGroupUserAttributeSpec {
    return {
      groupUserAttribute: selectedAttribute,
      required: false,
      isDefault: false,
      lockAttribute: false
    };
  }

  @Watch('groupUserAttributes')
  public onGroupUserAttributesChange(data: MyjbiGroupUserAttribute[]): void {
    this.attributeSelection = data?.map(this.constructAttributeSpec);
    this.updateSelectedAttributesIds();
  }

  @Watch('userAttributeUpdateKey')
  public onUserAttributeUpdate(): void {
    this.updateSelectedAttributesIds();
  }

  @Watch('newlyCreatedAttribute')
  public onCreatedNewAttribute(): void {
    this.attributeSelection = [
      ...this.newlyCreatedAttribute,
      ...this.attributeSelection
    ].sort((a, b) =>
      a.groupUserAttribute.name > b.groupUserAttribute.name ? 1 : -1
    );
  }
}
