



































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import FileUpload from '@/components/FileUpload.vue';
import { Action } from 'vuex-class';
import {
  GetSignedUrlForUploadRequestPayload,
  GetSignedUrlForUploadResponsePayload
} from '@/store/modules/static-file/types/static-file.types';
import retry from 'async-retry';
import {
  PermissionsMatrixActionsEnum,
  ResourceExceptions
} from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '@/utils/rbac.util';
import { EntityTypes } from '@/store/modules/module-tree/enums/module-tree.enums';

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {
    FileUpload
  }
})
export default class AgreementSection extends Vue {
  @Prop({ default: false }) isSettings!: boolean;
  @Prop() groupTypeName!: string;
  @Prop() groupId!: number;
  @Prop() groupExceptions!: ResourceExceptions;

  @Action('staticFile/getSignedUrlForUploads')
  public getSignedUrlForUploads!: (
    d: GetSignedUrlForUploadRequestPayload
  ) => Promise<GetSignedUrlForUploadResponsePayload>;

  @Prop() agreementFiles!: Array<
    File | Partial<GetSignedUrlForUploadResponsePayload>
  >;

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string,
    skipImplicitCheck?: boolean
  ): boolean {
    return this.isSettings
      ? isUserAllowed(
          action,
          module,
          EntityTypes.GROUP,
          this.groupTypeName,
          this.groupId,
          this.groupExceptions,
          skipImplicitCheck
        )
      : true;
  }

  get agreementFileTags() {
    return (
      this.agreementFiles?.map((f, index) => {
        const name =
          (f as File)?.name ||
          (f as GetSignedUrlForUploadResponsePayload)?.fileName;
        return {
          name,
          index
        };
      }) || []
    );
  }

  set agreementFileTags(tags) {
    const allIndex = tags.map((t) => t.index);
    const agreementFiles =
      this.agreementFiles?.filter((_, i) => allIndex.includes(i)) || null;
    this.$emit('update:agreementFiles', agreementFiles);
  }

  handleUploadFileBtn(e: Event) {
    // @ts-ignore
    const files: File[] = Array.from(e?.target?.files);
    const agreementFiles = [...(this.agreementFiles || []), ...files];
    this.$emit('update:agreementFiles', agreementFiles);
  }

  getName(file: GetSignedUrlForUploadResponsePayload) {
    return file?.storageUri?.split('/')?.pop();
  }

  handleFileUpload(file: File): Promise<GetSignedUrlForUploadResponsePayload> {
    const axios = this.axios;
    return retry(
      async () => {
        const {
          signedUrl,
          storageUrl,
          storageUri,
          fileName
        } = await this.getSignedUrlForUploads({
          fileName: file.name,
          contentType: file.type
        });

        const formData = new FormData();
        formData.append('file', file as Blob);

        return new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.open('PUT', signedUrl, true);

          xhr.onload = (e) => {
            // tslint:disable-next-line
            console.log(`Upload Completed for ${(file as File).name}`);
            return resolve({
              signedUrl,
              storageUrl,
              storageUri,
              fileName
            });
          };

          xhr.onerror = (error) => {
            return reject(error);
          };

          xhr.upload.onprogress = (e) => {
            if (e.lengthComputable) {
              const progress = (e.loaded / e.total) * 100;
              // tslint:disable-next-line
              console.log(
                `${progress}% Upload Progress for ${(file as File).name}`
              );
            }
          };

          xhr.send(file);
        });
      },
      {
        retries: 5
      }
    );
  }

  @Watch('agreementFiles')
  async onFilesChanged(
    files: Array<File | GetSignedUrlForUploadResponsePayload>
  ) {
    files.map(async (file, i) => {
      if (!(file instanceof File)) {
        return;
      }

      this.$set(
        this.agreementFiles,
        i,
        await this.handleFileUpload(file as File)
      );
    });
  }
}
