





















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ components: {} })
export default class GeneralModal extends Vue {
  @Prop()
  public modalTitle!: string;
  @Prop()
  public modalContent!: string;
  @Prop()
  public confirmBtnText!: string;
  @Prop()
  public cancelBtnText!: string;

  public confirm() {
    this.$emit('confirm');
    this.$emit('close');
  }
  public closeModal() {
    this.$emit('close');
  }
}
