










































































import { Action, State } from 'vuex-class';
import { Pagination } from 'nestjs-typeorm-paginate';
import { Component, Vue, Watch } from 'vue-property-decorator';

import TemplateList from './TemplateList.vue';
import { RootState } from '../../../../store/store';
import { SortOrder } from '../../../..//store/types/general.types';
import BaseLoading from '../../../../components/base/BaseLoading.vue';
import { Debounce } from '../../../../jbi-shared/util/debounce.vue-decorator';
import BasePaginatorHoc from '../../../../components/base/BasePaginatorHoc.vue';
import {
  GroupUserAttributeTypeStatus,
  MyjbiGroupUserAttributeSpec
} from '../../../../jbi-shared/types/myjbi-group.types';
import {
  GroupTemplateAttribute,
  AttributeValueType
} from '../../../../store/modules/admin/types/group-level-attribute.types';
import {
  GetGroupUserAttributeTemplatePayload,
  GroupUserAttributeTemplate,
  GroupUserAttributeTemplateUpStream
} from '../../../../store/modules/admin/types/group-user-attribute-template.types';

@Component({
  components: {
    BaseLoading,
    TemplateList,
    BasePaginatorHoc
  }
})
export default class UserAttributeTemplateModal extends Vue {
  public perPage = 50;
  public page = 1;
  public sortColumn: string = 'templateName';
  public sortOrder: SortOrder = SortOrder.ASC;
  filteredParams = '';
  selectedTemplateName: string = '';

  public allSelectedAttributes: MyjbiGroupUserAttributeSpec[] = [];

  /**
   * Action and state for getting the user attribute templates.
   */
  @Action('admin/getGroupUserAttributeTemplates')
  getGroupUserAttributeTemplates!: (
    options: GetGroupUserAttributeTemplatePayload
  ) => void;

  @State(
    (state: RootState) =>
      state.admin.apiState.getGroupUserAttributeTemplates.success
  )
  getGroupUserAttributeTemplatesApiSuccess!: boolean;

  @State(({ admin }: RootState) => admin.groupUserAttributeTemplates)
  groupUserAttributeTemplates!: Pagination<GroupUserAttributeTemplateUpStream>;

  get TemplateList() {
    return TemplateList;
  }

  get templates(): GroupUserAttributeTemplateUpStream[] {
    return this.groupUserAttributeTemplates
      ? this.groupUserAttributeTemplates?.items
      : [];
  }

  getRouteConfig() {
    return {
      name: 'admin-group-management',
      query: {
        tab: 'UserAttributesTemplate',
        templateName: this.filteredParams,
        limit: this.perPage.toString() || '50',
        page: this.page.toString() || '1',
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder || SortOrder.ASC
      }
    };
  }

  handleLinkClick(): void {
    this.closeModal();
  }

  selectedTemplate(template: GroupUserAttributeTemplate) {
    this.allSelectedAttributes = template.attributes.map((attr) => ({
      required: attr.required,
      lockAttribute: attr.lockAttribute,
      isDefault: false,
      groupUserAttribute: {
        groupUserAttributeType: attr.groupUserAttributeType,
        id: attr.id,
        name: attr.name,
        option: attr.option,
        slug: attr.slug || `${attr.id}_${attr.name}`,
        status: GroupUserAttributeTypeStatus.ACTIVE
      }
    }));

    this.selectedTemplateName = template.name;
  }

  getAttributeValueFromOption(
    attribute: GroupTemplateAttribute
  ): AttributeValueType {
    switch (attribute.groupLevelAttributeType.type) {
      case 'images':
      case 'documents':
        return [];
      default:
        return attribute.option;
    }
  }

  isButtonDisabled(): boolean {
    return this.allSelectedAttributes.length ? false : true;
  }

  useTemplate() {
    if (this.allSelectedAttributes.length) {
      this.$emit('addUserAttributeTemplate', this.allSelectedAttributes);
      this.$emit('updateTemplateName', this.selectedTemplateName);
      this.selectedTemplateName = '';
      this.closeModal();
    }
  }

  public handlePaginator({ perPage, page }: { perPage: number; page: number }) {
    this.perPage = perPage;
    this.page = page;
    this.getGroupUserAttributeTemplates({
      search: this.filteredParams,
      limit: this.perPage,
      page: this.page,
      sortOrder: this.sortOrder
    });
  }

  public handleSort(data: any) {
    this.sortColumn = data.sortColumn;
    this.sortOrder = data.sortOrder;

    this.getGroupUserAttributeTemplates({
      search: this.filteredParams,
      limit: this.perPage,
      page: this.page,
      sortOrder: this.sortOrder
    });
  }

  public handleFilter() {
    this.perPage = this.perPage;
    this.getGroupUserAttributeTemplates({
      search: this.filteredParams,
      limit: this.perPage,
      page: 1,
      sortOrder: this.sortOrder
    });
  }

  public mounted() {
    this.getGroupUserAttributeTemplates({
      limit: this.perPage
    });
  }

  closeModal() {
    this.$emit('close');
  }

  @Watch('filteredParams')
  @Debounce(600)
  public onChange() {
    this.handleFilter();
  }
}
