





















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import { isEqual as _isEqual } from 'lodash';
import { InputOption } from '@/jbi-shared/types/form.types';

@Component({
  // @ts-ignore
  components: {
    Multiselect,
  },
})
export default class BaseMultiSelect extends Vue {
  @Prop({ required: true })
  public value!: InputOption | InputOption[];
  @Prop({ type: Array, required: true })
  public options!: InputOption[];
  @Prop({ type: String, default: '' })
  public placeholder!: string;
  @Prop({ type: Boolean, default: false })
  public disabled!: string;
  @Prop(String) public openDirection!: string;
  @Prop(Number) public maxHeight!: number;

  get isMultiSelect() {
    return Array.isArray(this.selectedValue);
  }

  get isSingleSelect() {
    return !this.isMultiSelect;
  }

  get selectedValue(): InputOption | InputOption[] {
    return this.value;
  }

  set selectedValue(values: InputOption | InputOption[]) {
    if (this.isSingleSelect && !values) {
      return;
    }
    this.$emit('input', values);
  }
}
