








































import { Component, Vue, Prop } from 'vue-property-decorator';
import { MyjbiGroupUserAttributeSpec } from '@/jbi-shared/types/myjbi-group.types';
import { PermissionsMatrixActionsEnum } from '../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '../../../utils/rbac.util';
import { EntityTypes } from '../../../store/modules/module-tree/enums/module-tree.enums';
import { State } from 'vuex-class';
import { RootState } from '@/store/store';

/**
 * This component is only used for displaying props
 * propagated from AttributesList and "bubbling up" events.
 * No `v-model` is used here because ALL contextual data is
 * handled by AttributesList.
 *
 * Available events:
 * - `@toggleLock` and `@toggleRequired`
 * Tells parent to update spec, and re-render icons
 *
 * - `@addAttribute` and `@removeAttribute`
 * As the names suggest.
 */
@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {}
})
export default class AttributeRow extends Vue {
  @Prop() specItem!: MyjbiGroupUserAttributeSpec;
  @Prop({ default: false }) isSelected!: boolean;
  @Prop(Boolean) isRowDisabled!: boolean;
  @Prop({ default: false }) isSettings!: boolean;
  @Prop() groupTypeName!: string;
  @Prop() groupId!: number;

  // allows component consumer to supply predefined classes
  @Prop() classes!: string | string[];

  @State((state: RootState) => state.rbac.groupTypesUserHasAccessTo)
  public groupTypesUserHasAccessTo!: string[];

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string
  ): boolean {
    const instance = EntityTypes.GROUP + '_' + this.groupId;
    const groupTypeName = this.groupTypeName
      ? this.groupTypeName
      : this.groupTypesUserHasAccessTo[0];
    return this.isSettings
      ? isUserAllowed(
          action,
          module,
          EntityTypes.GROUP,
          groupTypeName,
          this.groupId
        )
      : true;
  }

  get classNames(): string {
    if (typeof this.classes === 'string') {
      return this.classes;
    }

    if (Array.isArray(this.classes)) {
      return this.classes.join(' ');
    }

    return '';
  }

  get lockIconClass(): string {
    const classes = [];

    if (this.specItem.lockAttribute === true) {
      classes.push('mdi-lock');
    } else {
      classes.push('mdi-lock-open-outline');
    }

    if (this.isRowDisabled) {
      classes.push('disabled');
    }

    return classes.join(' ');
  }

  /**
   * Returns a more helpful tooltip for the lock icon
   * to let users know what clicking the icon does.
   */
  get lockIconToolTip(): string {
    if (this.specItem.lockAttribute === true) {
      return 'Click to unlock attribute';
    }

    return 'Click to lock attribute';
  }

  /**
   * Image types cannot be required.
   * I haven't a clue why.
   * Please, update this comment if you know.
   */
  get isImageType(): boolean {
    return (
      this.specItem.groupUserAttribute.groupUserAttributeType.type === 'image'
    );
  }

  toggleLock(): void {
    if (this.isRowDisabled) {
      return;
    }

    this.specItem.lockAttribute = !this.specItem.lockAttribute;
    this.$emit('toggleLock', this.specItem);
  }

  toggleRequired(): void {
    if (this.isRowDisabled) {
      return;
    }

    if (this.isImageType) {
      return;
    }

    this.specItem.required = !this.specItem.required;
    this.$emit('toggleRequired', this.specItem);
  }

  updateSelected(
    item: MyjbiGroupUserAttributeSpec,
    checkboxValue: boolean
  ): void {
    if (this.isRowDisabled) {
      return;
    }

    if (checkboxValue === true) {
      this.$emit('addAttribute', item);
    } else {
      this.$emit('removeAttribute', item);
    }
  }
}
