












































import { uniq } from 'lodash';
import { Component, Vue, Watch, PropSync, Prop } from 'vue-property-decorator';
import { StringInputOption } from '../jbi-shared/types/form.types';
import { UserAttributesStringInputOption } from '../store/modules/admin/types/group.types';

@Component({})
export default class DropdownMultiselect extends Vue {
  @Prop(Array) value!: UserAttributesStringInputOption[];
  @Prop(Array) options!: UserAttributesStringInputOption[];
  @Prop(Array) fixedOptions!: UserAttributesStringInputOption[];
  @Prop(String) btnTxt!: string;
  @Prop(String) position!: 'is-top-right' | 'is-top-left' | 'is-bottom-left';
  @Prop(Array) triggers!: string[];
  @Prop({ default: true }) multi!: boolean;
  @Prop({ default: false }) disabled!: boolean;

  get selectedAttributes() {
    return this.value.map((o) => o.slug!);
  }

  set selectedAttributes(slug: string[]) {
    this.$emit(
      'input',
      this.options.filter((o) => slug.includes(o.slug!))
    );
  }

  toggleOption(slug: string) {
    if (!this.multi) {
      return (this.selectedAttributes = [slug]);
    }

    if (this.isSelected(slug)) {
      this.selectedAttributes = this.selectedAttributes.filter(
        (selectedSlug) => selectedSlug !== slug
      );
    } else {
      this.selectedAttributes = [...this.selectedAttributes, slug];
      this.selectedAttributes = uniq(this.selectedAttributes);
    }
  }

  isSelected(slug: string) {
    return this.selectedAttributes.find((sId) => sId === slug);
  }

  isFixed(slug: string) {
    return !!this.fixedOptions?.find((o) => o.slug === slug);
  }
}
