


































































































import { State } from 'vuex-class';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';

import TemplateView from './TemplateView.vue';
import { RootState } from '../../../../store/store';
import BaseTable from '../../../../components/BaseTable.vue';
import { SortOrder } from '../../../../store/types/general.types';
import BaseLoading from '../../../../components/base/BaseLoading.vue';
import BasePagination from '../../../../components/base/BasePagination.vue';
import { PaginationMixin } from '../../../../components/base/pagination.mixin';
import TableActionDropdown from '../../../../components/TableActionDropdown.vue';
import DropdownMultiselect from '../../../../components/DropdownMultiselect.vue';
import SortableTableHeader from '../../../../components/SortableTableHeader.vue';
import {
  GroupUserAttributeType,
  MyjbiGroupUserAttribute
} from '../../../../jbi-shared/types/myjbi-group.types';
import { TemplateAttributeDisplayCount } from '../../../../store/modules/admin/types/group-level-attribute.types';
import {
  GroupUserAttribute,
  GroupUserAttributeTemplate,
  GroupUserAttributeTemplateUpStream,
  GroupUserAttributeUpStream,
  Nullable,
  NullableAttributeType
} from '../../../../store/modules/admin/types/group-user-attribute-template.types';

@Component({
  components: {
    BaseTable,
    BaseLoading,
    TemplateView,
    BasePagination,
    SortableTableHeader,
    TableActionDropdown,
    DropdownMultiselect
  }
})
export default class TemplateList extends mixins(PaginationMixin) {
  @Prop() public items!: GroupUserAttributeTemplateUpStream[];
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop(String) public sortColumn!: string;
  @Prop(String) public sortOrder!: SortOrder.ASC | SortOrder.DESC;

  @State((state: RootState) => state.admin.groupUserAttributes)
  public groupUserAttributes!: MyjbiGroupUserAttribute[];

  public selectTemplate: Nullable<GroupUserAttributeTemplateUpStream> = null;

  get attributeDisplayCount(): number {
    return TemplateAttributeDisplayCount;
  }

  isViewMoreVisible(template: GroupUserAttributeTemplateUpStream): boolean {
    return template.attributes.length > this.attributeDisplayCount;
  }

  getViewMoreText(template: GroupUserAttributeTemplateUpStream): string {
    return `+${template.attributes.length - this.attributeDisplayCount} more`;
  }

  templateMapper(
    template: GroupUserAttributeTemplateUpStream
  ): Omit<GroupUserAttributeTemplate, 'attributes'> & {
    attributes: NullableAttributeType[];
  } {
    return {
      id: template.id,
      name: template.name,
      attributes: template.attributes.reduce<NullableAttributeType[]>(
        (acc, attr) => [
          ...acc,
          {
            ...attr,
            groupUserAttributeType:
              this.groupUserAttributes.find((gatr) => attr.id === gatr.id)
                ?.groupUserAttributeType || null,
            option:
              this.groupUserAttributes.find((gatr) => attr.id === gatr.id)
                ?.option || null,
            slug:
              this.groupUserAttributes.find((gatr) => attr.id === gatr.id)
                ?.slug || null
          }
        ],
        []
      )
    };
  }

  openTemplatesDetails(template: GroupUserAttributeTemplateUpStream) {
    this.$buefy.modal.open({
      parent: this,
      component: TemplateView,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: false,
      props: {
        template: this.templateMapper(template)
      }
    });
  }

  public onHeaderColumnClick(columnName: string) {
    if (this.sortColumn === columnName) {
      return this.$emit('sort', {
        sortColumn: columnName,
        sortOrder:
          this.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC
      });
    }

    this.$emit('sort', {
      sortColumn: columnName,
      sortOrder: SortOrder.DESC
    });
  }

  public getSortOrderOfColumn(name: string) {
    if (this.sortColumn === name) {
      return this.sortOrder;
    }
    return SortOrder.ASC;
  }

  /**
   * watcher functions
   */
  @Watch('selectTemplate')
  onSelect() {
    if (this.selectTemplate) {
      this.$emit('selectedTemplate', this.templateMapper(this.selectTemplate));
    }
  }
}
