




















import { Component, Prop, PropSync } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import CsvImportGuide from '@/components/CsvImportGuide.vue';
import { CsvXlxs } from '../../../utils/csv-xlxs.util';
import cuid from 'cuid';
import { MemberObject } from '../../../utils/group.util';
import { MyjbiGroupDetail } from '../../../jbi-shared/types/myjbi-group.types';
import dayjs from 'dayjs';

@Component({
  components: {
    CsvImportGuide
  }
})
export default class GroupCsvImportInfo extends mixins() {
  @Prop(Array) membersData!: MemberObject[];
  @Prop(Array) userAttributes!: MyjbiGroupDetail['groupUserAttributeSpecs'];
  @Prop(Boolean) shouldDownloadExistingMemberInCsv!: boolean;
  @Prop(String) csvFileName!: string;

  get requiredCsvColumns() {
    const arr = this.userAttributes
      ? this.userAttributes.map(
          (userAttr) => `<b>${userAttr.groupUserAttribute.name}</b>`
        )
      : '';

    let result = '';
    for (let i = 0; i < arr.length; i++) {
      const str = arr[i];
      result += str;
      if (i === arr.length - 2) {
        result += ' and ';
      } else if (i < arr.length - 2) {
        result += ', ';
      }
    }
    return result;
  }

  public downloadSampleXlsx() {
    CsvXlxs.downloadSampleXlsx(
      this.sampleWorkSheetData,
      this.csvFileName || 'sample',
      {
        Title: 'Bulk user import sample',
        Subject: 'Sample File',
        Author: 'Snappymob'
      }
    );
  }

  public downloadSampleCsv() {
    CsvXlxs.downloadSampleCsv(
      this.sampleWorkSheetData,
      this.csvFileName || 'sample'
    );
  }

  get sampleWorkSheetData() {
    const groupUserAttributes = this.userAttributes ? this.userAttributes : [];

    const header = groupUserAttributes.map(
      (attr) => attr.groupUserAttribute.name!
    );
    if (this.shouldDownloadExistingMemberInCsv) {
      const data: any[] = [];
      if (this.membersData && groupUserAttributes) {
        this.membersData?.map((member) => {
          return groupUserAttributes?.map((attr) => {
            return member[attr.groupUserAttribute.id!];
          });
        });
      }
      return [header, ...data];
    } else {
      const getData = () =>
        groupUserAttributes.map((userAttr) => {
          const {
            slug,
            name,
            groupUserAttributeType,
            option
          } = userAttr.groupUserAttribute;
          switch (groupUserAttributeType.type) {
            case 'email':
              return `sample${+new Date() + Math.random()}@mailinator.com`;
            case 'telephone':
              return `+${Math.floor(
                1000000000 + Math.random() * 900000000000
              )}`;
            case 'link':
              return `https://samplelink.example.com/${slug}/${cuid()}`;
            case 'date':
              return `${dayjs(new Date()).format('YYYY-MM-DD')}`;
            case 'list':
              return option?.multiSelect
                ? `sample option1, sample option2 (add comma separated options)`
                : `sample option (single option)`;
            case 'image':
              return `https://sampleimage.example.com/${slug}/${cuid()}`;
            default:
              return `sample ${name} ${cuid()}`;
          }
        });
      return [header, ...Array(5).fill(null).map(getData)];
    }
  }
}
