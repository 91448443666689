




















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { GroupLevelAttributeValue } from '../../../../store/modules/admin/types/group-level-attribute.types';
import AttributeButtons from './AttributeButtons.vue';
import DraggableIcon from '../GroupLevelAttributes/DraggableIcon.vue';
import { getHtmlContent } from '@/jbi-shared/util/group-level-attributes.util';

@Component({
  components: {
    AttributeButtons,
    DraggableIcon
  }
})
export default class GroupLevelAttributeTextField extends Vue {
  @Prop() attributeValue!: GroupLevelAttributeValue;
  @Prop() fieldClasses!: any;
  @Prop() duplicationError!: boolean;
  @Prop() updateAttribute!: (attribute: GroupLevelAttributeValue) => void;
  @Prop() deleteAttribute!: () => void;
  showField: boolean = false;
  labelErrorMsg: string = '';
  fieldErrorMsg: string = '';

  mounted() {
    this.validateLabel();
    this.validateRequiredFieldValues();

    /**
     * Workaround to override Quill's default link field placeholder in tooltip
     * https://github.com/quilljs/quill/issues/1107#issuecomment-259938173
     */
    const linkTooltip = document.querySelector('.ql-snow .ql-tooltip');
    const urlInput = (linkTooltip as any).querySelector('input[data-link]');

    if (urlInput) {
      urlInput.dataset.link = 'URL with https://';
    }
  }

  get editorOption() {
    return {
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'link'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }]
        ]
      },
      placeholder: 'Ctrl/Cmd + Shift + V to paste without formatting'
    };
  }

  /**
   * Validates attribute label content.
   * Displays label field if any error present.
   */
  validateLabel(): void {
    if (this.attributeValue.label === '') {
      this.labelErrorMsg = 'Label cannot be empty.';
      this.attributeValue.hasFieldError = true;
      this.showField = true;
    } else if (this.duplicationError) {
      this.labelErrorMsg = `Duplicated label '${this.attributeValue.label}'.`;
      this.attributeValue.hasFieldError = true;
      this.showField = true;
    } else {
      this.attributeValue.hasFieldError = false;
      this.labelErrorMsg = '';
    }
  }

  hideLabelAndValidate(event: any): void {
    this.validateLabel();
    this.validateRequiredFieldValues();

    // hide field on enter, esc, or blur + no error
    if (event.keyCode === 13 || event.keyCode === 27 || event.type === 'blur') {
      if (this.labelErrorMsg.length === 0) {
        this.showField = false;
      }
    }
  }

  /**
   * Add a separate watcher for "hasDuplicationError" property
   * because this property is updated by parent.
   */
  @Watch('duplicationError')
  duplicationCallback(currentError: boolean, previousError: boolean): void {
    this.validateLabel();
    this.updateAttribute(this.attributeValue);
  }

  @Watch('attributeValue.value')
  validateRequiredFieldValues() {
    // validate null / empty values for required fields
    if (
      this.attributeValue.isRequired &&
      getHtmlContent(
        this.attributeValue.value ? (this.attributeValue.value as string) : ''
      ).length === 0
    ) {
      this.fieldErrorMsg = 'Required field cannot be empty.';
      this.attributeValue.hasFieldError = true;
      this.updateAttribute(this.attributeValue);
      return;
    }

    this.fieldErrorMsg = '';
    this.attributeValue.hasFieldError = false;
    this.updateAttribute(this.attributeValue);
  }
}
