







































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import {
  GetUploadImageSignedUrlPayload,
  GetUploadImageSignedUrlResponse,
  ImageFileSizeLimit
} from '../../../../store/modules/admin/types/group-user-attribute.types';
import { ToastProgrammatic as Toast } from 'buefy';
import BaseLoading from '../../../../components/base/BaseLoading.vue';
import { RootState } from '../../../../store/store';

@Component({
  components: { BaseLoading }
})
export default class ImageUploadComponent extends Vue {
  @Prop(String) imageSrc!: string;
  @Prop(Boolean) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) isCurrentUserUsage!: boolean;

  @Action('admin/getUploadImageSignedUrl')
  public getUploadImageSignedUrl!: (
    payload: GetUploadImageSignedUrlPayload
  ) => Promise<GetUploadImageSignedUrlResponse>;

  @Action('admin/getCurrentUserUploadImageSignedUrl')
  public getCurrentUserUploadImageSignedUrl!: (
    payload: GetUploadImageSignedUrlPayload
  ) => Promise<GetUploadImageSignedUrlResponse>;

  @State(
    (state: RootState) => state.admin.groupUserAttributeUploadImageSignedUrl
  )
  public groupUserAttributeUploadImageSignedUrl!: GetUploadImageSignedUrlResponse;

  @State(
    (state: RootState) =>
      state.admin.apiState.getGroupUserAttributeUploadImageSignedUrl.success
  )
  public groupUserAttributeUploadImageSignedUrlSuccess!: boolean;

  imageFileToUpload: File | null = null;
  getImageUrl: string = this.imageSrc ? this.imageSrc : '';
  isLoading: boolean = false;

  public removeImage(): void {
    if (!this.disabled) {
      this.getImageUrl = '';
      this.$emit('getImage', this.getImageUrl);
    }
  }

  public async handleUploadImage(imageFile: File) {
    this.isLoading = true;
    this.$emit('isUploading', true);
    try {
      if (imageFile.size > ImageFileSizeLimit) {
        this.isLoading = false;
        this.$emit('isUploading', false);
        return Toast.open({
          message: `Image '${imageFile.name}' has exceeded size limitation.`,
          type: 'is-danger',
          position: 'is-top'
        });
      }
      const payload = {
        fileName: imageFile.name,
        contentType: imageFile.type
      };

      /**
       * This API is not achievable using Vuex state, because it's a shared component,
       * and it might have the same component rendered concurently, and it might cause
       * some state watching issue. Because if one of the component are calling this API
       * when the API success / failed, it will also trigger the watcher from another
       * same component.
       */
      const signedProfileImageUrls = await (this.isCurrentUserUsage
        ? this.getCurrentUserUploadImageSignedUrl(payload)
        : this.getUploadImageSignedUrl(payload));

      await this.uploadToStorageUrl(imageFile, signedProfileImageUrls);
    } catch (error) {
      this.isLoading = false;
      this.$emit('isUploading', false);
      return Toast.open({
        message: 'Upload failed. Please try again.',
        type: 'is-danger',
        position: 'is-top'
      });
    }
  }

  async uploadToStorageUrl(
    imageFile: File,
    signedUrls: GetUploadImageSignedUrlResponse
  ) {
    try {
      const formData = new FormData();
      formData.append('file', imageFile as Blob);

      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', signedUrls.signedUrl, true);

        xhr.onload = (e) => {
          this.getImageUrl = signedUrls.storageUrl;
          this.isLoading = false;
          this.$emit('getImage', signedUrls.storageUrl);
          this.$emit('isUploading', false);
          return resolve(signedUrls);
        };

        xhr.onerror = (error) => {
          return reject(error);
        };

        xhr.send(imageFile);
      });
    } catch (error) {
      this.isLoading = false;
      this.$emit('isUploading', false);
      return Toast.open({
        message: 'Upload failed. Please try again.',
        type: 'is-danger',
        position: 'is-top'
      });
    }
  }

  @Watch('imageSrc')
  onImageSrcChange() {
    this.getImageUrl = this.imageSrc;
  }

  // @Watch('groupUserAttributeUploadImageSignedUrlSuccess')
  // @isTruthy
  // public watchGroupUserAttributeUploadImageSignedUrlSuccess() {
  //   this.uploadToStorageUrl(
  //     this.imageFileToUpload as File,
  //     this.groupUserAttributeUploadImageSignedUrl,
  //   );
  // }
}
