




































































import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import BaseMultiSelect from '@/jbi-shared/vue-components/BaseMultiSelect.vue';
import { StringInputOption } from '../../../jbi-shared/types/form.types';
import DropdownMultiselect from '@/components/DropdownMultiselect.vue';
import { State } from 'vuex-class';
import { RootState } from '@/store/store';

@Component({
  components: {
    BaseMultiSelect,
    ValidationProvider,
    DropdownMultiselect
  }
})
export default class GroupNameAndTypeForm extends Vue {
  @PropSync('name', { type: String }) syncedName!: string;
  @PropSync('type', { type: Object }) syncedTypes!: StringInputOption;
  @Prop(Array) typeOptions!: StringInputOption[];
  @Prop(Boolean) isProtected!: boolean;
  filteredGroupTypes: StringInputOption[] = [];

  @State(({ rbac }: RootState) => rbac.roles)
  private roles!: string[];

  @State(({ rbac }: RootState) => rbac.groupTypesUserHasAccessTo)
  private groupTypesUserHasAccessTo!: string[];

  mounted() {
    this.filterGroupTypesBasedOnAccess();
  }

  filterGroupTypesBasedOnAccess() {
    // If user is admin or has full access, do nothing
    if (
      !this.groupTypesUserHasAccessTo ||
      this.groupTypesUserHasAccessTo.length === 0 ||
      this.roles.includes('super-admin-role')
    ) {
      this.filteredGroupTypes = this.typeOptions;
      return;
    }

    this.filteredGroupTypes = this.filterBySluggifiedLabels(
      this.typeOptions,
      this.groupTypesUserHasAccessTo
    );
  }

  private filterBySluggifiedLabels(
    data: StringInputOption[],
    groupTypesUserHasAccessTo: string[]
  ): StringInputOption[] {
    // Normalize the labels
    const normalizedLabels = groupTypesUserHasAccessTo.map((label) =>
      label.toLowerCase().replace(' ', '_')
    );
    return data?.filter(
      (item) =>
        item.name &&
        normalizedLabels.includes(item.name.toLowerCase().replace(' ', '_'))
    );
  }
}
