


































































































































































































import { Vue, Component, Prop, Watch, PropSync } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import {
  ToastProgrammatic as Toast,
  DialogProgrammatic as Dialog
} from 'buefy';
import { cloneDeep } from 'lodash';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  isValidGroupUserAttributeValue,
  MemberObject
} from '../../../utils/group.util';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { getHtmlContent } from '@/jbi-shared/util/group-level-attributes.util';
import { ExportGroupMembersCsvPayload } from '@/store/modules/admin/types/admin.types';
import { RootState } from '@/store/store';
import {
  isContact,
  isEmail,
  isLink,
  validateEmailDomain
} from '../../../jbi-shared/util/validate-email-domains.util';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import { debounce as _debounce, uniqBy } from 'lodash';
import { MyjbiGroupUserAttributeSpec } from '../../../jbi-shared/types/myjbi-group.types';
import ImageUploadComponent from './UserLevelAttributes/ImageUploadComponent.vue';
import ListDropdownComponent from './UserLevelAttributes/ListDropdownComponent.vue';
import RichTextEditorForm from './UserLevelAttributes/RichTextEditorForm.vue';

export interface MemberParams {
  isRequired: boolean;
  isValid: boolean;
  value: string;
  errorMessage: string;
}

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    ImageUploadComponent,
    ListDropdownComponent
  }
})
export default class EditableMemberTable extends Vue {
  @Prop(String) fileName!: number;
  @Prop(Number) successImport!: number;
  @Prop(Number) failedImport!: number;
  @Prop(Array) membersData!: MemberObject[];
  @PropSync('notify', Boolean) syncedNotify!: boolean;
  @Prop({ type: Boolean, default: true }) showNotify!: boolean;
  @Prop({ type: Boolean, default: false }) isMemberDataValid!: boolean;
  @Prop(Array) userAttributes!: MyjbiGroupUserAttributeSpec[];
  @Prop(Array) allowedEmailDomains!: string[];
  // @PropSync('allowedEmailDomains', Array) syncedAllowedEmailDomains!: [];

  isDownloading = false;
  public syncedMembersData: MemberObject[] = [];
  syncedListOptions: Array<{ values: string[] }> = [];
  @Action('admin/exportCsvGroupMembersList')
  public exportCsvGroupMembersList!: (
    params: ExportGroupMembersCsvPayload
  ) => Promise<any>;

  public removeImportedUser(i: number) {
    const msg = 'Are you sure you want to remove this imported user?';
    return Dialog.confirm({
      message: msg,
      confirmText: 'Confirm',
      type: 'is-primary',
      onConfirm: async () => {
        this.$emit('removedMembers', this.syncedMembersData[i].email.value);
        this.syncedMembersData.splice(i, 1);
      }
    });
  }

  get groupDetail() {
    return (this.$store.state as RootState).admin.groupDetail;
  }

  get getCurrentRoute() {
    return this.$route.name?.includes('settings');
  }
  get groupId() {
    return +this.$route.params.groupId;
  }

  getImage(imageUrl: string, index: number, slug: string) {
    this.syncedMembersData[index][slug].value = imageUrl;
    this.validateField(imageUrl, index, slug);
  }

  isUploading(status: boolean, index: number, slug: string) {
    this.syncedMembersData[index][slug].isValid = !status;
  }

  getListValue(listValues: string, index: number, slug: string) {
    this.syncedMembersData[index][slug].value = listValues;
    this.validateField(listValues, index, slug);
  }

  removeDate(index: number, slug: string) {
    this.syncedMembersData[index][slug].value = null;
    this.validateField(this.syncedMembersData[index][slug].value, index, slug);
  }

  public async handleDownloadCsv() {
    this.isDownloading = true;

    if (!this.groupDetail) {
      this.isDownloading = false;
      return;
    }
    const result: ExportGroupMembersCsvPayload = {
      id: this.groupId,
      parentsName: this.groupDetail?.parentsName
    };
    await this.exportCsvGroupMembersList(result);
    this.isDownloading = false;
  }

  renderTextAreaContent(memberIndex: number, attributeSlug: string): string {
    const attributeValue = this.syncedMembersData[memberIndex][attributeSlug]
      .value;
    return getHtmlContent(attributeValue || '');
  }

  openRichTextEditor(
    memberIndex: number,
    required: boolean,
    attributeLabel: string,
    attributeSlug: string
  ): void {
    const attributeValue: string =
      this.syncedMembersData[memberIndex][attributeSlug].value || '';
    const email: string = this.syncedMembersData[memberIndex].email.value || '';

    this.$buefy.modal.open({
      parent: this,
      component: RichTextEditorForm,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: false,
      canCancel: true,
      props: {
        htmlString: attributeValue,
        label: attributeLabel,
        email,
        required
      },
      events: {
        updateHtml: (updatedData: string) => {
          this.syncedMembersData[memberIndex][
            attributeSlug
          ].value = updatedData;

          this.validateField(
            this.syncedMembersData[memberIndex][attributeSlug].value,
            memberIndex,
            attributeSlug
          );
        }
      }
    });
  }

  @Watch('syncedMembersData', { deep: true })
  @Debounce(600)
  public watchSyncedMembersData() {
    this.checkMembersData();
    this.$emit('update:membersData', this.syncedMembersData);
  }

  @Watch('membersData', { deep: true })
  public watchMembersData() {
    const uniqueMembersData = uniqBy(this.membersData, 'email.value');
    this.syncedMembersData = [...uniqueMembersData];
  }

  @Watch('allowedEmailDomains')
  public onEmailDomainChange() {
    this.syncedMembersData.map((memberData, index) => {
      const { value } = memberData.email;
      if (
        isEmail(value) &&
        validateEmailDomain(value, this.allowedEmailDomains)
      ) {
        return (this.syncedMembersData[index].email.isValid = true);
      } else {
        this.$emit('update:isMemberDataValid', false);
        this.syncedMembersData[index].email.errorMessage = 'Invalid Email';
        return (this.syncedMembersData[index].email.isValid = false);
      }
    });
  }

  @isDifferent
  @isTruthy
  @Watch('isDownloading')
  public handleDownloadMembersList() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Downloading members list...`
    });
  }

  public sanitizeMembersData() {
    this.membersData = this.membersData.map((user: MemberObject) => {
      const arrayOfKeys = Object.keys(user);
      const attributeArray = this.userAttributes.map(
        (userAttr) => userAttr.groupUserAttribute.slug
      );

      arrayOfKeys
        .filter((userKeys) => !attributeArray.includes(userKeys))
        .map((keys) => {
          delete user[keys];
        });
      return user;
    });
  }

  public validateField(value: string, index: number, key: string) {
    const attribute = this.userAttributes.find(
      (attr) => attr.groupUserAttribute.slug === key
    );
    if (attribute) {
      const users = this.syncedMembersData;
      if (!(key in users[index])) {
        users[index][key] = {
          value: users[index][key]?.value ? users[index][key].value : null,
          isRequired: attribute.required ? true : false,
          isValid: true,
          errorMessage: null
        };
      }
      if (attribute.groupUserAttribute.groupUserAttributeType.type === 'date') {
        users[index][key].value = users[index][key]?.value
          ? new Date(users[index][key].value)
          : null;
      }

      const { isValid, errorMessage } = isValidGroupUserAttributeValue(
        attribute,
        users[index][key].value,
        this.allowedEmailDomains
      );
      this.syncedMembersData[index][key].isValid = isValid;
      this.syncedMembersData[index][key].errorMessage = errorMessage;
    } else {
      this.syncedMembersData[index][key].isValid = true;
      this.syncedMembersData[index][key].errorMessage = '';
    }
  }

  public checkMembersData(): boolean {
    const isValid: boolean = this.syncedMembersData.every(
      (member: MemberObject) => {
        const memberDataIsValid = Object.values(member).every(
          (values: MemberObject) => {
            return values.isValid;
          }
        );
        return memberDataIsValid;
      }
    );
    this.$emit('update:isMemberDataValid', isValid);
    return isValid;
  }

  public mounted() {
    this.syncedMembersData = cloneDeep(this.membersData);
    this.syncedMembersData = this.syncedMembersData.map(
      (user: MemberObject) => {
        this.userAttributes.map((attribute) => {
          const { slug, groupUserAttributeType } = attribute.groupUserAttribute;
          if (groupUserAttributeType.type === 'date') {
            user[slug].value = user[slug]?.value
              ? new Date(user[slug].value)
              : null;
          }
        });
        return user;
      }
    );
  }
}
