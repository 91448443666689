


















































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { RootState } from '../../../../store/store';
import {
  GroupUserAttributePayload,
  NewGroupUserAttributeResponse
} from '../../../../store/modules/admin/types/group-user-attribute.types';
import { isTruthy } from '../../../../jbi-shared/util/watcher.vue-decorator';
import {
  GroupUserAttributeOption,
  GroupUserAttributeType,
  GroupUserAttributeTypeStatus
} from '../../../../jbi-shared/types/myjbi-group.types';
import { ToastProgrammatic as Toast } from 'buefy';
import { ApiState } from '../../../../store/types/general.types';
import { Debounce } from '../../../../jbi-shared/util/debounce.vue-decorator';

@Component({})
export default class CreateNewAttribute extends Vue {
  @Action('admin/getGroupUserAttributeTypes')
  public getGroupUserAttributeTypes!: () => void;

  @State(({ admin }: RootState) => admin.groupUserAttributeTypes)
  public allGroupUserAttributeTypes!: GroupUserAttributeType[] | undefined;

  defaultAttribute: GroupUserAttributeType = {
    id: 0,
    type: '',
    option: null,
    status: GroupUserAttributeTypeStatus.ACTIVE
  };
  isDuplicateAttributeFound: boolean = false;
  disableSave: boolean = true;
  isRequired: boolean = false;
  attributeLabelName: string = '';
  selectedAttributeType: GroupUserAttributeType = this.defaultAttribute;
  selectedTypeOption: GroupUserAttributeOption | null = null;
  isMultiSelectList: boolean = false;
  attributeOptionValues: string[] = Array(2);
  attributeOptionErrorIndexes: number[] = [];
  lockAttribute: boolean = false;

  @Action('admin/createGroupUserAttribute')
  public createGroupUserAttribute!: (
    payload: GroupUserAttributePayload
  ) => void;

  @Action('admin/verifyGroupUserAttribute')
  public verifyGroupUserAttribute!: (attributeLabel: string) => void;

  @State((state: RootState) => state.admin.newGroupUserAttribute)
  public newGroupUserAttribute!: NewGroupUserAttributeResponse;

  @State((state: RootState) => state.admin.apiState.createGroupUserAttribute)
  public createGroupUserAttributeState!: ApiState;

  @State((state: RootState) => state.admin.verifyGroupUserAttribute)
  public getVerifyGroupUserAttribute!: boolean;

  @State(
    (state: RootState) => state.admin.apiState.verifyGroupUserAttribute.success
  )
  public getVerifyGroupUserAttributeSuccess!: boolean;

  mounted() {
    this.getGroupUserAttributeTypes();
  }

  isButtonDisabled(): boolean {
    const isInvalidLabel: boolean =
      this.attributeLabelName.length < 1 ||
      this.isDuplicateAttributeFound ||
      this.getVerifyGroupUserAttribute === undefined ||
      this.disableSave;

    if (this.selectedAttributeType.type === 'list') {
      return (
        isInvalidLabel ||
        this.selectedTypeOption === null ||
        this.attributeOptionErrorIndexes.length > 0 ||
        !this.getAttributeOptionValues().length
      );
    }
    return isInvalidLabel || this.selectedAttributeType.type === '';
  }

  @Debounce(800)
  validateLabelAndType() {
    if (
      this.attributeLabelName.length >= 1 &&
      this.selectedAttributeType.id !== 0
    ) {
      this.verifyGroupUserAttribute(this.attributeLabelName);
    } else {
      this.isDuplicateAttributeFound = false;
    }
  }

  getAttributeOptionValues() {
    return this.attributeOptionValues.filter((value) => value.length);
  }

  checkAttributeOptionDuplicateValues() {
    this.attributeOptionErrorIndexes = [];
    this.attributeOptionValues.forEach((item, index) => {
      if (item.trim() && this.attributeOptionValues.indexOf(item) !== index) {
        this.attributeOptionErrorIndexes.push(index);
      }
    });
  }

  createAttribute() {
    const newGroupUserAttribute: GroupUserAttributePayload = {
      label: this.attributeLabelName,
      status: GroupUserAttributeTypeStatus.ACTIVE,
      option: this.selectedTypeOption
        ? ({ ...this.selectedTypeOption } as GroupUserAttributeOption)
        : null,
      groupUserAttributeType: this.selectedAttributeType
    };
    if (newGroupUserAttribute.groupUserAttributeType.type === 'list') {
      ((newGroupUserAttribute.option as GroupUserAttributeOption)
        .value as string[]) =
        newGroupUserAttribute.option && this.selectedTypeOption
          ? this.getAttributeOptionValues()
          : [];
    }
    this.createGroupUserAttribute(newGroupUserAttribute);
  }

  removeOptionListItem(event: any, identifierClass: string, itemIndex: number) {
    if (event.target.parentElement.className.includes(identifierClass)) {
      this.attributeOptionValues.splice(itemIndex, 1);
      this.checkAttributeOptionDuplicateValues();
    }
  }

  onUseLabel(): void {
    this.disableSave = false;
    this.isDuplicateAttributeFound = false;
  }

  addOptionListItem() {
    this.attributeOptionValues.push('');
  }

  closeModal() {
    this.$emit('close');
  }

  @Watch('attributeLabelName')
  @Watch('selectedAttributeType')
  public onChangeAttributeLabelName() {
    this.isDuplicateAttributeFound = false; // clear debounced state
    this.disableSave = true;
    this.validateLabelAndType();
  }

  @Watch('getVerifyGroupUserAttributeSuccess')
  @isTruthy
  public watchVerifyGroupUserAttributeSuccess() {
    if (this.getVerifyGroupUserAttribute) {
      this.isDuplicateAttributeFound = true;
    } else {
      this.onUseLabel();
    }
  }

  @Watch('createGroupUserAttributeState', { deep: true })
  public createGroupUserAttributeApiState(state: ApiState) {
    if (state.success) {
      Toast.open({
        message: 'User Attribute Created Successfully.',
        type: 'is-dark',
        position: 'is-top'
      });

      /**
       * FIXME:
       * Either flatten structure (so the new attribute is not nested),
       * or, house the attribute specs separately.
       */
      this.$emit('addNewAttribute', {
        groupUserAttribute: this.newGroupUserAttribute.group_user_attribute,
        required: this.isRequired,
        lockAttribute: this.lockAttribute,
        isDefault: false // only email, first and last name default
      });

      this.closeModal();
    }

    if (state.error) {
      Toast.open({
        message: 'Something went wrong, try again.',
        type: 'is-danger',
        position: 'is-top'
      });

      this.isDuplicateAttributeFound = false;
      this.isRequired = false;
      this.attributeLabelName = '';
      this.selectedAttributeType = this.defaultAttribute;
      this.selectedTypeOption = null;
      this.isMultiSelectList = false;
      this.attributeOptionValues = Array(2);
    }
  }
}
