
































import { Component, Vue, Prop } from 'vue-property-decorator';
import { getHtmlContent } from '@/jbi-shared/util/group-level-attributes.util';

@Component({})
export default class RichTextEditorForm extends Vue {
  @Prop() htmlString!: string;
  @Prop() label!: string;
  @Prop() email!: string;
  @Prop() required!: boolean;

  content: string = '';

  mounted() {
    this.content = this.htmlString;
  }

  get editorOption() {
    return {
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'link'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }]
        ]
      },
      placeholder: 'Ctrl/Cmd + Shift + V to paste without formatting'
    };
  }

  requiredAttributeIsEmpty(): boolean {
    return this.required && getHtmlContent(this.content).length === 0;
  }

  updateValue(): void {
    this.$emit('updateHtml', this.content);
    this.closeModal();
  }

  closeModal(): void {
    this.$emit('close');
  }
}
