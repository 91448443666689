















































import { Component, Vue, Prop } from 'vue-property-decorator';
import isValidDomain from 'is-valid-domain';
import { ToastProgrammatic as Toast } from 'buefy';
import { remove as _remove, cloneDeep as _cloneDeep } from 'lodash';
import RemoveEmailDomainModal from './RemoveEmailDomainModal.vue';
import GeneralModal from '../../../components/GeneralModal.vue';

@Component({
  components: {}
})
export default class EmailDomainForm extends Vue {
  @Prop() allowedEmailDomains!: string[];
  @Prop() initialDomains!: string[];
  @Prop() isMaster!: boolean;
  @Prop() parentsName!: any;
  @Prop({ type: Boolean, default: false }) isSettings!: boolean;
  @Prop() isAllowedToEdit: boolean = false;
  public notify: boolean = false;

  public emailDomains: string[] = [];
  public existingEmailDomains: string[] = [];

  public confirmDeleteDomain(tag: string) {
    this.emailDomains = [...this.emailDomains];
    const domainIndex = this.emailDomains.findIndex(
      (domain) => tag.toLowerCase() === domain
    );

    if (this.existingEmailDomains.includes(tag)) {
      this.$buefy.modal.open({
        parent: this,
        component: RemoveEmailDomainModal,
        hasModalCard: true,
        trapFocus: true,
        width: 550,
        props: {
          domain: tag,
          domains: this.emailDomains,
          notify: this.notify,
          parentsName: this.parentsName,
          removeMembers: !this.isLastDomain(tag),
          addFirstDomain: this.isFirstDomain
        },
        events: {
          submit: (notify: boolean) => {
            this.emailDomains.splice(domainIndex, 1);
            this.$emit('removeEmailDomain', { tag, notify });
          }
        }
      });
    } else {
      this.emailDomains.splice(domainIndex, 1);
      this.$emit('removeEmailDomain', {
        tag: tag.trim().toLowerCase(),
        notify: false
      });
    }
  }

  public removeAllDomains() {
    this.$buefy.modal.open({
      parent: this,
      component: GeneralModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Clear all email domain?',
        modalContent:
          'Clearing all email domains will not remove any members from the list.',
        confirmBtnText: 'Clear',
        cancelBtnText: 'Cancel'
      },
      events: {
        confirm: () => {
          this.emailDomains = [];
          // setting null to indicate delete all domains
          this.$emit('removeEmailDomain', { tag: null, notify: false });
        }
      }
    });
  }

  public beforeAdding(tag: string) {
    if (
      isValidDomain(tag) &&
      this.emailDomains.indexOf(tag.toLowerCase()) === -1
    ) {
      return tag.toLowerCase();
    } else {
      Toast.open({
        message: 'Invalid domain added',
        type: 'is-danger',
        position: 'is-top'
      });
    }
  }

  public isLastDomain(tag: string): boolean {
    // check if the target domain (tag) to remove is the only one in the array
    const tagIndex = this.emailDomains.indexOf(tag);
    if (this.emailDomains.length === 1 && tagIndex === 0) {
      return true;
    } else {
      return false;
    }
  }

  public deleteDomainCreateGroup(tag: string) {
    this.emailDomains = this.emailDomains.filter(
      (domain) => domain !== tag.toLowerCase()
    );
    this.$emit('removeEmailDomain', {
      tag: tag.trim().toLowerCase()
    });
  }

  public addEmailDomain(tag: string) {
    this.emailDomains = [...this.emailDomains, tag];
    this.$emit('addEmailDomain', tag);
  }

  public mounted() {
    this.emailDomains = _cloneDeep(this.allowedEmailDomains);
    this.existingEmailDomains = [...this.allowedEmailDomains];
  }

  get isFirstDomain(): boolean {
    return this.initialDomains.length === 0 && this.emailDomains.length > 0;
  }

  get confirmKeys(): string[] {
    return [' '];
  }
}
