









































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { GroupTemplate } from '../../../../store/modules/admin/types/group-level-attribute.types';
import BaseTable from '@/components/BaseTable.vue';

@Component({
  components: {
    BaseTable
  }
})
export default class TemplateView extends Vue {
  @Prop() template!: GroupTemplate;

  closeModal() {
    this.$emit('close');
  }
}
