
























































import { Component, Vue, Watch, PropSync, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import BaseMultiSelect from '@/jbi-shared/vue-components/BaseMultiSelect.vue';
import { StringInputOption } from '../../../jbi-shared/types/form.types';
import DropdownMultiselect from '@/components/DropdownMultiselect.vue';
import NewAttributeModal from '@/views/AdminCreateGroup/components/NewAttributeModal.vue';
import _slugify from 'slugify';
import { ToastProgrammatic } from 'buefy';
import { UserAttributesStringInputOption } from '@/store/modules/admin/types/group.types';

const slugify = (str: string) =>
  _slugify(str, {
    strict: true,
    lower: true,
    locale: 'en'
  });

@Component({
  components: {
    BaseMultiSelect,
    ValidationProvider,
    DropdownMultiselect
  }
})
export default class GroupAttributeForm extends Vue {
  @PropSync('attributes', { type: Array })
  syncedAttributes!: UserAttributesStringInputOption[];
  @PropSync('requiredAttributes', { type: Array })
  syncedRequiredAttributes!: UserAttributesStringInputOption;

  @Prop(Boolean) isProtected!: boolean;
  @Prop(Array) attributeOptions!: UserAttributesStringInputOption[];
  @Prop(Array) requiredAttributeOptions!: UserAttributesStringInputOption[];
  @PropSync('attributeOptions', Array)
  syncedAttributeOptions!: UserAttributesStringInputOption[];
  @Prop(Array) fixedAttributes!: UserAttributesStringInputOption[];
  @Prop(Array) fixedRequiredAttributes!: UserAttributesStringInputOption[];

  get selectedAttributes() {
    return (
      this.syncedAttributes.map((o) => o.name).join(', ') ||
      `0 Selected User Attribute(s)`
    );
  }

  public async handleCreateNewAttribute() {
    this.$buefy.modal.open({
      parent: this.$refs.attributesDropdown as Vue,
      component: NewAttributeModal,
      hasModalCard: true,
      trapFocus: true,
      canCancel: false,
      events: {
        submit: this.handleNewAttributeSubmission.bind(this)
      }
    });
  }

  handleNewAttributeSubmission(newAttribute: string) {
    try {
      newAttribute = newAttribute.trim();
      const newOption = { slug: slugify(newAttribute), name: newAttribute };
      const isConflicted = this.syncedAttributeOptions.some(
        (o) => o.name === newOption.name || o.slug === newOption.slug
      );
      if (isConflicted) {
        throw new Error(`"${newAttribute}" is an existing attribute`);
      }
      this.syncedAttributeOptions = [...this.syncedAttributeOptions, newOption];
      this.syncedAttributes = [...this.syncedAttributes, newOption];
    } catch (error) {
      ToastProgrammatic.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: error?.response?.data?.message || error,
        duration: 5000
      });
    }
  }
}
