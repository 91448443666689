





















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { GroupLevelAttributeValue } from '../../../../store/modules/admin/types/group-level-attribute.types';
import { isEmail } from '../../../../../src/jbi-shared/util/validate-email-domains.util';
import AttributeButtons from './AttributeButtons.vue';
import DraggableIcon from '../GroupLevelAttributes/DraggableIcon.vue';

@Component({
  components: {
    AttributeButtons,
    DraggableIcon
  }
})
export default class GroupLevelAttributeEmailField extends Vue {
  @Prop() attributeValue!: GroupLevelAttributeValue;
  @Prop() fieldClasses!: any;
  @Prop() duplicationError!: boolean;
  @Prop() deleteAttribute!: () => void;
  @Prop() updateAttribute!: (attribute: GroupLevelAttributeValue) => void;
  showField: boolean = false;
  labelErrorMsg: string = '';
  fieldErrorMsg: string = '';
  isEmailFormatCorrect: boolean = true;

  mounted() {
    this.validateLabel();
    this.validateEmailFormat();
  }

  validateEmailFormat(): void {
    if (this.attributeValue.isRequired && !this.attributeValue.value) {
      this.fieldErrorMsg = 'Required field cannot be empty.';
      this.attributeValue.hasFieldError = true;
      this.updateAttribute(this.attributeValue);
      return;
    } else if (this.attributeValue.value) {
      // run validator when there's value
      this.isEmailFormatCorrect = isEmail(this.attributeValue.value as string);
    }

    if (!this.isEmailFormatCorrect) {
      this.fieldErrorMsg = 'Invalid email format.';
      this.attributeValue.hasFieldError = true;
    } else {
      this.fieldErrorMsg = '';
      this.attributeValue.hasFieldError = false;
    }

    this.updateAttribute(this.attributeValue);
  }

  /**
   * Validates attribute label content.
   * Displays label field if any error present.
   */
  validateLabel(): void {
    if (this.attributeValue.label === '') {
      this.labelErrorMsg = 'Label cannot be empty.';
      this.attributeValue.hasFieldError = true;
      this.showField = true;
    } else if (this.duplicationError) {
      this.labelErrorMsg = `Duplicated label '${this.attributeValue.label}'.`;
      this.attributeValue.hasFieldError = true;
      this.showField = true;
    } else {
      this.attributeValue.hasFieldError = false;
      this.labelErrorMsg = '';
    }
  }

  hideLabelAndValidate(event: any): void {
    this.validateLabel();
    this.validateEmailFormat();

    // hide field on enter, esc, or blur + no error
    if (event.keyCode === 13 || event.keyCode === 27 || event.type === 'blur') {
      if (this.labelErrorMsg.length === 0) {
        this.showField = false;
      }
    }
  }

  /**
   * Add a separate watcher for "hasDuplicationError" property
   * because this property is updated by parent.
   */
  @Watch('duplicationError')
  duplicationCallback(currentError: boolean, previousError: boolean): void {
    this.validateLabel();
    this.updateAttribute(this.attributeValue);
  }
}
